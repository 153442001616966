import React, { useEffect } from 'react';
import { useGlobal } from 'context/global/GlobalContext';
import { DocumentModuleType } from 'types/ProductDetailsType';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import useRedirectLink from 'hooks/useRedirectLink';

type DocumentDrawerProps = {
  drawerTitle: string;
  drawerData: DocumentModuleType;
  registrationRequired: boolean;
  closeDrawer: () => void;
};

const DocumentDrawer: React.FC<DocumentDrawerProps> = ({
  drawerTitle,
  drawerData,
  registrationRequired,
  closeDrawer,
}) => {
  const { autoDeployTriggered, setAutoDeployTriggered, desktopView } =
    useGlobal();
  const redirectToExternalLink = useRedirectLink();

  useEffect(() => {
    if (!registrationRequired) {
      redirectToExternalLink(drawerData.path, '_blank');
      setAutoDeployTriggered(true);
      closeDrawer();
    }
  }, [
    closeDrawer,
    desktopView,
    drawerData.path,
    autoDeployTriggered,
    registrationRequired,
    setAutoDeployTriggered,
    redirectToExternalLink,
  ]);

  return <ModuleWrapper drawerTitle={drawerTitle} />;
};

export default DocumentDrawer;
