import { SocialsType } from 'types/SocialsType';

export const validateSocials = (socials: SocialsType) => {
  return socials?.email ||
    socials?.instagram ||
    socials?.facebook ||
    socials?.linkedin ||
    socials?.whatsapp ||
    socials?.twitter ||
    socials?.threads ||
    socials?.youtube ||
    socials?.tiktok ||
    socials?.phone ||
    socials?.discord
    ? true
    : false;
};
