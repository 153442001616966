import React, { useState } from 'react';
import { MAGIC_ACTION } from 'context/global/GlobalProvider';
import { RegistrationFlowScreen } from 'types/RegistrationFlowScreen';
import PersonalDetails from 'components/forms/PersonalDetails';
import LoginForm from 'components/forms/LoginForm';

interface NormalLoginProps {
  loginComplete: () => void;
}

const NormalLogin: React.FC<NormalLoginProps> = ({ loginComplete }) => {
  const [screen, setScreen] = useState<RegistrationFlowScreen | null>(
    RegistrationFlowScreen.AUTH
  );

  const handleLogin = (provider: string = 'email') => {
    switch (provider) {
      case 'email':
        setScreen(RegistrationFlowScreen.PERSONAL);
        break;
      case 'social':
        loginComplete();
        break;
      default:
        break;
    }
  };

  switch (screen) {
    case RegistrationFlowScreen.AUTH:
      return (
        <LoginForm
          magicLinkRedirect={MAGIC_ACTION.NONE}
          handleLogin={handleLogin}
        />
      );
    case RegistrationFlowScreen.PERSONAL:
      return (
        <div className='relative w-full h-full px-6'>
          <PersonalDetails onPersonalDetailsUpdate={loginComplete} />
        </div>
      );
    default:
      return null;
  }
};

export default NormalLogin;
