import React, { useReducer } from 'react';
import { SuccessDrawerContext } from './SuccessDrawerContext';

interface MetaProps {
  title: string;
  description?: string;
}

interface SuccessDrawerInitStateType {
  open: boolean;
  success: boolean;
  meta: MetaProps;
  closeOnComplete: boolean;
}

const initState: SuccessDrawerInitStateType = {
  open: false,
  success: false,
  meta: {
    title: '',
    description: '',
  },
  closeOnComplete: true,
};

const reducer = (state: SuccessDrawerInitStateType, action: any) => {
  switch (action.type) {
    case 'SET_CLOSE_ON_COMPLETE':
      return {
        ...state,
        closeOnComplete: action.payload,
      };
    case 'OPEN': {
      return {
        ...state,
        open: true,
      };
    }
    case 'CLOSE': {
      return {
        ...state,
        open: false,
      };
    }
    case 'SET_META': {
      return {
        ...state,
        meta: { ...action.payload },
      };
    }
    case 'SHOW_SUCCESS': {
      return {
        ...state,
        success: true,
      };
    }
    case 'CLOSE_SUCCESS': {
      return {
        ...state,
        success: false,
      };
    }
    default: {
      return state;
    }
  }
};

export const SuccessDrawerProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const { open, success, meta, closeOnComplete } = state;

  const openDrawer = () => {
    dispatch({ type: 'OPEN' });
  };

  const closeSuccessDrawer = () => {
    dispatch({ type: 'CLOSE' });
  };

  const showSuccess = () => {
    dispatch({ type: 'SHOW_SUCCESS' });
  };

  const closeSuccess = () => {
    dispatch({ type: 'CLOSE_SUCCESS' });
  };

  const setCloseOnComplete = (closeOnComplete: boolean) => {
    dispatch({ type: 'SET_CLOSE_ON_COMPLETE', payload: closeOnComplete });
  };

  const clearMeta = () => {
    dispatch({
      type: 'SET_META',
      payload: {
        title: '',
        description: '',
      },
    });
  };

  const setMeta = (meta: MetaProps) => {
    clearMeta();
    dispatch({
      type: 'SET_META',
      payload: { ...meta },
    });
  };

  return (
    <SuccessDrawerContext.Provider
      value={{
        openDrawer,
        closeSuccessDrawer,
        showSuccess,
        closeSuccess,
        setMeta,
        setCloseOnComplete,
        meta,
        open,
        success,
        closeOnComplete,
        clearMeta,
      }}
    >
      {children}
    </SuccessDrawerContext.Provider>
  );
};
