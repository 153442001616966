import React from 'react';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as EmptyStarIcon } from 'assets/icons/svg/star-empty.svg';
import { ReactComponent as FilledStarIcon } from 'assets/icons/svg/star-filled.svg';

type StarRatingProps = {
  rating: number;
  setRating: (rating: number) => void;
  styles?: string;
};

const StarRating: React.FC<StarRatingProps> = ({
  rating,
  setRating,
  styles,
}) => {
  const [hovering, setHovering] = React.useState<number>(0);
  const { brandTheme } = useThemeContext();

  return (
    <div
      className={`relative w-full flex items-center justify-between mx-auto max-w-[400px] ${styles}`}
    >
      {[1, 2, 3, 4, 5].map((index: number) => (
        <button
          key={`star-${index}`}
          className='w-auto'
          onClick={() => (index === rating ? setRating(0) : setRating(index))}
          onMouseEnter={() => setHovering(index)}
          onMouseLeave={() => setHovering(0)}
        >
          {hovering !== 0 ? (
            hovering >= index ? (
              <FilledStarIcon fill={brandTheme} />
            ) : (
              <EmptyStarIcon fill={brandTheme} />
            )
          ) : index <= rating ? (
            <FilledStarIcon fill={brandTheme} />
          ) : (
            <EmptyStarIcon fill={brandTheme} />
          )}
        </button>
      ))}
    </div>
  );
};

export default StarRating;
