import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'components/atomic/Toast/Toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReferralModuleType } from 'types/ProductDetailsType';
import placeholder from 'assets/images/png/qr-placeholder.png';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ProgressiveImage from 'react-progressive-image';
import Button from 'components/atomic/Button';
import Input from 'components/atomic/Input';

type ReferralDrawerProps = {
  drawerTitle: string;
  referralData: ReferralModuleType;
};

const ReferralDrawer: React.FC<ReferralDrawerProps> = ({
  drawerTitle,
  referralData,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.referralDrawer',
  });

  const handleShare = useCallback(async () => {
    const shareData = {
      title: referralData?.url,
      text: t('shareText'),
      url: referralData?.url,
    };
    const resultPara = document.querySelector('.result');
    try {
      await navigator.share(shareData);
      if (resultPara) resultPara.textContent = 'Shared successfully';
    } catch (err) {
      if (resultPara) resultPara.textContent = 'Error: ' + err;
    }
  }, [t, referralData]);

  return (
    <ModuleWrapper drawerTitle={drawerTitle}>
      <HtmlWrapper html={referralData?.details} />
      <Input
        disabled
        styles='my-3'
        name='referral-url'
        autoComplete='referral-url'
        value={referralData?.url}
      />
      <div className='w-full flex flex-row items-center justify-between gap-2.5'>
        <CopyToClipboard
          text={referralData?.url}
          onCopy={() => {
            showToast({
              message: t('copyLinkToastMessage'),
              type: 'success',
            });
          }}
        >
          <Button title={t('copyLinkButton')} variant='light' />
        </CopyToClipboard>
        <Button
          variant='light'
          title={t('shareLinkButton')}
          onClick={() => handleShare()}
        />
      </div>
      <p className='my-3 text-center text-lg text-muted'>or</p>
      <div className='w-full flex flex-row items-center justify-between rounded-xl bg-referral'>
        <p className='pl-4 pr-1 text-base font-semibold text-darkGray'>
          {t('helpText')}
        </p>
        <div className='w-full max-w-[45%] flex items-center justify-center m-3 p-2 rounded-xl bg-white'>
          <ProgressiveImage
            src={referralData?.qrcode}
            placeholder={placeholder}
          >
            {(src: string, loading: boolean) => (
              <img
                src={src}
                alt='qr-code'
                width='100%'
                className={`w-full mt-2 object-cover duration-200 ${
                  loading ? 'opacity-50' : 'opacity-100'
                }`}
              />
            )}
          </ProgressiveImage>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default ReferralDrawer;
