import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDetailModel, NameConfig } from 'types/FormTypes';
import { FormikProps } from 'formik';
import Input from 'components/atomic/Input';
import FormImage from './FormImage';

type FormNameProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any) => void;
};

const FormName: React.FC<FormNameProps> = ({
  formData,
  formRef,
  name,
  updateFormField,
}) => {
  const [firstNameValue, setFirstName] = useState<string>('');
  const [lastNameValue, setLastName] = useState<string>('');

  const { firstName, lastName } = (formData?.config as NameConfig) || {};

  const { t } = useTranslation('translation', { keyPrefix: 'purchaseDetails' });

  useEffect(() => {
    console.log(formRef?.values[name]);

    if (formRef?.values[name]) {
      if (firstName) {
        setFirstName(formRef.values[name]?.firstName);
      }

      if (lastName) {
        setLastName(formRef.values[name]?.lastName);
      }
    }
  }, [firstName, lastName]);

  const updateAddress = (value: any, key: string) => {
    switch (key) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
    }

    const existingValue = formRef?.values[name] || {};
    updateFormField(name, { ...existingValue, [key]: value });
  };

  return (
    <>
      <div className='w-full h-max flex flex-col justify-start gap-3'>
        {formData.image && (
          <FormImage image={formData.image} alt='form-question-image' />
        )}
        {formData.text && (
          <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
            {formData.text}
          </h5>
        )}
        {formData.subText && (
          <p className='my-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
            {formData.subText}
          </p>
        )}
        <div className='w-full flex flex-1 flex-col items-center justify-start gap-3'>
          {firstName && (
            <Input
              value={firstNameValue}
              onChange={(e) => updateAddress(e.target.value, 'firstName')}
              placeholder={'First Name'}
            />
          )}
          {lastName && (
            <Input
              value={lastNameValue}
              onChange={(e) => updateAddress(e.target.value, 'lastName')}
              placeholder={'Last Name'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FormName;
