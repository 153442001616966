import React from 'react';

const LandingHtmlWrapper: React.FC<{ html: string }> = ({ html }) => (
  <div
    className='
      flex 
      w-full 
      flex-col 
      px-8 
      py-[30px] 
      text-center 
      [&_p]:text-sm [&_p]:text-landingPageText 
      [&_strong]:font-bold [&_strong]:text-black 
      [&_span]:text-sm [&_span]:text-landingPageText 
      [&_label]:text-sm [&_label]:text-landingPageText 
      [&_li]:flex [&_li]:items-start [&_li]:mt-1 [&_li]:gap-1.5 
      [&_h1]:text-[32px] [&_h1]:leading-[44px] [&_h1]:tracking-[0.5px] [&_h1]:font-bold [&_h1]:mb-3 [&_h1]:text-landingPageText 
      [&_h2]:text-[32px] [&_h2]:leading-[44px] [&_h2]:tracking-[0.5px] [&_h2]:font-bold [&_h2]:mb-3 [&_h2]:text-landingPageText 
      [&_h3]:text-[32px] [&_h3]:leading-[44px] [&_h3]:tracking-[0.5px] [&_h3]:font-bold [&_h3]:mb-3 [&_h3]:text-landingPageText 
      [&_h4]:text-[32px] [&_h4]:leading-[44px] [&_h4]:tracking-[0.5px] [&_h4]:font-bold [&_h4]:mb-3 [&_h4]:text-landingPageText 
      [&_h5]:text-[32px] [&_h5]:leading-[44px] [&_h5]:tracking-[0.5px] [&_h5]:font-bold [&_h5]:mb-3 [&_h5]:text-landingPageText 
      [&_h6]:text-[32px] [&_h6]:leading-[44px] [&_h6]:tracking-[0.5px] [&_h6]:font-bold [&_h6]:mb-3 [&_h6]:text-landingPageText
    '
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

export default LandingHtmlWrapper;
