export function applyTheme(theme: any) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar: string) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export function createTheme({
  primary,
  secondary,
}: {
  primary: string;
  secondary: string;
}) {
  return {
    '--theme-primary': primary,
    '--theme-secondary': secondary,
  };
}
