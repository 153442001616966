import React, { useEffect, useState } from 'react';
import { LandingPageType } from 'types/LandingPageType';
import { Helmet } from 'react-helmet';
import { useAPI } from 'utils/api';
import placeholder from 'assets/images/png/placeholder.png';
import viscosoftLogo from 'assets/logos/svg/viscosoft-logo.svg';
import viscosoftBackground from 'assets/images/svg/viscosoft-background.svg';
import LandingHtmlWrapper from 'components/wrappers/LandingHtmlWrapper';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import PageWrapper from 'components/layout/PageWrapper';
import ProgressiveImage from 'react-progressive-image';
import useRedirectLink from 'hooks/useRedirectLink';
import Button from 'components/atomic/Button';

const Landing: React.FC = () => {
  const [pageData, setPageData] = useState<LandingPageType | null>(null);
  const redirectToExternalLink = useRedirectLink();
  const [fetchData, loading] = useAPI(
    {
      method: 'GET',
      endpoint: `landing/page/viscosoft`,
      onSuccess: (response) => setPageData(response),
    },
    null,
    false
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper hideHeader>
      <Helmet>
        <title>{pageData ? pageData[0]?.brand[0]?.name : 'Loading...'}</title>
      </Helmet>
      {loading || !pageData ? (
        <div className='w-full h-full flex'>
          <LoadingIndicator color='#243762' />
        </div>
      ) : (
        <>
          <ProgressiveImage src={viscosoftBackground} placeholder={placeholder}>
            {(src: string, loading: boolean) => (
              <img
                src={src}
                alt='background'
                className={`absolute w-screen min-w-full min-h-full m-auto object-cover duration-500 ${
                  loading ? 'opacity-50' : 'opacity-100'
                }`}
              />
            )}
          </ProgressiveImage>
          <div className='z-30 relative w-full flex flex-col justify-start py-8 px-5 overflow-scroll'>
            <img
              className='max-w-32 mb-4 mx-auto'
              src={viscosoftLogo}
              alt='brand-logo'
            />
            <LandingHtmlWrapper html={pageData[0]?.brand[0]?.details} />
            {pageData[0]?.links?.map((node) => (
              <Button
                variant='custom'
                key={node.title}
                title={node.title}
                background='#243762'
                styles='my-1.5 bg-[#243762] text-white'
                onClick={() =>
                  node.url.includes('https://') || node.url.includes('http://')
                    ? redirectToExternalLink(node.url, '_self')
                    : redirectToExternalLink(`https://${node.url}`, '_self')
                }
              />
            ))}
          </div>
        </>
      )}
    </PageWrapper>
  );
};

export default Landing;
