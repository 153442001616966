import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { UrlParam } from 'types/Misc';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'react-scrollbars-custom';
import { useGlobal } from 'context/global/GlobalContext';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import PageWrapper from 'components/layout/PageWrapper';

const GameRules: React.FC = () => {
  const { sweepstakesGameRules, gameRulesLoading, productDetails, setSlug } =
    useGlobal();
  const { t } = useTranslation('translation', { keyPrefix: 'gameRules' });
  const { id } = useParams<UrlParam>();

  useEffect(() => {
    if (id) setSlug(id);
  }, [id, setSlug]);

  return (
    <PageWrapper
      allowGoBack={productDetails ? true : false}
      pageTitle={t('pageHeaderTitle')}
    >
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {gameRulesLoading ? (
        <div className='relative w-full h-full flex items-center justify-center pb-16'>
          <LoadingIndicator />
        </div>
      ) : (
        <Scrollbar style={{ background: '#FFFFFF' }}>
          <div className='relative w-full h-full px-5 pt-3 pb-4'>
            <HtmlWrapper html={sweepstakesGameRules?.gamerules || ''} />
          </div>
        </Scrollbar>
      )}
    </PageWrapper>
  );
};

export default GameRules;
