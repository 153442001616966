import React, { useState, useEffect, MouseEventHandler } from 'react';
import { ReactComponent as Remove } from 'assets/icons/svg/remove.svg';
import LoadingIndicator from 'components/atomic/LoadingIndicator';

interface ImagePreviewProps {
  src: string;
  showWarningLayer?: boolean;
  showSuccessLayer?: boolean;
  onRemove: MouseEventHandler<SVGSVGElement>;
}

// A component that renders a image preview with a remove button
const ImagePreview: React.FC<ImagePreviewProps> = ({
  src,
  onRemove,
  showSuccessLayer,
  showWarningLayer,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoading(false);
    };
  }, [src]);

  if (loading) {
    return (
      <div className='flex relative p-5'>
        <LoadingIndicator size='40px' />
      </div>
    );
  }

  return (
    <div className='flex relative p-5 w-1/2 h-[10rem]'>
      <div
        className={`absolute top-2 right-2 text-xl bg-white rounded-full shadow-lg p-px transition-all`}
      >
        <Remove onClick={onRemove} />
      </div>
      <div
        className='w-full flex flex-col items-center justify-center overflow-hidden rounded-[10px]'
        style={{
          ...(showSuccessLayer
            ? {
                border: '3px solid #00d7c8',
              }
            : showWarningLayer
            ? {
                border: '3px solid #ff931e',
              }
            : {}),
        }}
      >
        <img
          src={src}
          alt='preview'
          loading='lazy'
          className='aspect-auto max-w-[140px] max-h-[105px]'
          onLoad={() => setLoading(false)}
        />
        <div
          className='absolute left-[1.5rem] bottom-[1.5rem] w-[2rem] h-[2rem] rounded-full flex justify-center items-center'
          style={{
            background: showSuccessLayer
              ? '#00d7c8'
              : showWarningLayer
              ? '#ff931e'
              : 'transparent',
          }}
        >
          {showSuccessLayer && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 text-white'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M5 13l4 4L19 7'
              />
            </svg>
          )}
          {showWarningLayer && <span className='font-bold text-white'>!</span>}
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
