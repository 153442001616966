import React from 'react';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import { useIframeModifier } from 'hooks/useIframeModifier';
import { CustomModuleType } from 'types/ProductDetailsType';

type CustomDrawerProps = {
  drawerTitle: string;
  drawerData: CustomModuleType;
};

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  drawerTitle,
  drawerData,
}) => (
  <ModuleWrapper drawerTitle={drawerTitle}>
    <HtmlWrapper html={useIframeModifier(drawerData?.content)} />
  </ModuleWrapper>
);

export default CustomDrawer;
