import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormDetailModel } from 'types/FormTypes';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import Input from 'components/atomic/Input';
import FormImage from './FormImage';

type FormNumberProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: string) => void;
};

const FormNumber: React.FC<FormNumberProps> = ({
  name,
  formRef,
  formData,
  updateFormField,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.textArea',
  });

  const [number, setNumber] = useState<string>('');

  useEffect(() => {
    if (formRef && formRef.values[name]) {
      setNumber(formRef.values[name]);
    }
    formRef?.validateForm();
  }, []);

  return formRef ? (
    <div className='w-full h-max flex flex-col justify-start overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-4' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='my-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <div className='w-full flex flex-col justify-start'>
        <Input
          type='number'
          value={number}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setNumber(event.target.value);
            updateFormField(name, event.target.value);
          }}
          placeholder={t('placeHolder')}
        />
      </div>
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default FormNumber;
