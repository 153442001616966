import envVars from 'env-vars';

export const extractOptimizedMediaSource = (mediaSource?: string) => {
  if (!mediaSource?.match(/shopify/gi) && !mediaSource?.match(/;base64,/gi)) {
    const isVideo: boolean =
      mediaSource?.includes('.mp4') || mediaSource?.includes('.webm')
        ? true
        : false;
    const hostUrl: string = envVars.REACT_APP_HOST_URL_IMAGE || '';
    const fileId: string | undefined = mediaSource
      ?.split('?')
      ?.shift()
      ?.split('/')
      ?.pop()
      ?.split('.')
      ?.shift();
    return `${hostUrl}/${
      isVideo ? 'optimized-videos' : 'optimized-images'
    }/${fileId}/${isVideo ? 'video-vp9-720p.webm' : '1000x1000.webp'}`;
  }

  return mediaSource;
};

export const getFileType = (filename?: string): string => {
  if (!filename) return '';

  const ext = filename.split('?')[0].split('.').pop() || '';

  switch (ext) {
    case 'mp4':
    case 'webm':
    case 'mov':
    case 'mkv':
      return 'video';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'heic':
      return 'image';
    case 'pdf':
      return 'pdf';
  }

  return ext;
};

export const getMediaType = (mediaSource: string): string => {
  if (!mediaSource) {
    return 'video/mp4';
  }

  if (mediaSource?.match(/;base64,/gi)) {
    return 'image/jpeg';
  }

  const fileName = mediaSource.split('?')[0];
  const ext = fileName.split('.').pop();
  let mediaType = '';

  switch (ext) {
    case 'mp4':
      mediaType = 'video/mp4';
      break;
    case 'webm':
      mediaType = 'video/webm';
      break;
    case 'jpg':
      mediaType = 'image/jpg';
      break;
    case 'jpeg':
      mediaType = 'image/jpeg';
      break;
    case 'png':
      mediaType = 'image/png';
      break;
    case 'gif':
      mediaType = 'image/gif';
      break;
    case 'webp':
      mediaType = 'image/webp';
      break;
    default:
      mediaType = 'video/mp4';
  }

  return mediaType;
};

export const getImageMeta = (
  originalMediaSource?: string,
  renderOptimized: boolean = true
) => {
  const optimizedMediaSource: string =
    extractOptimizedMediaSource(originalMediaSource) || '';
  const originalMediaType = getMediaType(originalMediaSource || '');
  const optimizedMediaType = getMediaType(optimizedMediaSource);

  return {
    optimizedMediaSource,
    optimizedMediaType,
    originalMediaType,
    mediaToUse:
      renderOptimized && optimizedMediaSource
        ? optimizedMediaSource
        : originalMediaSource || '',
    mediaType:
      renderOptimized && optimizedMediaSource
        ? optimizedMediaType
        : originalMediaType,
  };
};
