import { useCallback, useRef, useState } from 'react';
import { useAPICacheContext } from '../context/APICacheContext/APICacheContext';
import { useGlobal } from '../context/global/GlobalContext';
import { UAParser } from 'ua-parser-js';
import { getAuth } from 'firebase/auth';
import envVars from '../env-vars';
import previewMockCalls from './previewMockCalls';
import useDeviceId from 'hooks/useDeviceId';
import useSession from 'hooks/useSession';

interface APIpayload {
  method: string;
  endpoint: string;
  onSuccess?: (response: any) => any;
  onError?: (error: string) => any;
}

type UseAPIVars<T> = [
  (
    data?: T,
    latestToken?: string | null,
    alwaysFetchFromMicroService?: boolean
  ) => Promise<any>,
  boolean,
  AbortController
];

// In dev mode, pass REACT_APP_API_URL manually. In production mode, this will be null as this code will be served from same host
const API_URL =
  envVars.REACT_APP_STAND_ALONE_MODE === 'false'
    ? envVars.REACT_APP_API_URL
    : '';

export function useAPI<T>(
  payload: APIpayload,
  currentToken: string | null = null,
  shouldUseToken: boolean = true,
  cacheEnabled: boolean = false
): UseAPIVars<T> {
  const { token } = useGlobal();
  const { method, endpoint, onSuccess, onError } = payload;
  const [cancelController, setCancelController] = useState<any>(null);
  const { updateCacheForAPI } = useAPICacheContext();

  const deviceId = useDeviceId();
  const sessionId = useSession();

  const fetchTokenRef = useRef<Promise<string | null> | null>(null);

  // avoid multiple calls to get firebase token
  const getToken = async () => {
    if (fetchTokenRef.current) {
      return fetchTokenRef.current;
    }

    const auth = getAuth();

    if (!auth.currentUser) {
      return null;
    }

    fetchTokenRef.current = auth.currentUser.getIdToken(true);
    return fetchTokenRef.current;
  };

  // in some cases we will call the useAPI hook inside useGlobal
  // and in that case the user is not always up to date with global context state
  // Todo: It seems complicated maybe we should refine this in a later stage
  const tokenToUse = currentToken || token;

  const [loading, setLoading] = useState(false);

  const apiCall = useCallback(
    async (
      data?: T,
      latestToken?: string | null,
      alwaysFetchFromMicroService?: boolean,
      overrideEndpoint?: string
    ) => {
      if (!alwaysFetchFromMicroService) {
        // if preview mode is enabled don't make api call
        switch ((window as any).mode) {
          case 'preview':
            const response = await previewMockCalls(endpoint, data);
            if (onSuccess) {
              onSuccess(response);
            }
            setLoading(false);
            return response;
          default:
            break;
        }
      }

      let headers: HeadersInit;
      headers = {
        'content-type': 'application/json',
      };

      let token = null;

      if (shouldUseToken) {
        // if latestToken is passed use that
        // else if token is present in localstorage use that
        // else use token from global context

        token = latestToken || tokenToUse;

        if (!token) {
          const auth = getAuth();
          if (auth.currentUser) {
            token = await getToken();
          }
        }
      }

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const deviceInfo = UAParser();

      const getResolution = () => {
        const realWidth = window.screen.width * window.devicePixelRatio;
        const realHeight = window.screen.height * window.devicePixelRatio;

        return {
          width: `${realWidth}`,
          height: `${realHeight}`,
        };
      };

      const { device } = deviceInfo;
      const { browser } = deviceInfo;
      const { os } = deviceInfo;

      headers.user_browser =
        `${browser.name || ''} ${browser.version || ''}` || '';
      headers.user_os = `${os.name || ''} ${os.version || ''}` || '';
      headers.user_device =
        `${device.vendor || ''} ${device.model || ''}` || '';
      headers.user_device_type = device.type || 'Desktop';
      headers.user_device_resolution_width = getResolution().width;
      headers.user_device_resolution_height = getResolution().height;
      headers.user_device_id = deviceId || '';
      headers.user_session_id = sessionId || '';

      try {
        setLoading(true);

        let url = `${API_URL}/app_api/${endpoint}`;

        if (overrideEndpoint) {
          url = `${API_URL}/app_api/${overrideEndpoint}`;
        }

        const payload: RequestInit = {
          method,
          headers,
          body: undefined,
        };

        if (data) {
          payload.body = JSON.stringify(data);
        }

        const res = await fetch(url, payload);

        if (res.status >= 200 && res.status < 400) {
          const response = await res.json();
          setLoading(false);
          onSuccess && onSuccess(response);

          // if cache is enabled for api cache the response
          if (cacheEnabled) {
            updateCacheForAPI(endpoint, response);
          }

          return response;
        } else {
          throw res;
        }
      } catch (e: any) {
        setLoading(false);
        onError && onError(e);
        throw e;
      }
    },
    [
      tokenToUse,
      shouldUseToken,
      method,
      endpoint,
      onSuccess,
      onError,
      cacheEnabled,
      updateCacheForAPI,
      deviceId,
      sessionId,
    ]
  );

  return [apiCall, loading, cancelController];
}
