import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAPI } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { getNameInitials } from 'utils/getInitials';
import { useGlobal } from 'context/global/GlobalContext';
import { showToast } from 'components/atomic/Toast/Toast';
import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import PageWrapper from 'components/layout/PageWrapper';
import PhoneInput from 'components/atomic/PhoneInput';
import Button from 'components/atomic/Button';
import Input from 'components/atomic/Input';

interface UserUpdatePayload {
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
}

interface PersonalDetailsProps {
  onPersonalDetailsUpdate?: () => void;
}

const Profile: React.FC<PersonalDetailsProps> = ({
  onPersonalDetailsUpdate = () => {},
}) => {
  const [firstName, setFirstName] = useState<string | undefined>('');
  const [lastName, setLastName] = useState<string | undefined>('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });
  const { t: personalDetailsTranslation } = useTranslation('translation', {
    keyPrefix: 'personalDetails',
  });
  const { user, personalDetails, getPersonalDetails } = useGlobal();
  const { profile } = personalDetails || {};

  const onSuccess = useCallback(() => {
    showToast({ message: t('updateToastMessage'), type: 'success' });
    getPersonalDetails();
    onPersonalDetailsUpdate();
  }, [onPersonalDetailsUpdate, getPersonalDetails, t]);

  const [updateUser, loading] = useAPI<UserUpdatePayload>({
    method: 'PUT',
    endpoint: 'auth/update',
    onSuccess,
    onError: (error) => {
      showToast({ message: error, type: 'error' });
    },
  });

  useEffect(() => {
    if (profile) {
      setFirstName(profile?.firstName);
      setLastName(profile?.lastName);
      setPhoneNumber(profile?.phoneNumber);
    }
  }, [profile]);

  return (
    <PageWrapper allowGoBack hideMenu pageTitle={t('pageHeaderTitle')}>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className='w-full h-full flex flex-col items-center justify-between px-0 pb-4 gap-4'>
        <div className='w-full h-full flex flex-col items-center pt-6 pb-2 overflow-auto'>
          <div className='relative w-28 h-28 flex items-center justify-center mb-8 mt-2 rounded-full shadow-avatar bg-lightgray'>
            <h1 className='text-5xl leading-[112px] text-center text-primary'>
              {firstName && lastName
                ? getNameInitials(firstName + ' ' + lastName)
                : user?.email?.charAt(0)?.toUpperCase()}
            </h1>
          </div>
          <div className='w-full flex flex-col items-center justify-center px-5 gap-4'>
            <Input
              type='text'
              name='firstName'
              value={firstName}
              autoComplete='given-name'
              placeholder={t('firstNameInput')}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              type='text'
              name='lastName'
              value={lastName}
              autoComplete='family-name'
              placeholder={t('lastNameInput')}
              onChange={(e) => setLastName(e.target.value)}
            />
            <div className='z-50 relative w-full'>
              <PhoneInput
                bottomOffset={98}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                existingPhoneNumber={profile?.phoneNumber}
                placeholder={personalDetailsTranslation(
                  'phoneNumberInputPlaceholder'
                )}
              />
            </div>
            <Input
              disabled
              type='text'
              name='email'
              autoComplete='email'
              placeholder={t('emailInput')}
              value={personalDetails?.emails[0]?.email}
            />
          </div>
        </div>
        <div className='relative w-full px-5'>
          <Button
            variant='dark'
            title={t('saveChanges')}
            disabled={
              phoneNumber && !validatePhoneNumber(phoneNumber) ? true : false
            }
            onClick={() =>
              updateUser({
                firstName,
                lastName,
                phoneNumber: phoneNumber?.replace(/[\s()-]/g, ''),
              })
            }
            loading={loading}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Profile;
