import React from 'react';
import { theme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from './WarrantyDrawer';
import { useGlobal } from 'context/global/GlobalContext';
import { ReactComponent as Warning } from 'assets/icons/svg/warning.svg';
import { ReactComponent as Checkmark } from 'assets/icons/svg/checkmark.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/svg/pending.svg';
import ReceiptUpload from 'components/forms/ReceiptUpload';
import ScreenType from 'types/ScreenType';

type WarrantyInfoProps = {
  title: string;
  issueDate: string;
  expiryDate: string;
  approvalStatus: ApprovalStatus;
  lifetime?: boolean;
  brandTheme?: string;
  content?: string;
  registrationData?: any;
  showReceiptUploadOption?: boolean;
};

const getTextClassName = (approvalStatus: ApprovalStatus): string => {
  switch (approvalStatus) {
    case ApprovalStatus.APPROVED:
      return 'text-brij';
    case ApprovalStatus.PENDING:
      return 'text-caution';
    case ApprovalStatus.DENIED:
      return 'text-warning';
    case ApprovalStatus.EXPIRED:
      return 'text-warning';
    default:
      return 'text-brij';
  }
};

const getClassName = (approvalStatus: ApprovalStatus): string => {
  switch (approvalStatus) {
    case ApprovalStatus.APPROVED:
      return 'bg-validWarranty';
    case ApprovalStatus.PENDING:
      return 'bg-pendingWarranty';
    case ApprovalStatus.DENIED:
      return 'bg-expiredWarranty';
    case ApprovalStatus.EXPIRED:
      return 'bg-expiredWarranty';
    default:
      return 'bg-validWarranty';
  }
};

const WarrantyInfo: React.FC<WarrantyInfoProps> = ({
  title,
  issueDate,
  expiryDate,
  approvalStatus,
  lifetime,
  content,
  registrationData,
  showReceiptUploadOption,
}) => {
  const { setScreen } = useGlobal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.warrantyDrawer',
  });

  const { enableMultiRegistration, multiRegistrationCTA } = registrationData;

  const getIcon = (approvalStatus: ApprovalStatus) => {
    switch (approvalStatus) {
      case ApprovalStatus.APPROVED:
        return <Checkmark width='15px' height='15px' fill={theme.primary} />;
      case ApprovalStatus.PENDING:
        return <PendingIcon width='15px' height='15px' fill='#FE931E' />;
      case ApprovalStatus.DENIED:
        return <Warning width='15px' height='15px' fill='#FD6157' />;
      case ApprovalStatus.EXPIRED:
        return <Warning width='15px' height='15px' fill='#FD6157' />;
      default:
        return <Checkmark width='15px' height='15px' fill={theme.primary} />;
    }
  };

  return (
    <div className='px-3 rounded-lg border-2 border-solid border-gray-100 mt-3'>
      <div
        className={`w-full flex items-center justify-center my-3 p-3 rounded ${getClassName(
          approvalStatus
        )}`}
      >
        {getIcon(approvalStatus)}
        <p
          className={`px-2 w-max text-sm text-center font-semibold ${getTextClassName(
            approvalStatus
          )}`}
        >
          {title}
        </p>
      </div>
      <div>
        <p
          className={`${getTextClassName(
            approvalStatus
          )} font-semibold text-sm mt-3 mb-3 text-center`}
        >
          {content}
        </p>
      </div>
      {approvalStatus !== ApprovalStatus.DENIED && (
        <div className='w-full flex mb-3 gap-3'>
          <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
            <p className='text-sm text-center font-medium text-muted'>
              {t('issueDate')}
            </p>
            <p className='font-semibold text-sm text-center text-dark'>
              {issueDate}
            </p>
          </div>
          <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
            <p className='text-sm text-center font-medium text-muted'>
              {t('expirationDate')}
            </p>
            <p className='font-semibold text-sm text-center text-dark'>
              {lifetime ? t('lifetimeLabel') : expiryDate}
            </p>
          </div>
        </div>
      )}
      {approvalStatus === ApprovalStatus.DENIED && showReceiptUploadOption && (
        <div className='mb-3'>
          <ReceiptUpload />
        </div>
      )}
      {enableMultiRegistration ? (
        <button
          type='button'
          onClick={() => setScreen(ScreenType.MULTIPLE_REGISTRATION)}
          className='not-draggable w-full flex items-center justify-center pt-2 text-xs font-semibold text-primary text-center underline mb-5'
        >
          {multiRegistrationCTA
            ? multiRegistrationCTA
            : t('multipleRegistrationText')}
        </button>
      ) : null}
    </div>
  );
};

export default WarrantyInfo;
