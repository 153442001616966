import { FC, useEffect } from 'react';
import { useRegistrationContext } from 'context/RegistrationContext/RegistrationContext';
import LoadingIndicator from 'components/atomic/LoadingIndicator';

const RegisterProductDrawer: FC<{}> = () => {
  const { onAuth, isMultipleRegistration, removeFormRegistration } =
    useRegistrationContext();

  useEffect(() => {
    isMultipleRegistration.current = true;
    removeFormRegistration();
    onAuth(undefined, true);
  }, []);

  return (
    <div className='w-full h-full flex justify-between align-between'>
      <LoadingIndicator />
    </div>
  );
};

export default RegisterProductDrawer;
