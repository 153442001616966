import React, { useEffect, useState } from 'react';
import { FormDetailModel, ConsentConfig } from 'types/FormTypes';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import FormImage from './FormImage';
import ConsentCheck from 'components/atomic/ConsentCheck';
import Input from 'components/atomic/Input';

type FormEmailProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any) => void;
};

const FormEmail: React.FC<FormEmailProps> = ({
  formData,
  formRef,
  name,
  updateFormField,
}) => {
  const [email, setEmail] = useState<string>('');
  const [consent, setConsent] = useState<boolean>(false);
  const { consentLabel, showConsent } =
    (formData?.config as ConsentConfig) || {};

  const { t } = useTranslation('translation', { keyPrefix: 'purchaseDetails' });

  useEffect(() => {
    if (formRef?.values[name]) {
      setEmail(formRef.values[name]?.email);
      if (showConsent) setConsent(formRef.values[name]?.consent);
    }
    formRef?.validateForm();
  }, [showConsent]);

  const updateEmail = (value: string) => {
    setEmail(value);
    updateFormField(name, { email: value, consent });
  };

  const updateConsent = (value: boolean) => {
    setConsent(value);
    updateFormField(name, { email, consent: value });
  };

  return (
    <>
      <div className='w-full flex flex-col justify-start overflow-auto'>
        {formData.image && (
          <FormImage image={formData.image} alt='form-question-image' />
        )}
        {formData.text && (
          <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
            {formData.text}
          </h5>
        )}
        {formData.subText && (
          <p className='my-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
            {formData.subText}
          </p>
        )}
        <div className='space-y-1 mt-2'>
          <Input
            type='email'
            value={email}
            onChange={(e) => updateEmail(e.target.value)}
            placeholder={t('emailInputPlaceholder')}
            className='form-input'
          />
          {showConsent ? (
            <ConsentCheck
              styles='!mb-0'
              enabled={[true]}
              consentChecks={[consent]}
              toggleConsentChecks={[updateConsent]}
              texts={[consentLabel || '']}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FormEmail;
