import { useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from 'utils/storage';
import { ModuleInfoType } from 'types/ProductDetailsType';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { useFormContext } from 'context/FormDrawerContext/FormDrawerContext';
import { ReactComponent as ResetIcon } from 'assets/icons/svg/reset.svg';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import Button from 'components/atomic/Button';
import { useGlobal } from 'context/global/GlobalContext';

type FormCompletionPageProps = {
  id: string;
  formData: ModuleInfoType;
};

const FormCompletionPage: FC<FormCompletionPageProps> = ({ id, formData }) => {
  const { setStartScreen, setCurrentStep, setEndScreen } = useFormContext();
  const { slug } = useGlobal();
  const { brandTheme } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer',
  });
  const history = useHistory();

  useEffect(() => {
    getLocalStorage().setItem(
      `brij-form-${slug}-${formData.id}-complete`,
      true
    );
    getLocalStorage().removeItem(`brij-form-${slug}-${formData.id}`);
  }, [formData]);

  return (
    <>
      {formData.endScreenContent ? (
        <div className='flex w-full min-h-[calc(100%_-_4.75rem)] overflow-y-auto overflow-x-hidden'>
          <HtmlWrapper html={formData.endScreenContent} />
        </div>
      ) : (
        <div className='flex w-full min-h-[calc(100%_-_4.75rem)] overflow-y-auto overflow-x-hidden'>
          <p className='text-sm'>{t('formCompletetionMessage')}</p>
        </div>
      )}
      {!formData.singleSubmission && (
        <div className='sticky bottom-0 left-0 bg-white pt-5'>
          <Button
            transition
            variant='light'
            styles='!flex-1 !px-14'
            title={t('submitAnotherResponse')}
            icon={<ResetIcon fill={brandTheme} className='absolute left-4' />}
            onClick={() => {
              getLocalStorage().setItem(
                `brij-form-${slug}-${formData.id}-complete`,
                false
              );
              getLocalStorage().setItem(
                `brij-form-${slug}-${formData.id}-start-screen-shown`,
                false
              );
              if (
                formData.startScreenContent === ' ' ||
                !formData.startScreenContent
              ) {
                setCurrentStep(1);
                getLocalStorage().setItem(
                  `brij-form-${slug}-${formData.id}-current-step`,
                  1
                );
                history.push(`/c/${id}/form/${formData.id}/step/1`);
                setStartScreen(false);
                setEndScreen(false);
                return;
              } else {
                setStartScreen(true);
                setEndScreen(false);
                setCurrentStep(1);
                getLocalStorage().removeItem(
                  `brij-form-${slug}-${formData.id}-current-step`
                );
                history.push(`/c/${id}/form/${formData.id}/start`);
                return;
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default FormCompletionPage;
