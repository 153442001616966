import React, { useState } from 'react';
import { theme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as Arrow } from 'assets/icons/svg/chevron-up.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import { ReactComponent as MulberryLogo } from 'assets/logos/svg/mulberry-logo.svg';
import {
  ModuleInfoType,
  WarrantyModuleType,
} from '../../../types/ProductDetailsType';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import CoverageTable from 'components/tables/CoverageTable';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import useRedirectLink from 'hooks/useRedirectLink';
import useElementSize from 'hooks/useElementSize';
import WarrantyInfo from './WarrantyInfo';
import dateFormat from 'dateformat';

type WarrantyDrawerProps = {
  drawerTitle: string;
  warrantyId: string;
  warrantyData: WarrantyModuleType;
  warrantyModule: ModuleInfoType;
  registrationData?: any;
};

export enum ApprovalStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  DENIED = 'denied',
  EXPIRED = 'expired',
}

const getInfoText = (
  approvalStatus: ApprovalStatus,
  enableWarrantyStateMessages?: boolean,
  deniedMessage?: string,
  pendingMessage?: string,
  t?: any
): string => {
  let pendingText = '';
  let deniedText = '';
  if (enableWarrantyStateMessages) {
    pendingText = pendingMessage || t('pendingInfoText');
    deniedText = deniedMessage || t('deniedInfoText');
  }
  switch (approvalStatus) {
    case ApprovalStatus.APPROVED:
      return '';
    case ApprovalStatus.PENDING:
      return pendingText;
    case ApprovalStatus.DENIED:
      return deniedText;
    case ApprovalStatus.EXPIRED:
      return '';
    default:
      return '';
  }
};

const validateDate = (date: Date) =>
  new Date().setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0);

const isExpired = (expirationDate?: Date | string) => {
  if (!expirationDate) return false;
  if (expirationDate === 'LIFETIME') return false;
  return validateDate(new Date(dateFormat(expirationDate, 'mmm d, yyyy')));
};

const WarrantyDrawer: React.FC<WarrantyDrawerProps> = ({
  drawerTitle,
  warrantyData,
  warrantyModule,
  registrationData,
}) => {
  const [animateTable, toggleAnimateTable] = useState<boolean>(false);
  const [showCoverageTable, toggleCoverageTable] = useState<boolean>(false);
  const redirectToExternalLink = useRedirectLink();
  const [tableRef, { height }] = useElementSize();
  const { brandTheme } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.warrantyDrawer',
  });

  const { enableWarrantyStateMessages, deniedMessage, pendingMessage } =
    warrantyModule;
  const { personalDetails, slug, getCurrentRegistrationId } = useGlobal();
  const productCollection = personalDetails?.profile?.productCollection;
  const registrationId = getCurrentRegistrationId();

  const currentProductItem = productCollection
    ?.filter((item) => !item.incomplete)
    .find(
      // TODO: remove this when we have a proper way to get the preview mode
      (item) => {
        const matched = item?.tagId === slug;
        if (registrationId)
          return matched && item?.registrationId === registrationId;
        return matched;
      }
    );

  let approvalStatus =
    (currentProductItem?.approvalStatus as ApprovalStatus) ||
    (warrantyData?.approvalStatus as ApprovalStatus) ||
    ApprovalStatus.APPROVED;

  approvalStatus = isExpired(warrantyData?.expirationDate)
    ? ApprovalStatus.EXPIRED
    : approvalStatus;

  const content = getInfoText(
    approvalStatus,
    enableWarrantyStateMessages,
    deniedMessage,
    pendingMessage,
    t
  );

  const showReceiptUploadOption = !!currentProductItem?.receipts?.length;

  const getHeaderWarranty = () => {
    let title = '';
    switch (approvalStatus) {
      case ApprovalStatus.APPROVED:
        title = t('warrantyHeading');
        break;
      case ApprovalStatus.PENDING:
        title = t('pendingWarrantyHeading');
        break;
      case ApprovalStatus.DENIED:
        title = t('deniedWarrantyHeading');
        break;
      case ApprovalStatus.EXPIRED:
        title = t('expiredWarrantyHeading');
        break;
      default:
        title = t('warrantyHeading');
        break;
    }
    return title;
  };

  const getMulberryHeader = () => {
    let title = validateDate(
      new Date(
        dateFormat(warrantyData?.mulberry?.expirationDate, 'mmm d, yyyy')
      )
    )
      ? t('expiredMulberryWarrantyHeading')
      : t('mulberryWarrantyHeading');
    return title;
  };

  return (
    <ModuleWrapper
      tableShown={showCoverageTable}
      tableHeight={height}
      drawerTitle={
        !warrantyData?.mulberry ? (
          drawerTitle
        ) : (
          <div className='w-full'>
            <MulberryLogo
              width='7.2rem'
              style={{
                margin: '1.25rem 4rem 1.25rem 1.75rem',
              }}
            />
          </div>
        )
      }
    >
      <HtmlWrapper html={warrantyData?.details} />
      {warrantyData?.mulberry && (
        <div className='w-full flex flex-col'>
          {warrantyData?.showWarrantyStatus && (
            <WarrantyInfo
              title={getMulberryHeader()}
              issueDate={dateFormat(
                warrantyData!.mulberry!.issueDate,
                'mmm d, yyyy'
              )}
              expiryDate={dateFormat(
                warrantyData!.mulberry!.expirationDate,
                'mmm d, yyyy'
              )}
              // @ts-ignore
              registrationData={registrationData}
              approvalStatus={approvalStatus}
              brandTheme={brandTheme}
              content={content}
              showReceiptUploadOption={showReceiptUploadOption}
            />
          )}
          <div
            className='w-full flex items-center justify-center pt-4 cursor-pointer'
            onClick={() => {
              toggleCoverageTable(!showCoverageTable);
              toggleAnimateTable(true);
            }}
          >
            <span className='w-max text-base font-semibold text-center mb-2 underline text-dark'>
              {t('viewDetails')}
            </span>
            <Arrow
              style={{
                transform: showCoverageTable
                  ? 'rotate(0deg)'
                  : 'rotate(180deg)',
                transition: '0.4s',
                margin: '0 0 0.6rem 0.4rem',
              }}
            />
          </div>
          <div className='flex mb-0.5 overflow-hidden'>
            <div className='flex pt-px'>
              <div
                ref={tableRef}
                className='h-full flex flex-col pt-8 duration-300'
                style={{
                  transform: showCoverageTable
                    ? 'translateY(0)'
                    : 'translateY(-101%)',
                }}
              >
                <CoverageTable
                  headers={["What's Covered", 'mulberry', 'Manu. Warranty']}
                  tableData={warrantyData!.mulberry!.coverages}
                />
                <div
                  className='flex items-center justify-center my-3 cursor-pointer'
                  onClick={() =>
                    redirectToExternalLink(
                      warrantyData!.mulberry!.policyTermsUrl,
                      '_blank'
                    )
                  }
                >
                  <ExternalLink
                    fill={brandTheme || theme.primary}
                    style={{ margin: '-0.05rem 0.25rem 0 0' }}
                  />
                  <p className='text-xs font-medium text-primary'>
                    {t('fullTermsLink')}
                  </p>
                </div>
                <p className='text-xs'>
                  {t('mulberryTable.description.explanationText')}{' '}
                  <span
                    style={{
                      color: brandTheme || theme.primary,
                      cursor: 'pointer',
                    }}
                    onClick={() => window.open('mailto:help@getmulberry.com')}
                  >
                    {t('mulberryTable.description.linkText')}
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {warrantyData?.showWarrantyStatus && (
        <div
          className={`w-full flex flex-col ${
            animateTable ? 'duration-300' : 'duration-0'
          }`}
          style={{
            transform: !showCoverageTable
              ? `translateY(-${height}px)`
              : 'translateY(0)',
          }}
        >
          <WarrantyInfo
            title={getHeaderWarranty()}
            issueDate={dateFormat(warrantyData.purchaseDate, 'mmm d, yyyy')}
            expiryDate={
              // @ts-ignore
              approvalStatus === ApprovalStatus.PENDING
                ? 'N/A'
                : dateFormat(warrantyData.expirationDate, 'mmm d, yyyy')
            }
            // @ts-ignore
            approvalStatus={approvalStatus}
            registrationData={registrationData}
            lifetime={warrantyData.duration.value === 'LIFETIME'}
            brandTheme={brandTheme}
            content={content}
            showReceiptUploadOption={showReceiptUploadOption}
          />
        </div>
      )}
    </ModuleWrapper>
  );
};

export default WarrantyDrawer;
