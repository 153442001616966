import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ borderStyle: CornerStyle }>`
    .dropdown-container {
        border-radius: ${(props) => {
          switch (props.borderStyle) {
            case CornerStyle.FULL_ROUND:
              return '20px !important';
            case CornerStyle.ROUNDED_CORNERS:
              return '10px !important';
            case CornerStyle.SQUARE_CORNERS:
              return '0px !important';
            default:
              return '20px !important';
          }
        }};

        .MuiMenuItem-root {
            font-family: 'brandFont','Poppins',sans-serif;
        }
    }
`;
