import React, { ChangeEvent } from 'react';

type NumberInputProps = {
  value: string | undefined;
  disabled?: boolean;
  placeholder?: string;
  borderStyle?: 'largeCorner' | 'smallCorner' | 'noCorner';
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const borders = {
  largeCorner: 'rounded-full',
  smallCorner: 'rounded-[10px]',
  noCorner: 'rounded-none',
};

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  placeholder = '',
  disabled = false,
  borderStyle = 'largeCorner',
}) => {
  let borderClasses: string = borders[borderStyle];

  return (
    <input
      type='number'
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={(event) => onChange && onChange(event)}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'e' || event.key === 'E') {
          event.preventDefault();
        }
      }}
      className={`w-full h-[2.25rem] max-w-[4.375rem] min-w-[1.375rem] py-[0.3rem] mx-[0.3rem] text-base text-center font-semibold text-black border border-solid border-numberInput ${borderClasses}`}
    />
  );
};

export default NumberInput;
