import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobal } from 'context/global/GlobalContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { isIOS, isMobile, isAndroid } from 'react-device-detect';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import RotationScreen from 'components/screens/RotationScreen';
import SideMenu from '../SideMenu';

type AppContainerProps = {
  children: ReactNode;
  zoom: number;
};

const setViewHeight = () => {
  if (isIOS) return 'h-[100dvh]';
  else if (isAndroid) return 'h-[100svh]';
  else return 'h-[100vh]';
};

const AppContainer: React.FC<AppContainerProps> = ({ zoom, children }) => {
  const { desktopView, slug } = useGlobal();
  const { menuStyle } = useThemeContext();
  const location = useLocation();

  const isFacebookInAppBrowser = () =>
    /FB_IAB/.test(navigator.userAgent) ||
    /FBAN/.test(navigator.userAgent) ||
    /FBAV/.test(navigator.userAgent);

  const getCornerStyle = () => {
    switch (menuStyle) {
      case CornerStyle.FULL_ROUND:
        return 'lg:rounded-3xl';
      case CornerStyle.ROUNDED_CORNERS:
        return 'lg:rounded-xl';
      case CornerStyle.SQUARE_CORNERS:
        return 'lg:rounded-none';
      default:
        return 'lg:rounded-3xl';
    }
  };

  let isIframe: boolean = window !== window.top;

  return (
    <div
      className={`w-screen flex items-center justify-center ${
        isIframe ? 'bg-white' : 'bg-light'
      } ${setViewHeight()}`}
    >
      <div
        style={{ zoom: zoom }}
        className={`relative w-full h-full overflow-hidden shadow-appFrame duration-100 
          ${
            isMobile &&
            !isFacebookInAppBrowser() &&
            'landscape:hidden portrait:block'
          } 
          ${getCornerStyle()} 
          ${
            desktopView
              ? `${
                  location.pathname.includes(`/c/${slug}`)
                    ? 'lg:relative lg:w-auto lg:max-w-[1080px] lg:aspect-[5/2]'
                    : 'lg:aspect-[9/16] lg:w-auto lg:min-w-[370px]'
                } ${
                  isIframe ? 'lg:h-[calc(100%)]' : 'lg:h-[calc(100%_-_4rem)]'
                } lg:h-[calc(100%_-_4rem)] lg:max-h-[720px] lg:bg-primary lg:ring-primary lg:ring-8`
              : `lg:w-auto lg:min-w-[370px] ${
                  isIframe
                    ? 'lg:max-h-[calc(100%)]'
                    : 'lg:max-h-[calc(100%_-_2rem)]'
                } lg:aspect-[9.2/16] bg-white`
          }
        `}
      >
        {desktopView ? (
          <div className='lg:w-full lg:h-full lg:ml-auto lg:bg-white'>
            <SideMenu />
            {children}
          </div>
        ) : (
          <>
            <SideMenu />
            {children}
          </>
        )}
      </div>
      <RotationScreen />
    </div>
  );
};

export default AppContainer;
