import React from 'react';
import { theme } from 'styles/theme';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BrijLogo } from 'assets/logos/svg/brij-colored.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import Button from 'components/atomic/Button';
import useRedirectLink from 'hooks/useRedirectLink';
import PageWrapper from 'components/layout/PageWrapper';
import background from 'assets/icons/svg/404-background.svg';

const FourZeroFour: React.FC = () => {
  const redirectToExternalLink = useRedirectLink();
  const { t } = useTranslation('translation', { keyPrefix: 'fourZeroFour' });

  return (
    <PageWrapper transparent logo={<BrijLogo fill='#4B6EFA' />}>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div
        className='w-full h-full flex flex-col items-center justify-start'
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center 50px',
          backgroundSize: 'cover',
        }}
      >
        <div className='h-full flex justify-end px-5 pb-20'>
          <div className='mt-auto flex flex-col items-center'>
            <h3 className='font-semibold'>{t('message')}</h3>
            <Button
              variant='light'
              title={t('learnMoreButton')}
              styles='w-[190px] mt-8 font-medium'
              icon={
                <ExternalLink
                  className='ml-2'
                  fill={theme.primary}
                  width={18}
                />
              }
              onClick={() =>
                redirectToExternalLink(t('learnMoreLink'), '_blank')
              }
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default FourZeroFour;
