import { useRef, useEffect } from 'react';

// A custom hook that returns the previous value of an input
function usePrevious(value: any) {
  // Create a ref object to store the previous value
  const ref = useRef();

  // Update the ref object with the current value on every render
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return the previous value (initially undefined)
  return ref.current;
}

export default usePrevious;
