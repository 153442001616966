import React from 'react';
import { theme } from 'styles/theme';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as LoadingAnimation } from 'assets/icons/svg/loading.svg';

type LoadingIndicatorProps = {
  color?: string;
  size?: string;
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  color,
  size = '60px',
}) => {
  const { brandTheme } = useThemeContext();

  return (
    <LoadingAnimation
      stroke={color || brandTheme || theme.primary}
      height={size}
      width='100%'
    />
  );
};

export default LoadingIndicator;
