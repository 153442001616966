import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UserCreatePayload, UserStruct } from '../types/User';
import { User } from 'firebase/auth';
import { useAPI } from '../utils/api';
import { getLocalStorage } from 'utils/storage';

function usePersonalDetails(
  user: User | null
): [
  UserStruct | null,
  () => void,
  string | null,
  (newToken: string | null) => void,
  Dispatch<SetStateAction<UserStruct | null>>,
  () => Promise<any>
] {
  const fetchingUser = useRef<any>(null);
  const [personalDetails, setpersonalDetails] = useState<UserStruct | null>(
    null
  );
  const [token, setToken] = useState<string | null>(null);
  const [tempToken, setTempToken] = useState<string | null>(null);
  const userFormUpdateId = getLocalStorage().getItem(
    'brij-form-user-update-id'
  );

  const onUpdateSuccess = () => {
    getLocalStorage().removeItem('brij-form-user-update-id');
  };

  const [updateForm] = useAPI<{ user: string }>({
    method: 'PUT',
    endpoint: `form/form_answers/${userFormUpdateId}`,
    onSuccess: onUpdateSuccess,
  });

  const onSuccess = useCallback(
    (userDetails: UserStruct) => {
      setpersonalDetails(userDetails);
      // set the real token so all the API's can use it.
      setToken(tempToken);
    },
    [tempToken]
  );

  const [getUserAsync] = useAPI<UserCreatePayload>({
    method: 'POST',
    endpoint: 'auth',
  });

  const getUserPromise = async () => {
    if (fetchingUser.current) {
      return fetchingUser.current;
    }

    return getUserAsync();
  };

  const [getUser] = useAPI<UserCreatePayload>(
    {
      method: 'POST',
      endpoint: 'auth',
      onSuccess,
    },
    tempToken
  );

  // watch out for any exisiting user form update id
  useEffect(() => {
    if (userFormUpdateId && personalDetails) {
      updateForm({ user: personalDetails._id });
    }
  }, [personalDetails, tempToken, userFormUpdateId]);

  useEffect(() => {
    // if user doesn't exist remove token ( mostly triggered when user logs out)
    if (!user) {
      setToken(null);
      setTempToken(null);
      return;
    }

    if (user && !tempToken) {
      const setUserToken = async () => {
        const tokenExtracted = (await user?.getIdToken()) || null;
        setTempToken(tokenExtracted);
      };

      setUserToken();
    }
  }, [user, tempToken]);

  useEffect(() => {
    // fetch user details for the first time
    if (user && tempToken) {
      const { email, phoneNumber, displayName } = user;
      fetchingUser.current = getUser({
        email,
        phoneNumber,
        firstName: displayName,
        lastName: '',
      });
    }
  }, [user, getUser, tempToken]);

  return [
    personalDetails,
    getUser,
    token,
    setToken,
    setpersonalDetails,
    getUserPromise,
  ];
}

export default usePersonalDetails;
