import React, { MouseEventHandler, ReactNode } from 'react';

type CatchLinkWrapperProps = {
  children: ReactNode;
  onLinkClick: (href: string) => void;
};

const CatchLinkWrapper: React.FC<CatchLinkWrapperProps> = ({
  children,
  onLinkClick,
}) => {
  const catchEvents: MouseEventHandler<HTMLDivElement> = (e) => {
    const element = e.target as HTMLAnchorElement;
    if (element.tagName === 'A') {
      onLinkClick(element.href);
      return;
    }

    const parentAnchor = element?.parentElement?.closest('a');

    if (parentAnchor) {
      onLinkClick(parentAnchor.href);
      return;
    }
  };

  return <div onClick={catchEvents}>{children}</div>;
};

export default CatchLinkWrapper;
