import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import { useHistory, useLocation } from 'react-router-dom';
import { PossibleExperienceType } from 'types/ExperienceTypes';
import { useCartContext } from 'context/CartContext/CartContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import IconButton from 'components/atomic/IconButton';

type HeaderProps = {
  pageTitle?: string;
  hideMenu: boolean;
  hideLogo?: boolean;
  allowGoBack?: boolean;
  transparent?: boolean;
  logo?: ReactNode;
};

const Header: React.FC<HeaderProps> = ({
  pageTitle,
  hideMenu,
  hideLogo,
  transparent,
  allowGoBack,
  logo,
}) => {
  const { setIsMenuOpen, productDetails, logEvent, desktopView, slug } =
    useGlobal();
  const { t } = useTranslation('translation', { keyPrefix: 'header' });
  const { cartProducts, openCart, recommendations } = useCartContext();
  const { menuStyle, secondaryBrandColor } = useThemeContext();
  const location = useLocation();
  const history = useHistory();
  const hubExperience: boolean =
    productDetails?.product?.qrCodeDestination ===
    PossibleExperienceType.DIGITAL_HUB;
  const optInExperience: boolean =
    productDetails?.product?.qrCodeDestination ===
    PossibleExperienceType.SMS_OPT_IN;
  const showCartMenu: boolean =
    location.pathname.includes('/shop/') && cartProducts?.length > 0;

  if (
    desktopView &&
    productDetails?.product.qrCodeDestination ===
      PossibleExperienceType.DIGITAL_HUB
  )
    return null;

  return (
    <section
      className={`w-full h-header flex flex-row items-center gap-5 px-5 py-4 border-solid border-t-0 border-l-0 border-r-0 border-b border-lightBorder bg-transparent 
      ${
        hubExperience || optInExperience ? 'justify-center' : 'justify-between'
      } 
      ${
        transparent
          ? 'z-10 absolute left-0 top-0 bg-transparent border-none'
          : 'relative bg-white'
      }`}
    >
      {desktopView && location.pathname.includes(`/c/${slug}`) ? (
        <div className='relative flex flex-col w-full max-w-[calc(100%_-_4rem)]'>
          <span className='relative w-full text-lg text-black leading-6 font-medium text-ellipsis overflow-hidden whitespace-nowrap'>
            {recommendations.length !== 0 &&
            location.pathname.includes(`/c/${slug}/shop`)
              ? t('recommendationsHeading')
              : productDetails?.product?.displayName ||
                productDetails?.product?.name ||
                ''}
          </span>
          {productDetails?.product?.subtitle && (
            <span className='relative font-medium text-base leading-6 text-muted truncate'>
              {location.pathname.includes(`/c/${slug}/shop`)
                ? recommendations.length === 0
                  ? productDetails?.product?.subtitle
                  : ''
                : productDetails?.product?.subtitle}
            </span>
          )}
        </div>
      ) : allowGoBack ? (
        <IconButton
          size={16}
          styles='p-4'
          variant='white'
          iconName='back'
          onClick={() => history.goBack()}
        />
      ) : !hideLogo && logo ? (
        <>
          {hubExperience ? (
            <a
              href={
                productDetails?.brand?.website?.includes('https://') ||
                productDetails?.brand?.website?.includes('http://')
                  ? productDetails?.brand?.website
                  : `https://${productDetails?.brand?.website}`
              }
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                logEvent({
                  eventType: 'ENGAGEMENTS',
                  event: 'WEBSITE_VISITS',
                  data: {
                    url:
                      productDetails?.brand?.website?.includes('https://') ||
                      productDetails?.brand?.website?.includes('http://')
                        ? productDetails?.brand?.website
                        : `https://${productDetails?.brand?.website}`,
                  },
                });
              }}
              className={`${hubExperience ? 'cursor-pointer' : ''}`}
            >
              {logo}
            </a>
          ) : (
            logo
          )}
        </>
      ) : null}
      {pageTitle && (
        <h6 className='absolute w-max max-w-[calc(100%_-_10rem)] left-0 right-0 m-auto text-center text-base text-black leading-5 font-semibold'>
          {pageTitle}
        </h6>
      )}
      {!hideMenu && !hubExperience && (
        <IconButton
          variant='themed'
          styles='absolute right-5'
          borderStyle={menuStyle || 'largeCorner'}
          iconName={showCartMenu ? 'cart' : 'menu'}
          iconColor={secondaryBrandColor || '#FFFFFF'}
          onClick={() => (showCartMenu ? openCart() : setIsMenuOpen(true))}
        />
      )}
    </section>
  );
};

export default Header;
