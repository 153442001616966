import React, { useCallback, useEffect, useReducer } from 'react';
import { createTheme } from 'styles/themes/utils';
import { applyTheme } from 'styles/themes/utils';
import { getLocalStorage } from 'utils/storage';
import { theme } from 'styles/theme';
import {
  CornerStyle,
  ThemeContext,
  initContextState,
  ThemeContextProps,
} from './ThemeContext';

const reducer = (state: ThemeContextProps, action: any): ThemeContextProps => {
  switch (action.type) {
    case 'setBrandTheme': {
      return {
        ...state,
        brandTheme: action.payload,
      };
    }
    case 'setButtonStyle': {
      return {
        ...state,
        buttonStyle: action.payload,
      };
    }
    case 'setSecondaryBrandColor': {
      return {
        ...state,
        secondaryBrandColor: action.payload,
      };
    }
    case 'setInputStyle': {
      return {
        ...state,
        inputStyle: action.payload,
      };
    }
    case 'setMenuStyle': {
      return {
        ...state,
        menuStyle: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const ThemeProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initContextState);
  const {
    brandTheme,
    buttonStyle,
    secondaryBrandColor,
    inputStyle,
    menuStyle,
  } = state;

  const setBrandTheme = useCallback(
    (color: string) => {
      dispatch({ type: 'setBrandTheme', payload: color });
    },
    [dispatch]
  );

  const setButtonStyle = useCallback(
    (style: CornerStyle) => {
      dispatch({ type: 'setButtonStyle', payload: style });
    },
    [dispatch]
  );

  const setSecondaryBrandColor = useCallback(
    (color: string) => {
      dispatch({ type: 'setSecondaryBrandColor', payload: color });
    },
    [dispatch]
  );

  const setInputStyle = useCallback(
    (style: CornerStyle) => {
      dispatch({ type: 'setInputStyle', payload: style });
    },
    [dispatch]
  );

  const setMenuStyle = useCallback(
    (style: CornerStyle) => {
      dispatch({ type: 'setMenuStyle', payload: style });
    },
    [dispatch]
  );

  const pathname: string = window.location.pathname;

  useEffect(() => {
    const regex = pathname.includes('/app/404');
    if (regex) {
      setButtonStyle(CornerStyle.FULL_ROUND);
      setInputStyle(CornerStyle.FULL_ROUND);
      setMenuStyle(CornerStyle.FULL_ROUND);
      setSecondaryBrandColor(theme.secondary);
      setBrandTheme(theme.primary);
      return;
    }
  }, [
    setBrandTheme,
    setSecondaryBrandColor,
    setButtonStyle,
    pathname,
    setInputStyle,
    setMenuStyle,
  ]);

  useEffect(() => {
    getLocalStorage().setItem('buttonStyle', buttonStyle);
  }, [buttonStyle]);

  const onBackButtonEvent = () => {
    setBrandTheme(getLocalStorage().getItem('accentColor') || theme.primary);
    setSecondaryBrandColor(
      getLocalStorage().getItem('secondaryColor') || theme.secondary
    );
  };

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    applyTheme({ primary: brandTheme, secondary: secondaryBrandColor });
  }, []);

  useEffect(() => {
    applyTheme(
      createTheme({ primary: brandTheme, secondary: secondaryBrandColor })
    );
  }, [brandTheme, secondaryBrandColor]);

  return (
    <ThemeContext.Provider
      value={{
        brandTheme,
        setBrandTheme,
        buttonStyle,
        secondaryBrandColor,
        setButtonStyle,
        inputStyle,
        setInputStyle,
        menuStyle,
        setMenuStyle,
        setSecondaryBrandColor,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
