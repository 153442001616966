import { createContext, useContext } from 'react';
import { theme } from 'styles/theme';

export enum CornerStyle {
  FULL_ROUND = 'largeCorner',
  SQUARE_CORNERS = 'noCorner',
  ROUNDED_CORNERS = 'smallCorner',
}

export type ThemeContextProps = {
  buttonStyle: CornerStyle;
  inputStyle: CornerStyle;
  menuStyle: CornerStyle;
  brandTheme: string;
  setBrandTheme: (color: string) => void;
  setInputStyle: (style: CornerStyle) => void;
  setMenuStyle: (style: CornerStyle) => void;
  secondaryBrandColor: string;
  setButtonStyle: (style: CornerStyle) => void;
  setSecondaryBrandColor: (color: string) => void;
};

export const initContextState: ThemeContextProps = {
  buttonStyle: CornerStyle.FULL_ROUND,
  inputStyle: CornerStyle.FULL_ROUND,
  menuStyle: CornerStyle.FULL_ROUND,
  brandTheme: theme.primary,
  setBrandTheme: () => {},
  setButtonStyle: () => {},
  setInputStyle: () => {},
  setMenuStyle: () => {},
  secondaryBrandColor: theme.secondary,
  setSecondaryBrandColor: () => {},
};

export const ThemeContext = createContext<ThemeContextProps>(initContextState);
export const useThemeContext = () => useContext(ThemeContext);
