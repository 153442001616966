import { useEffect, useState } from 'react';
import { getId } from 'utils/getId';
import { v4 as uuidv4 } from 'uuid';

const useSession = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    try {
      setSessionId(getId(sessionStorage, 'sessionId'));
    } catch (e) {
      setSessionId(uuidv4());
    }
  }, []);

  return sessionId;
};

export default useSession;
