import { ProductDetailsType } from 'types/ProductDetailsType';

const getLogoURL = (details: ProductDetailsType | null): string => {
  if (!details) {
    return '';
  }

  const { brand, product } = details;

  const { alternateLogo, alternateLogoOption } = product;
  const { image } = brand;

  // if alternateLogoOption is false just return the brand logo
  if (!alternateLogoOption) {
    return image;
  }

  // if alternateLogo is there return alternateLogo
  if (alternateLogo) {
    return alternateLogo;
  }

  // else return nothing
  return '';
};

export default getLogoURL;
