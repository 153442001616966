import React from 'react';
import { ReactComponent as BrijLogo } from 'assets/logos/svg/brij-colored.svg';
import { useGlobal } from 'context/global/GlobalContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RoutesHashMap } from 'routes';
import { Helmet } from 'react-helmet';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import NormalLogin from 'components/registration/NormalLogin';
import PageWrapper from 'components/layout/PageWrapper';

const Login: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'signIn' });
  const { authFetched } = useGlobal();
  const history = useHistory();

  const handleLoginComplete = () => {
    history.push(RoutesHashMap.Collection.path);
  };

  return (
    <PageWrapper
      pageTitle={t('pageHeaderTitle')}
      logo={<BrijLogo className='fill-primary' />}
    >
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      {!authFetched ? (
        <div className='w-full h-full flex items-center'>
          <LoadingIndicator />
        </div>
      ) : (
        <NormalLogin loginComplete={handleLoginComplete} />
      )}
    </PageWrapper>
  );
};

export default Login;
