import React, { ReactNode } from 'react';
import { useGlobal } from 'context/global/GlobalContext';
import { useLocation } from 'react-router-dom';
import PreviewPane from '../PreviewPane';
import Header from '../Header';

type PageWrapperProps = {
  pageTitle?: string;
  hideMenu?: boolean;
  hideLogo?: boolean;
  hideHeader?: boolean;
  allowGoBack?: boolean;
  transparent?: boolean;
  children: ReactNode;
  logo?: ReactNode;
};

const PageWrapper: React.FC<PageWrapperProps> = ({
  logo,
  children,
  pageTitle,
  hideHeader = false,
  allowGoBack = false,
  transparent = false,
  hideMenu = false,
  hideLogo = false,
}) => {
  const { desktopView, slug } = useGlobal();
  const location = useLocation();

  return (
    <main
      id='main-consumer-app'
      className={`absolute left-0 top-0 w-full h-full 
        ${
          desktopView && location.pathname.includes(`/c/${slug}`)
            ? 'lg:flex lg:flex-row-reverse lg:items-start lg:justify-between'
            : ''
        }
      `}
    >
      {(!desktopView ||
        (desktopView && !location.pathname.includes(`/c/${slug}/video`))) && (
        <PreviewPane>
          {!hideHeader && (
            <Header
              transparent={transparent}
              allowGoBack={allowGoBack}
              pageTitle={pageTitle}
              hideMenu={hideMenu}
              hideLogo={hideLogo}
              logo={logo}
            />
          )}
        </PreviewPane>
      )}
      <div
        className={`relative w-full h-full 
          ${
            desktopView && !location.pathname.includes(`/c/${slug}`)
              ? 'lg:flex lg:flex-col mx-auto'
              : ''
          } 
          ${hideHeader || transparent ? '' : 'max-h-[calc(100%_-_5.25rem)]'}
        `}
      >
        {children}
      </div>
    </main>
  );
};

export default PageWrapper;
