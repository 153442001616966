import {
  getAuth,
  signInWithPopup,
  signInWithCustomToken,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  isSignInWithEmailLink,
  signInWithEmailLink,
  Auth,
  User,
} from 'firebase/auth';

const user = {
  email: 'preview@brij.it',
  uid: 'wzCBEH5J3wVu3qksoFOLlfk88Mq2',
};

const addToken = (user: any) => {
  return {
    ...user,
    getIdToken: async () =>
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.JYymD2Sl-fZpPyMmIuY54HNjvR3f_TMF48IGrHM5TRQ',
  };
};

const getAuthPreview = () => {
  const onAuthStateChanged = (fn: Function) => {
    const stateChangeFunc = (event: any) => {
      if (event?.data?.type === 'authStateChanged') {
        fn(event?.data?.data ? addToken(event?.data?.data) : null);
      }
    };

    window.addEventListener('message', stateChangeFunc);

    return () => {
      window.removeEventListener('message', stateChangeFunc);
    };
  };

  return { onAuthStateChanged };
};

const signOutPreview = async () => {
  window.parent.postMessage({ type: 'signOut' }, '*');
  return;
};

const signInWithPopupPreview = () => {
  return new Promise((resolve) => {
    window.parent.postMessage({ type: 'loggedInUser', data: user }, '*');
    resolve({ user: addToken(user) });
  });
};

const signInWithCustomTokenPreview = async () => {
  return new Promise((resolve) => {
    window.parent.postMessage({ type: 'loggedInUser', data: user }, '*');
    resolve({ user: addToken(user) });
  });
};

const signInWithEmailLinkPreview = async () => {
  return new Promise((resolve) => {
    window.parent.postMessage({ type: 'loggedInUser', data: user }, '*');
    resolve({ user: addToken(user) });
  });
};

const useFirebase = () => {
  if ((window as any).location.href.includes(1234)) {
    return {
      getAuth: getAuthPreview as unknown as () => Auth,
      signInWithPopup: signInWithPopupPreview as unknown as () => Promise<{
        user: User;
      }>,
      signInWithCustomToken:
        signInWithCustomTokenPreview as unknown as () => Promise<{
          user: User;
        }>,
      GoogleAuthProvider,
      FacebookAuthProvider,
      signOut: signOutPreview as unknown as () => Promise<void>,
      isSignInWithEmailLink: () => false,
      signInWithEmailLink:
        signInWithEmailLinkPreview as unknown as () => Promise<{ user: User }>,
    };
  }

  switch ((window as any).mode) {
    case 'preview':
      return {
        getAuth: getAuthPreview as unknown as () => Auth,
        signInWithPopup: signInWithPopupPreview as unknown as () => Promise<{
          user: User;
        }>,
        signInWithCustomToken:
          signInWithCustomTokenPreview as unknown as () => Promise<{
            user: User;
          }>,
        GoogleAuthProvider,
        FacebookAuthProvider,
        signOut: signOutPreview as unknown as () => Promise<void>,
        isSignInWithEmailLink: () => false,
        signInWithEmailLink:
          signInWithEmailLinkPreview as unknown as () => Promise<{
            user: User;
          }>,
      };
    default:
      return {
        getAuth,
        signInWithPopup,
        signInWithCustomToken,
        GoogleAuthProvider,
        FacebookAuthProvider,
        signOut,
        isSignInWithEmailLink,
        signInWithEmailLink,
      };
  }
};

export default useFirebase;
