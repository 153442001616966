import { OptionItemType } from 'types/ProductDetailsType';

export const validateOption = (optionItem: OptionItemType) => {
  const defaultOption = {
    name: 'Title',
    values: ['Default Title'],
  };
  if (defaultOption.values.length !== optionItem.values.length) return true;
  else if (
    defaultOption.name === optionItem.name &&
    defaultOption.values[0] === optionItem.values[0]
  )
    return false;
  else return true;
};
