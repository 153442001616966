import React, { useEffect, useState } from 'react';
import { UrlParam } from 'types/Misc';
import { hex2rgba } from 'utils/hexToRgba';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SocialsType } from 'types/SocialsType';
import { isDesktop } from 'react-device-detect';
import { isLightColor, shadeChanger } from 'utils/shadeChanger';
import { SmsOptInType } from 'types/SmsOptInTypes';
import { validateSocials } from 'utils/validateSocials';
import { showToast } from 'components/atomic/Toast/Toast';
import { ReactComponent as Checkmark } from 'assets/icons/svg/circular-checkmark.svg';
import { ReactComponent as SmsMessageIcon } from 'assets/icons/svg/sms-message.svg';
import {
  CornerStyle,
  useThemeContext,
} from 'context/ThemeContext/ThemeContext';
import { ReactComponent as OneIcon } from 'assets/icons/svg/one.svg';
import { ReactComponent as TwoIcon } from 'assets/icons/svg/two.svg';
import { ReactComponent as FourIcon } from 'assets/icons/svg/four.svg';
import { ReactComponent as FiveIcon } from 'assets/icons/svg/five.svg';
import { ReactComponent as ThreeIcon } from 'assets/icons/svg/three.svg';
import { ReactComponent as PayoutIcon } from 'assets/icons/svg/payout.svg';
import { ReactComponent as CartIcon } from 'assets/icons/svg/blue-cart.svg';
import { ReactComponent as ReceiptIcon } from 'assets/icons/svg/receipt-button.svg';
import ConsentCheck from 'components/atomic/ConsentCheck';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import SocialIcons from 'components/atomic/SocialIcons';
import useRedirectLink from 'hooks/useRedirectLink';
import TelInput from 'components/atomic/TelInput';
import Button from 'components/atomic/Button';
import Cookies from 'js-cookie';
import envVars from 'env-vars';

type SmsOptInProps = {
  logo: boolean;
  data: SmsOptInType | null;
  socials: SocialsType;
  brandName: string;
  brandId: string;
};

const getInstructionIcon = (iconKey: string, iconColor: string) => {
  switch (iconKey) {
    case 'one':
      return <OneIcon width={30} height={30} fill={iconColor} />;
    case 'two':
      return <TwoIcon width={30} height={30} fill={iconColor} />;
    case 'three':
      return <ThreeIcon width={30} height={30} fill={iconColor} />;
    case 'four':
      return <FourIcon width={30} height={30} fill={iconColor} />;
    case 'five':
      return <FiveIcon width={30} height={30} fill={iconColor} />;
    case 'payout':
      return <PayoutIcon width={30} height={30} fill={iconColor} />;
    case 'blue-cart':
      return <CartIcon width={30} height={30} fill={iconColor} />;
    case 'receipt-button':
      return <ReceiptIcon width={30} height={30} fill={iconColor} />;
    default:
      return (
        <div className='w-8 h-8 min-w-8 min-h-8 aspect-square rounded-full bg-gray-300 opacity-50' />
      );
  }
};

const SmsOptIn: React.FC<SmsOptInProps> = ({
  logo,
  data,
  socials,
  brandId,
  brandName,
}) => {
  const [consentChecked, toggleConsentChecked] = useState<boolean>(false);
  const [numberField, toggleNumberField] = useState<boolean>(isDesktop);
  const [consentError, setConsentError] = useState<boolean>(false);
  const [textSent, toggleTextSent] = useState<boolean>(false);
  const [textNumber, setTextNumber] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const redirectToExternalLink = useRedirectLink();
  const { menuStyle, brandTheme, buttonStyle, secondaryBrandColor } =
    useThemeContext();
  const { id } = useParams<UrlParam>();
  const { t } = useTranslation('translation', {
    keyPrefix: 'smsOptIn',
  });

  useEffect(() => {
    if (
      data?.optInComponent?.consentBox &&
      data?.optInComponent?.checkedAsDefault
    )
      toggleConsentChecked(true);
  }, [data?.optInComponent]);

  useEffect(() => {
    if (data?.disableTextToOptinOnMobile) toggleNumberField(true);
  }, [data?.disableTextToOptinOnMobile]);

  const customLegalText = data?.optInComponent?.consentBox
    ? data?.optInComponent?.consentBoxText
    : '';

  const defaultLegalText = data?.customLegalConsent
    ? data?.customLegalText
    : t('defaultLegalText')
        .replace('{{brand_name}}', brandName)
        .replace('https://brij.it/terms', `/p/${brandId}`);

  // TEXT US
  const openSMSApp = async () => {
    // ALSO LOG THE USER EVENT HERE
    const API_URL =
      envVars.REACT_APP_STAND_ALONE_MODE === 'false'
        ? envVars.REACT_APP_API_URL
        : '';

    if (
      data?.optInComponent?.consentBox &&
      data?.optInComponent?.consentRequired &&
      !consentChecked
    ) {
      setConsentError(true);
      showToast({
        message: t('consentRequiredError'),
        type: 'error',
      });
    } else {
      setLoading(true);
      const rebateId: string = Cookies.get(`rebateId_${id}`) || '';

      if (rebateId) {
        const url = `${API_URL}/app_api/rebate/${rebateId}/redirect`;
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              consent: {
                consentType: data?.optInComponent?.consentBox
                  ? 'explicit'
                  : 'implicit',
                consentFor: 'sms',
                defaultLanguage: defaultLegalText,
                language: customLegalText,
                allowed: data?.optInComponent?.consentBox
                  ? consentChecked
                  : true,
              },
            }),
          });

          if (!response.ok) {
            console.error('Error:', await response.json());
            showToast({
              message: t('errorSendingRequest'),
              type: 'error',
            });
          } else {
            const data = await response.json();
            redirectToExternalLink(data.redirectUrl, '_self');
          }
        } catch (error) {
          showToast({
            message: t('errorSendingRequest'),
            type: 'error',
          });
          console.error('Error:', error);
        }
      } else showToast({ message: t('rebateIdError'), type: 'error' });
      setLoading(false);
    }
  };

  // TEXT ME
  const sendOptInRequest = async () => {
    // ALSO LOG THE USER EVENT HERE
    const API_URL =
      envVars.REACT_APP_STAND_ALONE_MODE === 'false'
        ? envVars.REACT_APP_API_URL
        : '';

    if (textNumber.replace(/[\s()_-]/g, '').length !== 12) {
      showToast({
        message: t('invalidPhoneNumberError'),
        type: 'error',
      });
      return;
    }
    if (
      data?.optInComponent?.consentBox &&
      data?.optInComponent?.consentRequired &&
      !consentChecked
    ) {
      setConsentError(true);
      showToast({
        message: t('consentRequiredError'),
        type: 'error',
      });
    } else {
      setLoading(true);
      const rebateId: string = Cookies.get(`rebateId_${id}`) || '';

      if (rebateId) {
        const url = `${API_URL}/app_api/rebate/${rebateId}/text`;
        const body = {
          phoneNumber: textNumber.replace(/[\s()-]/g, ''),
          consent: {
            consentType: data?.optInComponent?.consentBox
              ? 'explicit'
              : 'implicit',
            consentFor: 'sms',
            defaultLanguage: defaultLegalText,
            language: customLegalText,
            allowed: data?.optInComponent?.consentBox ? consentChecked : true,
          },
        };
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
          });
          if (response?.status === 409) {
            showToast({
              message: t('rebateAlreadyClaimedError'),
              type: 'error',
            });
          } else if (!response.ok) {
            console.error('Error:', await response.json());
            showToast({
              message: t('errorSendingRequest'),
              type: 'error',
            });
          } else {
            console.log('Success:', await response.json());
            showToast({
              message: t('messageSentSuccessfully'),
              type: 'success',
            });
          }
        } catch (error) {
          showToast({
            message: t('errorSendingRequest'),
            type: 'error',
          });
          console.error('Error:', error);
        }
      } else showToast({ message: t('rebateIdError'), type: 'error' });
      setLoading(false);
    }
  };

  const getCornerStyle = (isMenuStyle: boolean, styleProperty: string) => {
    switch (styleProperty) {
      case CornerStyle.FULL_ROUND:
        return isMenuStyle ? 'rounded-3xl' : 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-sm1';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return isMenuStyle ? 'rounded-3xl' : 'rounded-full';
    }
  };

  console.log('OPTIN DATA: ', data);

  return (
    <>
      {data?.dimBackground && (
        <div className='absolute top-0 left-0 w-full h-full bg-black/50 duration-300' />
      )}
      <div
        className={`absolute top-0 left-0 w-full h-full flex flex-col justify-between items-stretch ${
          logo ? 'max-h-[calc(100%-5.25rem)] mt-[5.25rem]' : 'max-h-full mt-0'
        }`}
      >
        <div
          className={`relative w-full h-full px-5 overflow-auto 
            ${
              data?.socialMediaIcons
                ? 'max-h-[calc(100%-5.25rem)] pb-5'
                : 'max-h-full pb-6'
            } 
            ${logo ? 'pt-0' : 'pt-4'}
          `}
        >
          <div
            className={`
              ${
                data?.optInComponent?.showBackground
                  ? 'py-4 px-6 shadow-md'
                  : 'p-4 shadow-none'
              }
              ${getCornerStyle(true, menuStyle)}
            `}
            style={{
              background: data?.optInComponent?.showBackground
                ? data?.optInComponent?.backgroundColor || '#FFFFFF'
                : 'transparent',
            }}
          >
            {data?.instructions?.showSteps && (
              <div className='w-full relative flex flex-col items-center justify-center mt-2 mb-3 rounded-[14px] border border-solid border-primary overflow-hidden'>
                <div className='w-full relative flex items-center justify-center px-4 py-2 bg-primary'>
                  <p className='font-semibold text-white text-lg'>
                    {data?.instructions.title}
                  </p>
                </div>
                <ul className='relative w-full flex flex-col items-start justify-start px-4 py-3 gap-3'>
                  {data?.instructions?.messages.map((message, index) => (
                    <li
                      key={`${message?.title}-${index}`}
                      className='w-full flex flex-row items-center justify-start gap-2'
                    >
                      <div className='flex items-center justify-center w-instructionIcon h-instructionIcon min-w-instructionIcon min-h-instructionIcon rounded-full overflow-hidden bg-primary'>
                        {getInstructionIcon(
                          message?.icon,
                          secondaryBrandColor || '#FFFFFF'
                        )}
                      </div>
                      <p className='font-medium text-darkGray text-xs'>
                        {message?.title}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <HtmlWrapper
              html={data?.optInComponent?.content}
              background='transparent'
              styles='!mb-2'
            />
            {numberField && textSent && (
              <div
                className={`flex flex-row items-center justify-start px-3 py-1.5 gap-3 mb-1.5 ${getCornerStyle(
                  false,
                  buttonStyle
                )}`}
                style={{ background: hex2rgba(brandTheme, 0.1) }}
              >
                <Checkmark />
                <p className='font-medium text-xxs text-primary [&_span]:underline [&_span]:cursor-pointer'>
                  {t('messageSentText')}
                  <span
                    onClick={() => {
                      toggleTextSent(false);
                      toggleConsentChecked(false);
                      setTextNumber('');
                    }}
                  >
                    {t('trySendingAgain')}
                  </span>
                  .
                </p>
              </div>
            )}
            {data?.optInComponent?.consentBox && !textSent && (
              <ConsentCheck
                styles={isDesktop ? '!mb-1' : `!mb-2`}
                enabled={[true]}
                errors={[consentError]}
                consentChecks={[consentChecked]}
                texts={[data?.optInComponent?.consentBoxText || '']}
                toggleConsentChecks={[toggleConsentChecked]}
              />
            )}
            <div
              className={`w-full duration-200 overflow-hidden ${
                numberField ? 'h-0' : 'h-[58px]'
              }`}
            >
              <Button
                variant='dark'
                styles='!text-sm !px-12 !mt-1 !duration-200'
                onClick={() => openSMSApp()}
                title={
                  data?.optInComponent?.customButtonCTA
                    ? data?.optInComponent?.customButtonCTAMobileText
                    : t('sendMessageButton')
                }
                icon={
                  <SmsMessageIcon
                    width={32}
                    fill={brandTheme}
                    className='absolute left-3.5'
                  />
                }
              />
            </div>
            <div
              className={`
                relative w-full flex flex-col overflow-hidden 
                ${textSent ? 'duration-0' : 'duration-200'} 
                ${
                  numberField
                    ? `${
                        isDesktop
                          ? `pt-1 ${textSent ? 'h-0' : 'h-[140px]'}`
                          : `${textSent ? 'h-0' : 'h-[172px]'}`
                      }`
                    : 'h-[40px]'
                }
              `}
            >
              {numberField ? (
                <>
                  {!textSent && (
                    <>
                      <TelInput
                        value={textNumber}
                        mask='(+1) 999 999 9999'
                        name='phone-number-input'
                        styles='!h-input !flex-shrink-0 mt-1'
                        placeholder={t('phoneNumberInputPlaceholder')}
                        onChange={(event) => setTextNumber(event.target.value)}
                      />
                      <Button
                        variant='dark'
                        loading={loading}
                        styles='!text-sm !px-12 !mt-3 !duration-200'
                        onClick={() => sendOptInRequest()}
                        title={
                          data?.optInComponent?.customButtonCTA
                            ? data?.optInComponent?.customButtonCTADesktopText
                            : t('receiveMessageButton')
                        }
                        icon={
                          <SmsMessageIcon
                            width={32}
                            fill={brandTheme}
                            className='absolute left-3.5'
                          />
                        }
                      />
                      {!isDesktop && (
                        <Button
                          variant='link'
                          title={t('sendUsText')}
                          onClick={() => toggleNumberField(false)}
                          styles={`!text-sm !h-8 mt-2 ${
                            isLightColor(
                              data?.optInComponent?.backgroundColor ||
                                '#FFFFFF',
                              130
                            )
                              ? '!text-black'
                              : '!text-white/90'
                          }`}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <Button
                  variant='link'
                  title={t('sendMeText')}
                  onClick={() => toggleNumberField(true)}
                  styles={`!text-sm !h-8 mt-0.5 ${
                    isLightColor(
                      data?.optInComponent?.backgroundColor || '#FFFFFF',
                      130
                    )
                      ? '!text-black'
                      : '!text-white/90'
                  }`}
                />
              )}
            </div>
            <HtmlWrapper
              html={
                data?.customLegalConsent
                  ? data?.customLegalText
                  : t('defaultLegalText')
                      .replace('{{brand_name}}', brandName)
                      .replace('https://brij.it/terms', `/p/${brandId}`)
              }
              background='transparent'
              styles='px-2.5 [&_p]:!text-xxxs [&_p]:!leading-[12px] text-justify [&_p]:!opacity-50'
              paragraphTextColor={
                isLightColor(
                  data?.optInComponent?.backgroundColor || '#FFFFFF',
                  130
                )
                  ? shadeChanger('#000000', 50)
                  : shadeChanger('#FFFFFF', 30)
              }
            />
          </div>
        </div>
        {data?.socialMediaIcons && validateSocials(socials) && (
          <div className='relative w-full h-[5.688rem] flex items-center bg-transparent border-solid border-0 border-t border-white/20'>
            <SocialIcons socials={socials} darkIcons />
          </div>
        )}
      </div>
    </>
  );
};

export default SmsOptIn;
