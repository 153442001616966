import React from 'react';
import { GlobalStyle } from './styles';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';

const ThemedGlobalStyle: React.FC<any> = (props) => {
  const { inputStyle } = useThemeContext();
  const { ...restProps } = props;

  return <GlobalStyle borderStyle={inputStyle} {...restProps} />;
};

export default ThemedGlobalStyle;
