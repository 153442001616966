import React from 'react';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as Checkmark } from 'assets/icons/svg/checkbox-checkmark.svg';

type ConsentCheckProps = {
  texts: string[];
  styles?: string;
  errors?: boolean[];
  enabled: boolean[];
  consentChecks?: boolean[];
  toggleConsentChecks?:
    | React.Dispatch<React.SetStateAction<boolean>>[]
    | ((value: boolean) => void)[];
};

const ConsentCheck: React.FC<ConsentCheckProps> = ({
  texts,
  errors,
  styles,
  enabled,
  consentChecks,
  toggleConsentChecks,
}) => {
  const { inputStyle } = useThemeContext();

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-sm1';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-[26px]';
    }
  };

  return (
    <div
      className={`
        relative w-full flex flex-col py-1.5 px-3 gap-1.5 mb-1 bg-lightgray cursor-pointer border-2 border-solid 
        ${
          errors &&
          errors?.some((error) => error === true) &&
          consentChecks?.some((consentCheck) => consentCheck === false)
            ? 'border-warning'
            : 'border-lightgray'
        } 
        ${getCornerStyle()} 
        ${styles}
      `}
    >
      {consentChecks?.map((consentCheck, index) =>
        enabled[index] ? (
          <button
            key={index}
            type='button'
            className='relative w-full flex flex-row items-center gap-3'
            onClick={() => {
              if (toggleConsentChecks)
                toggleConsentChecks[index](!consentCheck);
            }}
          >
            <div
              className={`min-w-[18px] w-[18px] h-[18px] m-[3px] flex items-center justify-center aspect-square rounded-sm ${
                consentCheck ? 'border-none' : 'border-2 border-solid'
              }`}
            >
              {consentCheck ? <Checkmark /> : null}
            </div>
            {texts && texts[index] && (
              <div
                dangerouslySetInnerHTML={{ __html: texts[index] }}
                className='text-xxs text-black font-medium text-left leading-[15px] [&_a]:underline'
              />
            )}
          </button>
        ) : null
      )}
    </div>
  );
};

export default ConsentCheck;
