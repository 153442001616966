import {
  useState,
  useEffect,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  ModuleInfoType,
  SweepstakesModuleType,
} from 'types/ProductDetailsType';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ProductDetailsType } from 'types/ProductDetailsType';
import { GameRulesType } from 'types/GameRulesType';
import { getFontWeightStyle } from 'utils/fonts';
import { getLocalStorage } from 'utils/storage';
import { theme } from 'styles/theme';
import { useAPI } from 'utils/api';

function useGameRules(
  productDetails: ProductDetailsType | null,
  productLoading: boolean,
  slug: string | null,
  token: string | null = null
): [
  any | null,
  Dispatch<SetStateAction<any | null>>,
  boolean,
  Dispatch<SetStateAction<boolean>>
] {
  const [gameRulesLoading, setGameRulesLoading] = useState<boolean>(false);
  const [sweepstakesGameRules, setSweepstakesGameRules] =
    useState<GameRulesType | null>(null);
  const fetchProductRef = useRef<any>(null);

  const { setBrandTheme, setSecondaryBrandColor } = useThemeContext();

  const getSweepstakesFromProduct = (property: 'moduleId' | 'gamerules') => {
    if (productDetails) {
      let sweepstakesModule: ModuleInfoType | undefined =
        productDetails?.modules?.find(
          (module) => module.type === 'SWEEPSTAKES_MODULE'
        );
      if (sweepstakesModule) {
        let sweepstakesModuleInfo: SweepstakesModuleType =
          sweepstakesModule.moduleInfo as SweepstakesModuleType;
        return property === 'moduleId'
          ? sweepstakesModule?.id || ''
          : sweepstakesModuleInfo?.gameRules || '';
      } else return '';
    } else return '';
  };

  useEffect(() => {
    if (
      slug &&
      sweepstakesGameRules?.fonts &&
      sweepstakesGameRules?.fonts.length > 0 &&
      window.location.pathname.includes('/s/')
    ) {
      const fonts = sweepstakesGameRules?.fonts;

      let fontCSS = fonts.reduce(
        (css: string, fontObject: { name: string; src: string }) =>
          css.concat(`
            @font-face {
              font-family: brandFont;
              src: url(${fontObject.src});
              font-weight: ${getFontWeightStyle(fontObject.name).weight};
              font-style: ${getFontWeightStyle(fontObject.name).style};
            }
          `),
        ''
      );
      const style = document.createElement('style');
      style.innerHTML = fontCSS;
      document.head.appendChild(style);
    }
  }, [sweepstakesGameRules?.brandId, slug, sweepstakesGameRules?.fonts]);

  const onSuccess = useCallback(
    // UPDATE THE TYPE HERE
    (sweepstakesGameRules: any) => {
      setSweepstakesGameRules({
        ...sweepstakesGameRules,
      });

      if (sweepstakesGameRules?.customAccentColor) {
        setBrandTheme(sweepstakesGameRules.customAccentColor);
        getLocalStorage().setItem(
          'accentColor',
          sweepstakesGameRules?.customAccentColor
        );
      } else {
        setBrandTheme(theme.primary);
        getLocalStorage().setItem('accentColor', '');
      }
      if (sweepstakesGameRules?.secondaryColor) {
        setSecondaryBrandColor(sweepstakesGameRules.secondaryColor);
        getLocalStorage().setItem(
          'secondaryColor',
          sweepstakesGameRules?.secondaryColor
        );
      } else {
        setSecondaryBrandColor(theme.secondary);
        getLocalStorage().setItem('secondaryColor', '');
      }

      setGameRulesLoading(false);
      if (fetchProductRef.current) fetchProductRef.current = null;
    },
    [setGameRulesLoading, setBrandTheme, setSecondaryBrandColor]
  );

  const onError = useCallback(
    (error) => {
      setGameRulesLoading(false);
      if (error.status === 404) window.location.href = `/c/${slug}`;
      else if (error.status === 400) window.location.href = '/app/404';
    },
    [slug]
  );

  const [getSweepstakesGameRules] = useAPI(
    {
      method: 'GET',
      endpoint: `gamerules/${slug ? slug : ''}`,
      onError,
    },
    token,
    true,
    true
  );

  const getGameRulesWithLoading = useCallback(
    async (...args) => {
      if (!slug) return;

      if (fetchProductRef.current) return fetchProductRef.current;

      setGameRulesLoading(true);
      fetchProductRef.current = getSweepstakesGameRules(...args);

      // @ts-ignore
      const cache = await fetchProductRef.current;
      if (cache) onSuccess(cache);

      return;
    },
    [getSweepstakesGameRules, onSuccess, slug]
  );

  useEffect(() => {
    if (productDetails && productDetails?.tag.slug === slug) {
      setSweepstakesGameRules({
        brandId: productDetails?.brand.id,
        moduleId: getSweepstakesFromProduct('moduleId'),
        gamerules: getSweepstakesFromProduct('gamerules'),
        website: productDetails?.brand.website,
        customAccentColor: productDetails?.brand.customAccentColor || '',
        secondaryColor: productDetails?.brand.secondaryColor,
        fonts: productDetails?.brand.fonts,
      });
      return;
    } else if (
      !productLoading &&
      productDetails?.tag.slug !== slug &&
      window.location.pathname.includes('/s/')
    ) {
      getGameRulesWithLoading();
    } else return;
  }, [productDetails, productLoading, slug, getGameRulesWithLoading]);

  return [
    sweepstakesGameRules,
    setSweepstakesGameRules,
    gameRulesLoading,
    setGameRulesLoading,
  ];
}

export default useGameRules;
