import { RegistrationType } from '../utils/getRegisterText';
import { ContentGateType } from './ContentGateType';
import { FormDetailModel } from './FormTypes';
import { SmsOptInType } from './SmsOptInTypes';

export enum ApprovalStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  DENIED = 'denied',
  EXPIRED = 'expired',
  INCOMPLETE = 'incomplete',
}

export type PossibleModulesType =
  | 'WARRANTY_MODULE'
  | 'SWEEPSTAKES_MODULE'
  | 'CUSTOM_MODULE'
  | 'LINK_MODULE'
  | 'SHOPPING_MODULE'
  | 'REGISTRATION_MODULE'
  | 'REFERRAL_MODULE'
  | 'FORMS_MODULE'
  | 'VIDEO_MODULE'
  | 'DOCUMENT_MODULE'
  | 'DISCOUNT_MODULE'
  | 'IFRAME_MODULE'
  | 'AB899_MODULE'
  | 'REGISTER_PRODUCT';

export interface Product {
  // unique id of the product
  id: string;
  displayName: string;
  // name of the product
  name: string;
  // image of the product
  image: string;
  // @sush: I need to clarify where to get this from. For now, just set it to false
  registered: boolean;
  // is the product registered to currently signed in user
  registeredToCurrentUser: boolean;
  // ageGate field in document
  ageGateEnabled: boolean;
  // age limit for the age gate
  ageLimit: number;
  // content gate is enabled for the product
  enableContentGate: boolean;
  // content gate details
  contentGateModule: ContentGateType;
  // date on which the product was registered
  registeredDate: string;
  // subtitle for bottom drawer
  subtitle: string;
  // defines the type of tag
  tagType: string;
  // product description for shopping drawer
  productDescription: string;
  // auto deploy registration module for the product
  autoDeploy: boolean;
  // whether to render bottom drawer or digital hub
  qrCodeDestination: string;
  // custom CTA text for more button of Bottom Drawer
  customCTAText: string;
  // dull background media in Digital Hub
  dimBackground: boolean;
  // allow display of social icons in Digital Hub
  allowSocialIcons: boolean;
  // alternate logo
  alternateLogo: string;
  // display alternate logo
  alternateLogoOption: boolean;
  // hex color value for digital hub background
  customDigitalHubColor?: string;
  // should the color be displayed instead of product media as digital hub background
  customHubColorSelected?: boolean;
  // does the product have its own custom theme
  customTheme?: boolean;
  // More button theme configuratio
  secondaryButtonTheme?: {
    enabled: boolean;
    background: string;
    border: string;
    text: string;
  };
  // custom theme config
  customThemeConfig?: {
    buttonStyle: string;
    customAccentColor: string;
    customBgColor: string;
    inputStyle: string;
    menuStyle: string;
    secondaryColor: string;
  };
  // content for the SMS opt-in page
  smsOptIn?: SmsOptInType;
  // desktop view for product
  enableDesktopView: boolean;
}

export interface ModuleThemeType {
  // text color for the module button
  textColor: string;
  // background color for the module button
  borderColor: string;
  // background color for the module button
  backgroundColor: string;
  // is module theme enabled
  enabled?: boolean;
}

export type ModuleInfoType = {
  // module Id
  id: string;
  // module type
  type: PossibleModulesType;
  // title to be displayed
  title: string;
  // this indicates if the module should open automatically on page load
  autoDeploy: boolean;
  // this indicates whether we need an actual login to unlock this module
  registrationRequired: boolean;
  // actual module info. Note, this field is optional. It will be present if registrationRequired is set to false. If registrationRequired is true, then this field is set to null
  moduleTheme: ModuleThemeType;
  moduleInfo:
    | CustomModuleType
    | LinkModuleType
    | WarrantyModuleType
    | SweepstakesModuleType
    | ShoppingModuleType
    | ReferralModuleType
    | FormDetailModel[]
    | VideoModuleType
    | AB899ModuleType
    | DocumentModuleType
    | DiscountModuleType
    | null;

  hideClosePopup?: boolean;
  content?: string | null;
  startScreenContent?: string;
  endScreenContent?: string;
  name?: string;
  confirmationHeader?: string;
  confirmationMessage?: string;
  isAlreadySubmitted?: boolean;
  singleSubmission?: boolean;
  postRegCTA?: string;
  deniedMessage?: string;
  enablePostRegCTA?: boolean;
  enableWarrantyStateMessages?: boolean;
  pendingMessage?: string;
};

export type CustomModuleType = {
  // content field
  content: string;
};

export type VideoModuleType = {
  // path to the video asset
  path: string;
  youtubeEmbed: boolean;
};

export type ToxicElementLimitType = {
  _id?: string;
  label: string;
  value: string;
  amount: string;
};

export interface ToxicElementResultsType {
  enabled: boolean;
  hideNullValues: boolean;
  hidePostExpirationResults: boolean;
  // results config for plasticizers
  plasticizersResults: ToxicElementResultsType;
  hideTestingDate: boolean;
  hideExpirationDate: boolean;
  // results config for pesticides and glyphosate
  pesticidesAndGlyphosateResults: ToxicElementResultsType;
  overrideNonNumericResults: {
    enabled: boolean;
    overrideText: string;
  };
  educationalText: {
    enabled: boolean;
    text: string;
  };
  includeLinkToTestResultsDocument: {
    enabled: boolean;
    linkText: string;
  };
  overrideBelowthreshold: {
    enabled: boolean;
    overrideText: string;
    thresholdValues: ToxicElementLimitType[];
  };
  showStandardLimits: {
    enabled: boolean;
    standardLimits: ToxicElementLimitType[];
    enableAlerts: {
      enabled: boolean;
      alertText: string;
    };
  };
}

export type AB899ModuleType = {
  // pre lookup html content field
  preLookup?: string;
  // post lookup html content field
  postLookup?: string;
  // enable UPC search
  enableSearchByUpc: {
    enabled: boolean;
    required: boolean;
    ignoreSpecialCharacters: boolean;
  };
  // enabled Lot Number search
  enableSearchByLotNumber: {
    enabled: boolean;
    required: boolean;
    ignoreSpecialCharacters: boolean;
  };
  // custom message when no results are found
  customizeNoMessageResult: {
    enabled: boolean;
    message: string;
  };
  // results config for heavy metals
  heavyMetalsResults: ToxicElementResultsType;
  // results config for plasticizers
  plasticizersResults: ToxicElementResultsType;
  // results config for pesticides and glyphosate
  pesticidesAndGlyphosateResults: ToxicElementResultsType;
};

export type LinkModuleType = {
  // link refers to destination field
  link: string;
};

export type SweepstakesModuleType = {
  content: string;
  gameRules: string;
  showSweepstakesEntryStatus: boolean;
  customizeSweepstakesStateMessages: boolean;
  entryActiveMessage: string;
  entryPendingReviewMessage: string;
  entryDeniedMessage: string;
  sweepstakesExpiredMessage: string;
  customizePostRegistrationCta: boolean;
  postRegistrationCta: string;
  limitNoOfEntrants: boolean;
  maxNoOfEntries: number;
  sweepstakesStartDate: string; // UTC timestamp
  sweepstakesEndDate: string; // UTC timestamp
  sweepstakesStatus: string;
  registrationId: string | null;
};

export type WarrantyModuleType = {
  // details field
  details: string;
  // period field
  period: number;
  // warranty approval status
  approvalStatus: string | null;
  // show or hide the warranty status and warranty details
  showWarrantyStatus: boolean;
  // duration
  duration: {
    label: string;
    value: string;
  };
  // this field should be stored prob. under user -> profile -> warranty object and created when user registers for warranty
  activated: boolean;
  // same as above, created when user called activateWarranty mutation. If not set, returned undefined
  purchaseDate?: string; // UTC timestamp
  // same as above, created and set when user called activateWarranty mutation, If not set, it is undefined
  expirationDate?: string; // UTC timestamp
  // optional mulberry warranty coverage details
  mulberry?: {
    coverages: string[];
    policyTermsUrl: string;
    warrantyOfferId: string;
    issueDate: string;
    expirationDate: string;
  };
};

export type DiscountModuleType = {
  buttonCta: string;
  details: string;
  discountCode: string;
  discountTerms: string;
  url: string;
};

export type ShoppingModuleOptionsType = {
  // All options presented as name, values array key pair
  name: string;
  value: string;
  values: string[];
};

export type AllOptionsType = Pick<ShoppingModuleOptionsType, 'name' | 'values'>;

export type OptionItemType = {
  name: string;
  values: string[];
};

export type OptionsType = {
  [key: string]: string;
};

export type VariantDetails = {
  /* VariantId */
  id: string;
  /* name of this variant */
  name: string;
  /* Image */
  image: string;
  /* Price of item */
  price: string;
  /* Discounted price if available */
  discountedPrice?: string;
  /* Pricing based on user's location */
  contextualPricing?: {
    amount: string;
    currencyCode: string;
  };
  /* Discounted pricing based on user's location */
  discountedContextualPricing?: string;
  /* Maximum quantity available */
  inventoryQuantity: number;
  /* Checkout link for this variant */
  checkoutUri: string;
  /* These are options list for this variant */
  options?: OptionsType;
  /* Object hash. Use this field on client side to check if the option chosen is valid */
  objectHash?: string;
};

export type ShoppingModuleType = {
  // All of these fields here should not be populated if registrationRequired is true and user token is not present
  id: string;
  description?: string;
  defaultVariantDetails: VariantDetails;
  alternateProductDescription: string;
  displayAlternateProduct: boolean;
  isDiscountAvailable: boolean;
  discountPercentage?: number;
  discountCode?: string;
  isProductLevel: boolean;
  allOptions?: AllOptionsType[];
  recommendations?: ShoppingModuleType[];
  variantDetails?: VariantDetails[];
  showProductPriceHome?: boolean;
};

export type ReferralModuleType = {
  // This is from text field rich html text
  details: string;
  url: string;
  qrcode: string;
};

export type IframeModuleType = {
  iframeCode: string;
  iframeType: 'URL' | 'CODE';
  iframeUrl: string;
  addMargin: boolean;
};

export type DocumentModuleType = {
  // path to the file
  path: string;
};

export type PurchaseTemplate = {
  description: string;
  name: boolean;
  phoneNo: boolean;
  purchaseDate: boolean;
  quantity: boolean;
  receipt: boolean;
  serialNo: boolean;
  includeOther: boolean;
  retailChannel: boolean;
  retailChannels: string[];
  retailChannelsList: {
    value: string;
    label: string;
  }[];
};

export interface ReturnedCollectionType {
  image: string;
  productId: string;
  variantId: string;
  brandId: string;
  brandName: string;
  tagId: string;
  registrationDate: string;
  registrationId: string;
  expirationDate: string;
  purchaseDate: string;
}

export type ProductDetailsType = {
  // brand refers to brandId which is userId in Users collection
  // product refers to productId which is _id in Products collection
  tag: {
    slug: string;
  };
  registration: {
    _id: string;
    confirmationHeader: string;
    confirmationText: string;
    hideSignupOptions: boolean;
    registrationText: string;
    registrationType: RegistrationType;
    formModuleId: string;
    enablePurchaseDetails?: boolean;
    customCallToAction?: string;
    purchaseTemplate?: PurchaseTemplate;
    completeProfile: boolean;
    enableMultiRegistration?: boolean;
    multiRegistrationCTA?: string;
    consentCheck: boolean;
    consentDetail?: {
      text: string;
      requireConsent: boolean;
      defaultChecked: boolean;
    };
    privacyConsent: boolean;
    privacyConsentDetail?: {
      text: string;
      requireConsent: boolean;
      defaultChecked: boolean;
    };
  };
  unitTagDetails?: {
    registered: boolean;
    user: string;
    email: string;
  };
  warrantyInformation: {
    period: number;
    duration: {
      label: string;
      value: string;
    };
  };
  // Find this data in Users => profile
  brand: {
    id: string;
    image: string;
    name: string;
    website: string;
    customAccentColor?: string;
    customBgColor?: string;
    registrationDetails?: string;
    brandLegalName?: string;
    privacyPolicyURL?: string;
    termsAndConditionsURL?: string;
    minimizeBranding: boolean;
    customRegistrationLegalText: boolean;
    registrationLegalText: string;
    buttonStyle: string;
    inputStyle: string;
    menuStyle: string;
    secondaryColor: string;
    hideMenu: boolean;
    reCaptchaScore?: number;
    webappMenuOptions: {
      myProfile: boolean;
      translate: boolean | null;
      visitWebsite: boolean;
      myCollection: boolean;
      signInSignOut: boolean;
      customLinkOne: boolean | null;
      customLinkTwo: boolean | null;
      termsAndPrivacy: boolean;
      removeFromCollection: boolean;
      customLinkOneContent: {
        linkName: string;
        linkUrl: string;
      } | null;
      customLinkTwoContent: {
        linkName: string;
        linkUrl: string;
      } | null;
    };
    social: {
      phone?: string;
      email?: string;
      twitter?: string;
      instagram?: string;
      facebook?: string;
    };
    fonts: any[];
  };
  basicTranslationEnabled?: boolean;
  automaticReceiptApprovalEnabled?: boolean;
  // Find this data in Products
  product: Product;
  modules: ModuleInfoType[];
  digitalModules: ModuleInfoType[];
  // leadModule = true is the first module that should be shown
  leadModule?: ModuleInfoType;
};
