export const ignoreIfPreview = (fn: any) => {
  if ((window as any).mode === 'preview') {
    return;
  }

  return fn();
};

export const isPreviewMode = () => {
  try {
    return (window as any).mode === 'preview';
  } catch (error) {
    return false;
  }
};
