export enum RegistrationType {
  REGISTER = 'Product_Registration',
  SIGNUP = 'Marketing_List_Signup',
  ACTIVATE = 'Warranty_Activation',
  DONATE = 'Donate',
  CUSTOM = 'Custom',
}

export const getRegisterText = (
  registrationType?: RegistrationType,
  customCallToAction?: string
): string => {
  switch (registrationType) {
    case RegistrationType.REGISTER:
      return 'Register';
    case RegistrationType.SIGNUP:
      return 'Sign Up';
    case RegistrationType.ACTIVATE:
      return 'Activate';
    case RegistrationType.DONATE:
      return 'Donate';
    case RegistrationType.CUSTOM:
      return customCallToAction || 'Register';
    default:
      return 'Register';
  }
};
