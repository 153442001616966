import { isPreviewMode } from './preview';

const brijPrefix = `brij-local`;

let localStore: any | null = null;
let localStorageAvailable = true;

export const getLocalStorage = (): any => {
  const updateLocalStore = (state: any) => {
    if (localStorageAvailable) {
      window.localStorage.setItem(brijPrefix, JSON.stringify(state));
    } else {
      localStore = { ...state };
    }
  };

  const inMemoryStorage = {
    getItem: (key: string) => {
      if (isPreviewMode()) {
        return null;
      }

      return localStore[key] || null;
    },
    setItem: (key: string, value: any) => {
      localStore[key] = value;
      updateLocalStore(localStore);
    },
    removeItem: (key: string) => {
      delete localStore[key];
      updateLocalStore(localStore);
    },
    clear: () => {
      localStore = {};
      updateLocalStore(localStore);
    },
    getAllKeys: () => {
      return Object.keys(localStore);
    },
  };

  return inMemoryStorage;
};

const checkIfLocalStorageIsAvailable = () => {
  try {
    localStorage.setItem('isAvailable', 'true');
    localStorage.removeItem('isAvailable');
    localStorageAvailable = true;
  } catch (error) {
    localStorageAvailable = false;
  }
};

const initLocalStorage = () => {
  if (localStorageAvailable) {
    localStore = JSON.parse(window.localStorage.getItem(brijPrefix) || '{}');
  } else {
    localStore = {};
  }
};

(() => {
  checkIfLocalStorageIsAvailable();
  initLocalStorage();
})();
