import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { IFileFrom } from 'types/FileType';
import { uploadToS3 } from 'utils/uploadToS3';
import { useTranslation } from 'react-i18next';
import { FormDetailModel } from 'types/FormTypes';
import { showToast } from 'components/atomic/Toast/Toast';
import { useFormContext } from 'context/FormDrawerContext/FormDrawerContext';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import FileUpload from '../../../atomic/FileUpload';
import FormImage from './FormImage';
import { useGlobal } from 'context/global/GlobalContext';

type FormFileUploadProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any[]) => void;
};

const FormFileUpload: React.FC<FormFileUploadProps> = ({
  formData,
  formRef,
  name,
  updateFormField,
}) => {
  const { setBackDisabled, setNextDisabled, setFileUploading, fileUploading } =
    useFormContext();

  const { productDetails } = useGlobal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.fileUpload',
  });

  useEffect(() => {
    formRef?.validateForm();
  }, []);

  const changeSelectedFile = async (files: FileList) => {
    setBackDisabled(true);
    setNextDisabled(true);
    setFileUploading(true);
    if (!files?.length) {
      updateFormField(name, []);
      setFileUploading(false);
      return;
    }

    const uploaded_files = [...files];
    let updatedSelectedFiles: IFileFrom[] = formRef?.values[name] || [];
    const uploadFilePromiseArr = [];

    for (let i = 0; i < uploaded_files?.length; i++) {
      // only allow images and pdf
      if (
        ![
          'application/pdf',
          'image/png',
          'image/jpg',
          'image/jpeg',
          'video/mp4',
          'video/mkv',
        ].includes(uploaded_files[i].type)
      ) {
        toast.error(t('fileExtensionError'));
        setFileUploading(false);
        return;
      }

      let fileObject: IFileFrom = {
        fileName: uploaded_files[i]?.name,
        fileUrl: '',
      };

      uploadFilePromiseArr.push(async () => {
        try {
          const url = await uploadToS3(uploaded_files[i]);
          let verificationResult;
          if (!url) {
            showToast({
              message: `File "${
                uploaded_files[i].name.length > 30
                  ? `${uploaded_files[i].name.substring(0, 18)}...`
                  : uploaded_files[i].name
              }"${t('failedToUpload')}`,
              type: 'error',
            });
            return {
              ...fileObject,
              fileUrl: '',
            };
          }

          if (
            !!productDetails?.automaticReceiptApprovalEnabled &&
            formData?.property?.name === 'receiptUpload'
          ) {
            try {
              const receiptVerificationResponse = await fetch(
                `https://${window.location.hostname}/shared-api/receipts/process`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    receiptUrl: url,
                    brandId: productDetails?.brand.id,
                    approvalMode: 'Lenient',
                    slug: (
                      window.location.pathname.match(/\/c\/[a-z0-9]+/i) as any
                    )[0]
                      .split('/')
                      .pop(),
                  }),
                }
              );
              const receiptVerificationData =
                await receiptVerificationResponse.json();
              verificationResult = receiptVerificationData.verificationResult;
            } catch (e) {
              console.error(e);
            }
          }

          return {
            ...fileObject,
            fileUrl: url,
            verificationResult,
          };
        } catch (e) {
          throw e;
        }
      });
    }

    if (uploadFilePromiseArr.length) {
      try {
        let results = await Promise.all(uploadFilePromiseArr.map((p) => p()));
        updatedSelectedFiles = updatedSelectedFiles.concat(
          results.filter((result) => result.fileUrl !== '')
        );
      } catch (e) {
        toast.error(t('receiptUploadError'));
        return;
      }
    }

    updateFormField(name, updatedSelectedFiles);
    formRef?.validateForm();
    setBackDisabled(false);
    setNextDisabled(false);
    setFileUploading(false);
  };

  return formRef ? (
    <div className='w-full h-max flex flex-col justify-start overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='my-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <div className='w-full my-3 flex flex-col items-center justify-start'>
        <FileUpload
          multiple
          uploadLimit={5}
          loading={fileUploading}
          changeFile={changeSelectedFile}
          selectedFiles={formRef.values[name]}
          uploadButtonText={formData?.config?.cta}
          setSelectedFiles={(files: IFileFrom[]) =>
            updateFormField(name, files)
          }
        />
      </div>
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default FormFileUpload;
