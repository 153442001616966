import React from 'react';
import Button from 'components/atomic/Button';
import useRedirectLink from 'hooks/useRedirectLink';
import IconButton from 'components/atomic/IconButton';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { DiscountModuleType } from 'types/ProductDetailsType';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showToast } from 'components/atomic/Toast/Toast';
import { useTranslation } from 'react-i18next';

type DiscountDrawerProps = {
  drawerTitle: string;
  discountData: DiscountModuleType;
};

const DiscountDrawer: React.FC<DiscountDrawerProps> = ({
  drawerTitle,
  discountData,
}) => {
  const { brandTheme, buttonStyle } = useThemeContext();
  const redirectToExternalLink = useRedirectLink();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.discountDrawer',
  });

  const borders = {
    largeCorner: 'rounded-[26px]',
    smallCorner: 'rounded-[10px]',
    noCorner: 'rounded-none',
  };

  return (
    <ModuleWrapper drawerTitle={drawerTitle}>
      <HtmlWrapper html={discountData.details} />
      <div
        className={`w-[calc(100%-4px)] flex flex-col items-start my-6 p-3 gap-3 border border-solid border-discountFrame ${borders[buttonStyle]}`}
      >
        <div className='w-full flex flex-row items-center justify-between pr-0.5'>
          <div className='w-max px-2.5 py-[5px] flex flex-col gap-1.5'>
            <p className='text-xs font-medium text-muted'>
              {t('discountCodeHeading')}
            </p>
            <p className='font-semibold text-xs text-black'>
              {discountData.discountCode}
            </p>
          </div>
          <CopyToClipboard
            text={discountData.discountCode}
            onCopy={() => {
              showToast({
                message: t('copiedToClipboard'),
                type: 'success',
              });
            }}
          >
            <IconButton
              variant='outlined'
              iconName='clipboard'
              iconColor={brandTheme}
              borderStyle={buttonStyle}
              size={20}
            />
          </CopyToClipboard>
        </div>
        <Button
          variant='dark'
          title={discountData.buttonCta}
          onClick={() => redirectToExternalLink(discountData.url, '_blank')}
        />
        <p className='text-xxs text-center self-center text-darkGray'>
          {discountData.discountTerms}
        </p>
      </div>
    </ModuleWrapper>
  );
};

export default DiscountDrawer;
