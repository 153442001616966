export const fontsToWeightMap: { [key: string]: any } = {
  regular: {
    style: 'normal',
    weight: 'normal',
  },
  medium: {
    style: 'normal',
    weight: '500',
  },
  semiBold: {
    style: 'normal',
    weight: '600',
  },
  bold: {
    style: 'normal',
    weight: 'bold',
  },
  regularItalic: {
    style: 'italic',
    weight: 'normal',
  },
  mediumItalic: {
    style: 'italic',
    weight: '500',
  },
  semiBoldItalic: {
    style: 'italic',
    weight: '600',
  },
  boldItalic: {
    style: 'italic',
    weight: 'bold',
  },
};

export const getFontWeightStyle = (fontType: string) =>
  fontsToWeightMap[fontType];
