import { MAGIC_ACTION } from 'context/global/GlobalProvider';
import { ModuleInfoType, Product } from 'types/ProductDetailsType';
import ScreenType from 'types/ScreenType';

export const initialScreenToShow = (
  currentModule: Partial<ModuleInfoType> | null | undefined,
  token: string | null,
  registration: any,
  product: Partial<Product>,
  currentScreen: ScreenType,
  magicAction?: MAGIC_ACTION,
  registerProduct?: () => void
): ScreenType | null => {
  if (!currentModule?.registrationRequired) {
    return ScreenType.CURRENT_MODULE;
  }
  if (product?.registeredToCurrentUser) {
    return ScreenType.CURRENT_MODULE;
  }

  if (
    [
      ScreenType.AUTH,
      ScreenType.COMPLETE_PROFILE,
      ScreenType.FORM,
      ScreenType.PURCHASE_DETAILS,
      ScreenType.REGISTER,
      ScreenType.START_REGISTRATION,
    ].includes(currentScreen) &&
    currentModule?.registrationRequired
  ) {
    return currentScreen;
  }

  if (magicAction === MAGIC_ACTION.OPEN_MODULE) {
    return ScreenType.MAGIC_ACTION_AUTH;
  }

  if (!token) {
    return ScreenType.AUTH;
  }

  return ScreenType.REGISTER;
};
