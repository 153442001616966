import React, { useState } from 'react';
import { useGlobal } from 'context/global/GlobalContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { PossibleGtmEventNames } from 'utils/logGtmEvent';
import { getLocalStorage } from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-animated-css';
import Button from 'components/atomic/Button';
import logGtmEvent from 'utils/logGtmEvent';

type AgeGateProps = {
  ageLimit: number;
};

const AgeGate: React.FC<AgeGateProps> = ({ ageLimit }) => {
  const [warningDisplay, setWarningDisplay] = useState<boolean>(false);
  const { secondaryBrandColor } = useThemeContext();
  const { agegateDisplay, toggleAgegateDisplay } = useGlobal();
  const { t } = useTranslation('translation', { keyPrefix: 'ageGate' });

  return agegateDisplay &&
    getLocalStorage().getItem('ageGateChecked') !== 'true' ? (
    <div className='z-[999] absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center p-6 bg-white'>
      <div className='relative w-full flex flex-col items-center justify-center p-5'>
        <h4 className='w-full text-2xl text-center font-medium uppercase text-dark'>
          {t('title')}
        </h4>
        <p className='w-full text-sm text-center font-medium text-gray'>
          {t('subtitle').replace('{{ageLimit}}', ageLimit.toString())}
        </p>
      </div>
      <div className='relative w-full flex flex-col items-center justify-center my-5 gap-3'>
        <Button
          variant='dark'
          title={t('confirmationButton')}
          onClick={() => {
            toggleAgegateDisplay(false);
            getLocalStorage().setItem('ageGateChecked', JSON.stringify(true));
            logGtmEvent(PossibleGtmEventNames.CONTENT_GATE_CHECKED);
          }}
        />
        <Button
          variant='light'
          color={secondaryBrandColor}
          title={t('disapprovalButton')}
          onClick={() => setWarningDisplay(true)}
        />
        <Animated
          isVisible={warningDisplay}
          animationIn='headShake'
          animationOut='slideOutDown'
          animationInDuration={300}
          animationOutDuration={0}
        >
          <p className='relative w-full text-sm font-medium text-center text-warning overflow-x-hidden'>
            {t('warningMessage').replace('{{ageLimit}}', ageLimit.toString())}
          </p>
        </Animated>
      </div>
    </div>
  ) : null;
};

export default AgeGate;
