import React, { useState, useEffect, useRef } from 'react';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import { IframeModuleType } from 'types/ProductDetailsType';

type IframeDrawerProps = {
  drawerTitle: string;
  drawerData: IframeModuleType;
};

const IframeDrawer: React.FC<IframeDrawerProps> = ({
  drawerTitle,
  drawerData,
}) => {
  const [iframeHeight, setIframeHeight] = useState(0);
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      const elem: any = iframeRef.current;
      setIframeHeight(elem.parentElement.clientHeight - 65);
    }
  }, []);

  return (
    <ModuleWrapper drawerTitle={drawerTitle}>
      {/* Iframe Url */}
      {(!drawerData.iframeType || drawerData.iframeType === 'URL') && (
        <iframe
          title='url-iframe'
          ref={iframeRef}
          src={drawerData.iframeUrl || drawerData.iframeCode}
          style={{
            width: '100%',
            position: 'absolute',
            height: `${iframeHeight}px`,
          }}
        />
      )}
      {/* Iframe html code */}
      {drawerData.iframeType === 'CODE' && (
        <iframe
          title='html-code-iframe'
          ref={iframeRef}
          srcDoc={drawerData.iframeCode}
          style={{
            width: '100%',
            position: 'absolute',
            height: `${iframeHeight}px`,
          }}
        />
      )}
    </ModuleWrapper>
  );
};

export default IframeDrawer;
