import { useCallback, useState } from 'react';
import { ReturnedCollectionType } from '../types/ProductDetailsType';
import { useAPI } from '../utils/api';

function useCollection(
  token: string | null = null
): [ReturnedCollectionType[], () => Promise<any>] {
  const [collectionDetails, setCollectionDetails] = useState<
    ReturnedCollectionType[]
  >([]);

  const onSuccess = useCallback((details) => {
    setCollectionDetails(details);
  }, []);

  const isCustomDomain =
    !window.location.host.match(/^(staging|rc)?\.?brij\.it/) &&
    !window.location.host.match('localhost');
  const productsCollectionPath = isCustomDomain
    ? `products/collection?domain=${window.location.hostname}`
    : 'products/collection';
  const [getCollection] = useAPI(
    {
      method: 'GET',
      endpoint: productsCollectionPath,
      onSuccess,
    },
    token
  );

  return [collectionDetails, getCollection];
}

export default useCollection;
