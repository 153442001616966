import React, { useEffect } from 'react';
import { LinkModuleType } from 'types/ProductDetailsType';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import useRedirectLink from 'hooks/useRedirectLink';

type LinkDrawerProps = {
  drawerTitle: string;
  drawerData: LinkModuleType;
  registrationRequired: boolean;
  closeDrawer: () => void;
};

const LinkDrawer: React.FC<LinkDrawerProps> = ({
  drawerTitle,
  drawerData,
  registrationRequired,
  closeDrawer,
}) => {
  const redirectToExternalLink = useRedirectLink();

  useEffect(() => {
    if (!registrationRequired) {
      redirectToExternalLink(
        drawerData.link.includes('https://') ||
          drawerData.link.includes('http://')
          ? drawerData.link
          : `https://${drawerData.link}`,
        '_blank'
      );
      closeDrawer();
    }
  }, [
    registrationRequired,
    drawerData.link,
    closeDrawer,
    redirectToExternalLink,
  ]);

  return <ModuleWrapper drawerTitle={drawerTitle} />;
};

export default LinkDrawer;
