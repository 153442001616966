import React, { useMemo } from 'react';
import { RoutesHashMap } from 'routes';
import { useGlobal } from 'context/global/GlobalContext';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoadingIndicator from 'components/atomic/LoadingIndicator';

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { user, authFetched } = useGlobal();

  const loadingIndicator = useMemo(
    () => (
      <div className='w-full h-screen'>
        <LoadingIndicator />
      </div>
    ),
    []
  );

  if (!authFetched) return loadingIndicator;
  if (user) return <Route {...props} />;

  return <Redirect to={RoutesHashMap.Login.path} />;
};

export default ProtectedRoute;
