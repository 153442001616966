import React, { useCallback, useEffect, useState } from 'react';
import { validatePhoneNumber } from 'utils/validatePhoneNumber';
import { useGlobal } from 'context/global/GlobalContext';
import { showToast } from 'components/atomic/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'utils/api';
import Input from 'components/atomic/Input';
import Button from 'components/atomic/Button';
import PhoneInput from 'components/atomic/PhoneInput';

interface UserUpdatePayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  slug?: string | null;
  brandId?: string;
}

interface PersonalDetailsProps {
  onPersonalDetailsUpdate?: () => void;
  onDetailsSaved?: () => void;
  saveToShopify?: boolean;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  onPersonalDetailsUpdate = () => {},
  onDetailsSaved = () => {},
  saveToShopify,
}) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const { t } = useTranslation('translation', { keyPrefix: 'personalDetails' });
  const { getPersonalDetails, personalDetails, slug, productDetails } =
    useGlobal();

  const onSuccess = useCallback(() => {
    getPersonalDetails();
    onPersonalDetailsUpdate();
  }, [onPersonalDetailsUpdate, getPersonalDetails]);

  useEffect(() => {
    if (personalDetails) {
      setFirstName(personalDetails?.profile?.firstName || '');
      setLastName(personalDetails?.profile?.lastName || '');
      setPhoneNumber(personalDetails?.profile?.phoneNumber || '');
    }
  }, [personalDetails]);

  const [updateUser, loading] = useAPI<UserUpdatePayload>({
    method: 'PUT',
    endpoint: 'auth/update',
    onSuccess,
  });

  const validateUserInformation = useCallback(
    (details: UserUpdatePayload) => {
      if (!details.firstName) {
        showToast({ message: 'First name is required', type: 'error' });
        return;
      }
      if (!details.lastName) {
        showToast({ message: 'Last name is required', type: 'error' });
        return;
      }

      if (details.phoneNumber && !validatePhoneNumber(details.phoneNumber)) {
        showToast({
          message: 'Please add a valid phone number',
          type: 'error',
        });
        return;
      }

      updateUser({
        ...details,
        phoneNumber: details.phoneNumber
          ? details.phoneNumber.replace(/[\s()-]/g, '')
          : phoneNumber.replace(/[\s()-]/g, ''),
        slug: saveToShopify ? slug : null,
        // for giving direct permissions to the brand
        brandId: productDetails?.brand?.id,
      });
      onDetailsSaved();
    },
    [updateUser, slug, saveToShopify, onDetailsSaved, phoneNumber]
  );

  return (
    <div className='relative w-full h-full flex flex-1 flex-col items-center justify-start overflow-auto pb-16'>
      <div className='w-full flex flex-col items-center justify-center py-1'>
        <p className='w-full pb-3 pl-1 pt-2 text-base text-left font-semibold'>
          {t('completeProfile')}
        </p>
        <Input
          type='text'
          styles='my-3'
          name='firstName'
          value={firstName}
          autoComplete='given-name'
          placeholder={t('firstNameInputPlaceholder')}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          type='text'
          styles='mb-1.5'
          name='lastName'
          value={lastName}
          autoComplete='family-name'
          placeholder={t('lastNameInputPlaceholder')}
          onChange={(e) => setLastName(e.target.value)}
        />
        <div className='z-50 relative w-full'>
          <PhoneInput
            bottomOffset={98}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            existingPhoneNumber={personalDetails?.profile.phoneNumber}
            placeholder={t('phoneNumberInputPlaceholder')}
          />
        </div>
      </div>
      <div className='w-full flex flex-col items-center justify-center mt-1.5 gap-3'>
        <Button
          variant='dark'
          loading={loading}
          title={t('continueButton')}
          onClick={() =>
            validateUserInformation({
              firstName,
              lastName,
              phoneNumber,
            })
          }
        />
        <Button
          variant='light'
          title={t('skip')}
          onClick={onPersonalDetailsUpdate}
        />
      </div>
    </div>
  );
};

export default PersonalDetails;
