import { useContext, createContext, Ref } from 'react';

export type RegistrationContextProps = {
  onAuth: (currentToken?: string, isMultipleRegistration?: boolean) => void;
  closePage: () => void;
  isMultipleRegistration: any;
  removeFormRegistration: () => void;
};

export const RegistrationContext = createContext<RegistrationContextProps>({
  onAuth: () => {},
  closePage: () => {},
  isMultipleRegistration: null,
  removeFormRegistration: () => {},
});

export const useRegistrationContext = () => useContext(RegistrationContext);
