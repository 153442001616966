import Cookies from 'js-cookie';
import { getLocalStorage } from './storage';

export enum PossibleGtmEventNames {
  CONTENT_GATE_CHECKED = 'contentGateChecked',
  REGISTRATION_INITIATION = 'registrationInitiation',
  REGISTRATION_COMPLETION = 'registrationCompletion',
}

const logGtmEvent = (eventName: PossibleGtmEventNames, params?: any) => {
  const sessionId: string =
    getLocalStorage().getItem('sessionId') || Cookies.get('sessionId') || '';
  const deviceId: string =
    getLocalStorage().getItem('deviceId') || Cookies.get('deviceId') || '';
  const userDateOfBirth: string =
    getLocalStorage().getItem('ageGateBirthDate') || '';
  const countryCode: string = Cookies.get(`countryCode`) || '';
  // Using this until we get Continent Code from the IPAPI object in the cookies
  const gdprRegion: boolean =
    Intl.DateTimeFormat().resolvedOptions().timeZone.indexOf('Europe') === 0 ||
    false;
  const w: any = window;

  let device = '';
  let browser = '';
  let operatingSystem = '';
  let navigator = w?.navigator;
  let userAgent = navigator?.userAgent;

  if (userAgent) {
    if (userAgent.match(/Android/i)) {
      device = 'Android';
      operatingSystem = 'Android';
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      device = 'iOS';
      operatingSystem = 'iOS';
    } else if (userAgent.match(/Windows/i)) operatingSystem = 'Windows';
    else if (userAgent.match(/Macintosh/i)) operatingSystem = 'Macintosh';
    else if (userAgent.match(/Linux/i)) operatingSystem = 'Linux';
    else operatingSystem = 'Unknown';

    if (userAgent.match(/Chrome/i)) browser = 'Chrome';
    else if (userAgent.match(/Firefox/i)) browser = 'Firefox';
    else if (userAgent.match(/Safari/i)) browser = 'Safari';
    else if (userAgent.match(/Opera/i)) browser = 'Opera';
    else if (userAgent.match(/Edge/i)) browser = 'Edge';
    else if (userAgent.match(/MSIE/i)) browser = 'IE';
    else browser = 'Unknown';
  }

  // Set search params
  w._initSearchParams_ =
    w._initSearchParams_ ||
    Object.fromEntries(
      window.location.search
        .substring(1)
        .split('&')
        .filter((itm) => itm)
        .map((itm) => itm.split('='))
    );

  if (w && w?.dataLayer) {
    w.dataLayer.push({
      event: `brij.${eventName}`,
      deviceId: deviceId,
      sessionId: sessionId,
      timeStamp: new Date(),
      gdprRegion: gdprRegion,
      language: navigator?.language,
      platform: navigator?.platform,
      device: device,
      browser: browser,
      operatingSystem: operatingSystem,
      countryCode: countryCode,
      utm_source: w._initSearchParams_.utm_source,
      utm_medium: w._initSearchParams_.utm_medium,
      utm_campaign: w._initSearchParams_.utm_campaign,
      utm_term: w._initSearchParams_.utm_term,
      utm_content: w._initSearchParams_.utm_content,
      glid: w._initSearchParams_.glid,
      gclid: w._initSearchParams_.gclid,
      dclid: w._initSearchParams_.dclid,
      wctid: w._initSearchParams_.wctid,
      ...(userDateOfBirth && { dateOfBirth: userDateOfBirth }),
      ...params,
    });
  }

  return;
};

export default logGtmEvent;
