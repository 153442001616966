import React, { useState } from 'react';
import { IFileFrom } from 'types/FileType';
import { useTranslation } from 'react-i18next';
import { fetchWrapper } from 'utils/previewMockCalls';
import { ProductDetailsType } from 'types/ProductDetailsType';
import { showToast } from 'components/atomic/Toast/Toast';
import { useGlobal } from 'context/global/GlobalContext';
import { uploadToS3 } from 'utils/uploadToS3';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import DatePicker from 'components/atomic/DatePicker';
import FileUpload from 'components/atomic/FileUpload';
import Button from 'components/atomic/Button';
import envVars from 'env-vars';
import dayjs from 'dayjs';

const getPurchaseTemplate = (
  productDetails: ProductDetailsType | null
): ProductDetailsType['registration']['purchaseTemplate'] | any => {
  const registrationModule = productDetails?.registration;
  if (!registrationModule) return {};
  const { purchaseTemplate, enablePurchaseDetails } = registrationModule;
  return enablePurchaseDetails ? purchaseTemplate : {};
};

const ReceiptUpload: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<IFileFrom[] | undefined>();
  const [receipts, setReceipts] = useState<any[]>([]);
  const { t } = useTranslation('translation', { keyPrefix: 'purchaseDetails' });
  const {
    slug,
    token,
    productDetails,
    getPersonalDetails,
    getCurrentRegistrationId,
  } = useGlobal();
  const { purchaseDate: purchaseDateEnabled } =
    getPurchaseTemplate(productDetails);

  const updateReceipts = async () => {
    if (!selectedFiles?.length) {
      showToast({ message: t('noReceiptError'), type: 'error' });
      return;
    }
    const API_URL =
      envVars.REACT_APP_STAND_ALONE_MODE === 'false'
        ? envVars.REACT_APP_API_URL
        : '';

    try {
      setLoading(true);
      await fetchWrapper(`${API_URL}/app_api/user/update_receipt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          // only send receipts that are not empty
          receipts: receipts.filter((receipt) => !!receipt),
          purchaseDate: purchaseDateEnabled
            ? dayjs(selectedDate).format('YYYY-MM-DD')
            : null,
          tagId: slug,
          registrationId: getCurrentRegistrationId(),
        }),
      });
      await getPersonalDetails();
      showToast({ message: t('receiptUploadSuccess'), type: 'success' });
    } catch (e) {
      showToast({ message: t('receiptUploadError'), type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const changeSelectedFile = async (files: FileList) => {
    if (!files?.length) {
      setSelectedFiles(undefined);
      setFileUploading(false);
      return;
    }
    const uploaded_files = [...files];
    setFileUploading(true);
    let updatedSelectedFiles: IFileFrom[] = selectedFiles || [];
    const uploadFilePromiseArr = [];

    for (let i = 0; i < uploaded_files.length; i++) {
      // only allow images and pdf
      if (
        ![
          'application/pdf',
          'image/png',
          'image/jpg',
          'image/jpeg',
          'image/heif',
          'image/heic',
        ].includes(uploaded_files[i].type)
      ) {
        showToast({ message: t('invalidType'), type: 'error' });
        return;
      }

      let fileObject: IFileFrom = {
        fileName: uploaded_files[i]?.name,
        fileUrl: '',
      };

      uploadFilePromiseArr.push(async () => {
        try {
          const url = await uploadToS3(uploaded_files[i]);
          return {
            ...fileObject,
            fileUrl: url,
          };
        } catch (e) {
          throw e;
        }
      });
    }

    if (uploadFilePromiseArr.length) {
      try {
        const results = await Promise.all(uploadFilePromiseArr.map((p) => p()));
        updatedSelectedFiles = updatedSelectedFiles.concat(results);
      } catch (e) {
        showToast({ message: t('receiptUploadError'), type: 'error' });
        return;
      }
    }
    setSelectedFiles(updatedSelectedFiles);
    setReceipts(updatedSelectedFiles);
    setFileUploading(false);
  };

  if (loading) return <LoadingIndicator />;

  return (
    <div>
      {purchaseDateEnabled && (
        <div className='receipt-upload'>
          <DatePicker
            label={
              selectedDate ? t('datepickerFilledLabel') : t('datepickerLabel')
            }
            selectedDate={selectedDate}
            changeDate={setSelectedDate}
            minDate={new Date('1910-01-01')}
            maxDate={new Date()}
          />
        </div>
      )}
      <div className='mt-3'>
        <FileUpload
          multiple={true}
          loading={fileUploading}
          changeFile={changeSelectedFile}
          setSelectedFiles={setSelectedFiles}
          uploadButtonText={t('uploadReceiptButtonText')}
          selectedFiles={selectedFiles}
          validatePdf
        />
      </div>
      <div className='mt-3'>
        <Button
          type='submit'
          title='Submit'
          variant='dark'
          disabled={
            fileUploading ||
            dayjs().diff(selectedDate, 'days') < 0 ||
            dayjs().diff(selectedDate, 'days').toString() === 'NaN'
          }
          onClick={updateReceipts}
          styles='max-w-full flex-shrink-0'
        />
      </div>
    </div>
  );
};

export default ReceiptUpload;
