import { QuestionType } from './FormDetailsEnum';

export interface ConsentConfig {
  showConsent: boolean;
  consentLabel: string;
  required: boolean;
  checkedByDefault: boolean;
}

export interface AddressConfig {
  line1: boolean;
  line2: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  country: boolean;
}

export interface NameConfig {
  firstName: boolean;
  lastName: boolean;
}

export interface ContentConfig {
  content: string;
}

export interface FileUploadConfig {
  cta: string;
}

export enum FiledValidationType {
  alphanumeric = 'alphanumeric',
  numeric = 'numeric',
  list = 'list',
}

export interface FieldValidation {
  type?: FiledValidationType;
  listId?: string;
  allowSpecialCharacters?: boolean;
  min?: number;
  max?: number;
  uniqueEntry?: boolean;
}

type SortMethod = 'alphabetical' | 'random' | 'date';

export interface SearchableDropDownConfig {
  sortingSchema: SortMethod;
  allowedCustomValue: boolean;
}

export interface FieldConfig {
  fieldValidation?: FieldValidation;
}

export type Config =
  | (ConsentConfig &
      NameConfig &
      AddressConfig &
      SearchableDropDownConfig &
      FieldConfig & {
        cta: string;
        content: string;
      })
  | null;
export interface FormModuleModel {
  _id: string;
  name: string;
  title: string;
  enabled: boolean;
  active: boolean;
  brand: string;
  type: string;
  content: string;
  formDetails: FormDetailModel;
  autoDeploy: boolean;
}

export interface FormDetailOptions {
  text: string;
  isDefault: boolean;
  date?: Date | string;
}

export enum PropertyDestination {
  Registration = 'registration',
  Profile = 'profile',
  Form = 'form',
}

export interface Property {
  _id?: string;
  name: string;
  destination: PropertyDestination[];
  type: string;
  brandId?: string;
  sampleValue: string;
  global: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  subProperties?: Property[];
}

export interface FormDetailModel {
  _id?: string;
  type: QuestionType;
  isRequired: boolean;
  text: string;
  options: FormDetailOptions[];
  subText: string;
  isLongQuestion?: boolean;
  allowedMultiple?: boolean;
  image?: string;
  config?: Config;
  property: Property;
  subProperty?: string;
}
