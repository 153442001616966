import React, { useEffect, useState } from 'react';
import { FormDetailModel, ConsentConfig } from 'types/FormTypes';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import FormImage from './FormImage';
import PhoneInput from 'components/atomic/PhoneInput';
import ConsentCheck from 'components/atomic/ConsentCheck';

type FormPhoneNumberProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any) => void;
};

const FormPhoneNumber: React.FC<FormPhoneNumberProps> = ({
  formData,
  formRef,
  name,
  updateFormField,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [consent, setConsent] = useState<boolean>(false);
  const { consentLabel, showConsent } =
    (formData?.config as ConsentConfig) || {};

  const { t } = useTranslation('translation', { keyPrefix: 'purchaseDetails' });

  useEffect(() => {
    if (formRef?.values[name]) {
      setPhoneNumber(formRef.values[name]?.phoneNumber);
      if (showConsent) setConsent(formRef.values[name]?.consent);
    }
    formRef?.validateForm();
  }, [showConsent]);

  const updatePhoneNumber = (value: string) => {
    setPhoneNumber(value);
    updateFormField(name, { phoneNumber: value, consent });
  };

  const updateConsent = (value: boolean) => {
    setConsent(value);
    updateFormField(name, { phoneNumber, consent: value });
  };

  return (
    <>
      <div className='w-full flex flex-col justify-start overflow-auto'>
        {formData.image && (
          <FormImage image={formData.image} alt='form-question-image' />
        )}
        {formData.text && (
          <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
            {formData.text}
          </h5>
        )}
        {formData.subText && (
          <p className='my-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
            {formData.subText}
          </p>
        )}
        <PhoneInput
          phoneNumber={phoneNumber}
          setPhoneNumber={updatePhoneNumber}
          existingPhoneNumber={phoneNumber}
          placeholder={t('phoneNumberInputPlaceholder')}
          bottomOffset={98}
        />
        {showConsent ? (
          <ConsentCheck
            styles='!mb-0'
            enabled={[true]}
            consentChecks={[consent]}
            toggleConsentChecks={[updateConsent]}
            texts={[consentLabel || '']}
          />
        ) : null}
      </div>
    </>
  );
};

export default FormPhoneNumber;
