import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { getLocalStorage } from 'utils/storage';
import useFirebase from './useFirebase';
import usePersonalDetails from './usePersonalDetails';

const useUser = () => {
  const { getAuth, isSignInWithEmailLink } = useFirebase();

  const [authFetched, setAuthFetched] = useState<boolean>(false);
  const [_user, setUser] = useState<User | null>(null);
  const [
    personalDetails,
    getPersonalDetails,
    token,
    setToken,
    setpersonalDetails,
    getUserAsync,
  ] = usePersonalDetails(_user);

  useEffect(() => {
    const auth = getAuth();
    // listen for auth state changes
    const listener = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (_user) return;
        setUser(user);
      } else {
        // if it's a magic link, wait for the user to sign in
        if (isSignInWithEmailLink(auth, window.location.href)) {
          return;
        }

        setUser(null);
        setAuthFetched(true);
      }
    });

    // unsubscribe on unmount
    return () => {
      listener();
    };
  }, [setAuthFetched, setUser, _user]);

  useEffect(() => {
    if (personalDetails && token) setAuthFetched(true);
  }, [personalDetails, token]);

  useEffect(() => {
    if (token) {
      getLocalStorage().setItem('brij-token', token);
    }
  }, [token]);

  return {
    user: _user,
    setUser,
    personalDetails,
    getPersonalDetails,
    authFetched,
    token,
    setToken,
    setpersonalDetails,
    getUserAsync,
  };
};

export default useUser;
