import { useGlobal } from 'context/global/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

const useRedirectLink = () => {
  const { logEvent } = useGlobal();
  const history = useHistory();

  const redirectToExternalLink = useCallback(
    (
      url?: string | URL,
      target?: string,
      features?: string,
      internal?: boolean
    ) => {
      logEvent({
        eventType: 'ENGAGEMENTS',
        event: 'WEBSITE_VISITS',
        data: {
          url,
        },
      });

      let link: string | URL | undefined = url as string;

      if (internal) {
        history.push(link);
      } else if (link.includes('https://') || link.includes('http://')) {
        window.open(`${url}`, target, features);
      } else window.open(`${'https://'}${url}`, target, features);
    },
    [logEvent]
  );

  return redirectToExternalLink;
};

export default useRedirectLink;
