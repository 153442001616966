import React from 'react';
import { useCartContext } from 'context/CartContext/CartContext';
import { ReactComponent as PlusIcon } from 'assets/icons/svg/plus.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/svg/menu.svg';
import { ReactComponent as CartIcon } from 'assets/icons/svg/cart.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/svg/minus.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/svg/close.svg';
import { ReactComponent as BackIcon } from 'assets/icons/svg/chevron-left.svg';
import { ReactComponent as ClipboardIcon } from 'assets/icons/svg/clipboard.svg';

type IconButtonProps = {
  iconName: 'close' | 'menu' | 'cart' | 'back' | 'plus' | 'minus' | 'clipboard';
  borderStyle?: 'largeCorner' | 'smallCorner' | 'noCorner';
  variant: 'blank' | 'white' | 'themed' | 'outlined';
  onClick?: () => void;
  iconColor?: string;
  disabled?: boolean;
  styles?: string;
  size?: number;
};

const variants = {
  blank: 'bg-transparent',
  white: 'bg-white shadow-md',
  themed: 'bg-primary',
  outlined: 'bg-transparent border border-primary border-solid',
};

const borders = {
  largeCorner: 'rounded-full',
  smallCorner: 'rounded-[10px]',
  noCorner: 'rounded-none',
};

const IconButton: React.FC<IconButtonProps> = ({
  iconName,
  size,
  onClick,
  disabled,
  iconColor,
  styles = '',
  variant = 'themed',
  borderStyle = 'largeCorner',
}) => {
  const { cartProducts } = useCartContext();
  let variantClasses: string = variants[variant];
  let borderClasses: string = borders[borderStyle];

  const getIcon = (iconColor: string, size: number) => {
    switch (iconName) {
      case 'menu':
        return (
          <div>
            <MenuIcon width={size} height={size} fill={iconColor} />
            {cartProducts.length > 0 && (
              <div className='absolute -top-1 -right-1 w-[19px] h-[19px] flex items-center justify-center rounded-full bg-white'>
                <span className='font-semibold text-[9px] leading-[13px] text-black'>
                  {cartProducts.length}
                </span>
              </div>
            )}
          </div>
        );
      case 'cart':
        return (
          <div>
            <CartIcon width={size} height={size} fill={iconColor} />
            {cartProducts.length > 0 && (
              <div className='absolute -top-1 -right-1 w-[19px] h-[19px] flex items-center justify-center rounded-full bg-white'>
                <span className='font-semibold text-[9px] leading-[13px] text-black'>
                  {cartProducts.length}
                </span>
              </div>
            )}
          </div>
        );
      case 'back':
        return <BackIcon width={size} height={size} fill={iconColor} />;
      case 'plus':
        return <PlusIcon width={size} height={size} fill={iconColor} />;
      case 'minus':
        return <MinusIcon width={size} height={size} fill={iconColor} />;
      case 'clipboard':
        return <ClipboardIcon width={size} height={size} fill={iconColor} />;
      default:
        return <CloseIcon width={size} height={size} fill={iconColor} />;
    }
  };

  return (
    <button
      className={`w-max h-max p-3 flex items-center justify-center cursor-pointer disabled:bg-disabledButton duration-150 ${variantClasses} ${borderClasses} ${styles}`}
      disabled={disabled}
      onClick={onClick}
      type='button'
    >
      {getIcon(iconColor || '', size || 28)}
    </button>
  );
};

export default IconButton;
