import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useAPI } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { showToast } from 'components/atomic/Toast/Toast';
import { AB899ModuleType } from 'types/ProductDetailsType';
import { FDA_REGULATIONS_LINK } from 'i18n/globalConstants';
import { ReactComponent as ExpandIcon } from 'assets/icons/svg/expand.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import { TestResultsType, ProductTestedType } from 'types/TestResultsTypes';
import dateDifferenceCounter from 'utils/dateDifferenceCounter';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ToxicElementsTable from './ToxicElementsTable';
import useRedirectLink from 'hooks/useRedirectLink';
import Button from 'components/atomic/Button';
import Input from 'components/atomic/Input';

type AB899DrawerProps = {
  brandId: string;
  drawerTitle: string;
  drawerData: AB899ModuleType;
};

const AB899Drawer: React.FC<AB899DrawerProps> = ({
  brandId = '',
  drawerData,
  drawerTitle,
}) => {
  const [lotNumber, setLotnumber] = useState<string>('');
  const [upcNumber, setUpcNumber] = useState<string>('');
  const [expandedSection, setExpandedSection] = useState<number>(-1);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [testResults, setTestResults] = useState<TestResultsType[]>([]);
  const redirectToExternalLink = useRedirectLink();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.ab899Drawer',
  });

  const {
    preLookup,
    postLookup,
    enableSearchByUpc,
    enableSearchByLotNumber,
    customizeNoMessageResult,
    heavyMetalsResults,
    pesticidesAndGlyphosateResults,
    plasticizersResults,
  } = drawerData;

  const endpoint = `ab899/${brandId}?${
    lotNumber
      ? `lotNumber=${
          drawerData.enableSearchByLotNumber.ignoreSpecialCharacters
            ? lotNumber.replace(/[^a-zA-Z0-9]/g, '')
            : lotNumber
        }&`
      : ''
  }${
    upcNumber
      ? `upcNumber=${
          drawerData.enableSearchByUpc.ignoreSpecialCharacters
            ? upcNumber.replace(/[^a-zA-Z0-9]/g, '')
            : upcNumber
        }`
      : ''
  }`;
  const [fetchLotDetails] = useAPI({
    endpoint,
    method: 'GET',
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!lotNumber && !upcNumber) {
      showToast({
        message: t('errors.emptyLotOrUpcNumber'),
        type: 'error',
      });
    } else {
      setResultsLoading(true);
      try {
        let lotDetails = await fetchLotDetails();
        setTestResults(lotDetails);
        if (lotDetails.length === 1) setExpandedSection(0);
      } catch (error: any) {
        showToast({
          message: customizeNoMessageResult.enabled
            ? customizeNoMessageResult.message
            : t('errors.noRecordFound'),
          type: 'error',
        });
      }
      setResultsLoading(false);
    }
  };

  const legalDisclaimer = (
    <p className='text-xxs px-6 pt-1.5 pb-3 text-justify'>
      {t('disclaimer')}
      <a
        href={FDA_REGULATIONS_LINK}
        target='_blank'
        rel='noreferrer'
        className='text-brij underline'
      >
        {t('disclaimerLink')}
      </a>
      .
    </p>
  );

  return (
    <ModuleWrapper drawerTitle={drawerTitle}>
      <div className='relative w-full flex flex-col items-center justify-center gap-3'>
        <HtmlWrapper html={testResults?.length > 0 ? postLookup : preLookup} />
        {!testResults || testResults.length === 0 ? (
          <section className='relative w-full flex flex-col mt-2.5 pb-3 gap-3 rounded-xl border border-solid border-lightgray overflow-hidden'>
            <div className='relative py-1.5 w-full text-center bg-minimalGray'>
              <h3 className='font-bold uppercase text-lg text-black tracking-[0.5px]'>
                {t('testResultsLookup')}
              </h3>
            </div>
            {(!testResults || testResults?.length === 0) && (
              <div className='relative flex flex-col px-3 gap-3'>
                <p className='px-1.5 py-2 font-medium text-sm text-black tracking-[0.3px]'>
                  {enableSearchByUpc.enabled && enableSearchByLotNumber.enabled
                    ? t('enterUpcAndLotNumber')
                    : enableSearchByUpc.enabled
                    ? t('enterUpcNumber')
                    : t('enterLotNumber')}
                </p>
                <form
                  onSubmit={handleSubmit}
                  className='relative flex flex-col gap-3'
                >
                  {enableSearchByUpc.enabled && (
                    <Input
                      value={upcNumber}
                      name='upc-number-input'
                      autoComplete='upc-number-input'
                      required={enableSearchByUpc.required || false}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setUpcNumber(event.target.value)
                      }
                      placeholder={
                        upcNumber
                          ? t('upcNumberInputPlaceholder')
                          : t('upcNumberEmptyInputPlaceholder')
                      }
                    />
                  )}
                  {enableSearchByLotNumber.enabled && (
                    <Input
                      value={lotNumber}
                      name='lot-number-input'
                      autoComplete='lot-number-input'
                      required={enableSearchByLotNumber.required || false}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setLotnumber(event.target.value)
                      }
                      placeholder={
                        lotNumber
                          ? t('lotNumberInputPlaceholder')
                          : `${t('lotNumberEmptyInputPlaceholder')}`
                      }
                    />
                  )}
                  <Button
                    type='submit'
                    variant='dark'
                    title={t('submitLotNumberButton')}
                    disabled={!lotNumber && !upcNumber}
                    loading={resultsLoading}
                  />
                </form>
              </div>
            )}
            {legalDisclaimer}
          </section>
        ) : (
          <div className='relative w-full flex flex-col items-center justify-start gap-3'>
            {testResults.length > 0 &&
              testResults?.map((result: TestResultsType, index: number) =>
                result?.expirationDate &&
                drawerData?.heavyMetalsResults.hidePostExpirationResults &&
                drawerData.pesticidesAndGlyphosateResults
                  .hidePostExpirationResults &&
                drawerData.plasticizersResults.hidePostExpirationResults &&
                dateDifferenceCounter(result?.expirationDate) > 31 ? null : (
                  <section
                    key={result._id}
                    className='relative w-full flex flex-col items-center justify-star rounded-xl border border-solid border-lightgray overflow-hidden'
                  >
                    <button
                      type='button'
                      onClick={() =>
                        expandedSection === index
                          ? setExpandedSection(-1)
                          : setExpandedSection(index)
                      }
                      className='relative w-full flex flex-row items-center justify-between px-4 py-2 bg-minimalGray'
                    >
                      <div className='w-full flex flex-col items-start'>
                        <p className='font-medium text-xs text-muted text-left text-wrap text-ellipsis whitespace-nowrap overflow-hidden'>
                          {result.productsTested
                            ?.map((product: ProductTestedType) => product.label)
                            .join(', ')}
                        </p>
                        <h3 className='font-bold text-left'>
                          {t('lotNumberHeading')}: {result.lotNo}
                        </h3>
                      </div>
                      <ExpandIcon
                        className={`min-w-[18px] min-h-[18px] transform duration-150 ${
                          index === expandedSection ? 'rotate-180' : 'rotate-0'
                        }`}
                        fill='#000000'
                        height={18}
                        width={18}
                      />
                    </button>
                    {expandedSection === index && (
                      <div className='relative w-full flex flex-col items-center justify-start p-3 gap-3'>
                        {result?.heavyMetals &&
                          heavyMetalsResults.enabled &&
                          result?.heavyMetals?.length > 0 && (
                            <ToxicElementsTable
                              startExpanded
                              key='heavy-metals-table'
                              title={t('heavyMetalsHeading')}
                              toxicElements={result.heavyMetals}
                              toxicElementsResults={heavyMetalsResults}
                              expirationDate={result.expirationDate || null}
                              testingDate={result.testingDate || null}
                            />
                          )}
                        {result?.pesticidesAndGlyphosateElements &&
                          drawerData.pesticidesAndGlyphosateResults?.enabled &&
                          result?.pesticidesAndGlyphosateElements?.length >
                            0 && (
                            <ToxicElementsTable
                              startExpanded
                              key='pesticides-and-glyphosate-table'
                              title={t('pesticidesAndGlyphosateHeading')}
                              toxicElements={
                                result.pesticidesAndGlyphosateElements
                              }
                              toxicElementsResults={
                                drawerData.pesticidesAndGlyphosateResults
                              }
                              expirationDate={result.expirationDate || null}
                              testingDate={result.testingDate || null}
                            />
                          )}
                        {result.plasticizersElements &&
                          drawerData.plasticizersResults?.enabled &&
                          result.plasticizersElements?.length > 0 && (
                            <ToxicElementsTable
                              startExpanded
                              key='plasticizers-table'
                              title={t('plasticizersHeading')}
                              toxicElements={result.plasticizersElements}
                              toxicElementsResults={
                                drawerData.plasticizersResults
                              }
                              expirationDate={result.expirationDate || null}
                              testingDate={result.testingDate || null}
                            />
                          )}
                        {result?.testResultsFile &&
                        (drawerData?.heavyMetalsResults
                          .includeLinkToTestResultsDocument.enabled ||
                          drawerData?.pesticidesAndGlyphosateResults
                            .includeLinkToTestResultsDocument.enabled ||
                          drawerData?.plasticizersResults
                            .includeLinkToTestResultsDocument.enabled) ? (
                          <Button
                            type='button'
                            variant='link'
                            styles='!h-[18px] mb-1 mt-1.5'
                            textStyles='!text-black !text-xs !font-medium !underline !tracking-[0.1px]'
                            title={
                              heavyMetalsResults
                                .includeLinkToTestResultsDocument.linkText ||
                              pesticidesAndGlyphosateResults
                                .includeLinkToTestResultsDocument.linkText ||
                              plasticizersResults
                                .includeLinkToTestResultsDocument.linkText ||
                              t('viewFullReportButton')
                            }
                            icon={
                              <ExternalLink
                                width={12}
                                height={12}
                                fill='#000000'
                                className='ml-0.5 mt-px'
                              />
                            }
                            onClick={() =>
                              redirectToExternalLink(
                                result.testResultsFile,
                                '_blank'
                              )
                            }
                          />
                        ) : null}
                      </div>
                    )}
                  </section>
                )
              )}
            {testResults?.length > 0 && (
              <div className='relative w-full'>
                <Button
                  type='button'
                  variant='dark'
                  title={t('startOverButton')}
                  onClick={() => {
                    setUpcNumber('');
                    setLotnumber('');
                    setTestResults([]);
                    setExpandedSection(-1);
                  }}
                />
              </div>
            )}
            <p className='text-xxs px-3 text-justify'>
              {t('disclaimer')}
              <a
                href={FDA_REGULATIONS_LINK}
                target='_blank'
                rel='noreferrer'
                className='text-brij underline'
              >
                {t('disclaimerLink')}
              </a>
              .
            </p>
          </div>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default AB899Drawer;
