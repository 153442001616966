import React, { useState, useEffect } from 'react';
import { useGlobal } from 'context/global/GlobalContext';
import { VideoModuleType } from 'types/ProductDetailsType';
import { extractYoutubeVideoId } from 'utils/extractYoutubeVideoId';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import envVars from 'env-vars';

type VideoDrawerProps = {
  drawerTitle: string;
  closeDrawer: () => void;
  drawerData?: VideoModuleType;
};

const VideoDrawer: React.FC<VideoDrawerProps> = ({
  drawerData,
  drawerTitle,
  closeDrawer,
}) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] =
    useState<boolean>(true);
  const [showControls, toggleControls] = useState<boolean>(false);
  const { desktopView } = useGlobal();

  useEffect(() => {
    if (!desktopView) closeDrawer();
  }, [closeDrawer, desktopView]);

  let optimizedVideoLink: string = drawerData?.path || '';
  if (!drawerData?.youtubeEmbed && drawerData?.path) {
    const hostUrl: string = envVars.REACT_APP_HOST_URL_IMAGE || '';
    const fileId: string | undefined = drawerData?.path
      ?.split('?')
      ?.shift()
      ?.split('/')
      ?.pop()
      ?.split('.')
      ?.shift();
    optimizedVideoLink = `${hostUrl}/optimized-videos/${fileId}/video-vp9-720p.webm`;
  }

  return desktopView ? (
    <ModuleWrapper drawerTitle={drawerTitle}>
      {drawerData?.youtubeEmbed ? (
        <div
          className={`relative w-full flex items-start pt-3 aspect-video ${
            drawerData.path.includes('/shorts/') ? 'h-full max-h-full' : ''
          }`}
        >
          <iframe
            width='100%'
            height='100%'
            allowFullScreen
            title='YouTube Embed'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            src={`https://www.youtube.com/embed/${extractYoutubeVideoId(
              drawerData.path
            )}`}
          />
        </div>
      ) : (
        <div className='relative w-full h-full flex items-start pt-3 aspect-video'>
          <video
            width='100%'
            height='100%'
            controls={showControls}
            className='max-w-full max-h-full'
            onMouseEnter={() => toggleControls(true)}
            onError={() => setRenderOptimizedMedia(false)}
            src={renderOptimizedMedia ? optimizedVideoLink : drawerData?.path}
          />
        </div>
      )}
    </ModuleWrapper>
  ) : (
    <div className='w-full h-full flex' />
  );
};

export default VideoDrawer;
