const w: any = window;

const envVars = {
  PUBLIC_URL: w.ENV_PUBLIC_URL || process.env.PUBLIC_URL,
  REACT_APP_RECAPTCHA_KEY:
    w.ENV_REACT_APP_RECAPTCHA_KEY || process.env.REACT_APP_RECAPTCHA_KEY,
  REACT_APP_STAND_ALONE_MODE:
    w.ENV_REACT_APP_STAND_ALONE_MODE || process.env.REACT_APP_STAND_ALONE_MODE,
  REACT_APP_API_URL: w.ENV_REACT_APP_API_URL || process.env.REACT_APP_API_URL,
  REACT_APP_RECAPTCHA_KEY_v2:
    w.ENV_REACT_APP_RECAPTCHA_KEY_v2 || process.env.REACT_APP_RECAPTCHA_KEY_v2,
  REACT_APP_NODE_ENV:
    w.ENV_REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV,
  REACT_APP_HOST_URL_IMAGE:
    w.ENV_REACT_APP_HOST_URL_IMAGE || process.env.REACT_APP_HOST_URL_IMAGE,
  PUPPETEER_EXEC_PATH:
    w.ENV_PUPPETEER_EXEC_PATH || process.env.PUPPETEER_EXEC_PATH,
  CI: w.ENV_CI || process.env.CI,
  SMS_OPTIN_APIKEY: w.ENV_SMS_OPTIN_APIKEY || process.env.SMS_OPTIN_APIKEY,
};

export default envVars;
