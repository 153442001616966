import { useEffect, Suspense } from 'react';
import { getLocalStorage } from 'utils/storage';
import { BrowserRouter } from 'react-router-dom';
import { useGlobal } from './context/global/GlobalContext';
import { LastLocationProvider } from 'react-router-last-location';
import { useAPICacheContext } from 'context/APICacheContext/APICacheContext';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import ThemedGlobalStyle from 'components/ThemedGlobalStyle';
import SuccessDrawer from 'components/drawers/SuccessDrawer';
import AppContainer from 'components/layout/AppContainer';
import CartDrawer from 'components/drawers/CartDrawer';
import Toast from 'components/atomic/Toast';
import GlobalStyle from 'styles/global';
import envVars from './env-vars';
import Routes from './routes';
import 'assets/main.css';
import PreviewHandler from 'components/PreviewHandler/PreviewHandler';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function BrijConsumerApp() {
  const { appZoom } = useGlobal();
  const { invalidateCache } = useAPICacheContext();
  const { globalLoading } = useGlobal();

  useEffect(() => {
    const previewModeListener = (event: MessageEvent) => {
      if (event?.data?.type === 'enablePreview') {
        (window as any).mode = 'preview';
        getLocalStorage().clear();
      }
    };

    window.addEventListener(
      'message', // listen for messages from the parent
      previewModeListener
    );

    return () => {
      (window as any).mode = '';
      window.removeEventListener('message', previewModeListener);
    };
  }, []);

  useEffect(() => {
    const productId = getLocalStorage().getItem('currentProductModuleId');
    if (!productId) invalidateCache();
  }, []);

  return (
    <BrowserRouter basename={envVars.PUBLIC_URL}>
      {globalLoading && (
        <div className='w-full h-full flex fixed z-50 bg-white opacity-50'>
          <LoadingIndicator />
        </div>
      )}
      <PreviewHandler />
      <AppContainer zoom={appZoom}>
        <GlobalStyle />
        <ThemedGlobalStyle />
        <GoogleReCaptchaProvider reCaptchaKey={envVars.REACT_APP_RECAPTCHA_KEY}>
          <LastLocationProvider>
            <SuccessDrawer />
            <CartDrawer />
            <Suspense
              fallback={
                <div className='w-full h-full'>
                  <LoadingIndicator />
                </div>
              }
            >
              <Routes />
            </Suspense>
          </LastLocationProvider>
        </GoogleReCaptchaProvider>
        <Toast />
      </AppContainer>
    </BrowserRouter>
  );
}
