import React from 'react';
import { theme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import { SweepstakesModuleType } from 'types/ProductDetailsType';
import { ReactComponent as Warning } from 'assets/icons/svg/warning.svg';
import { ReactComponent as Checkmark } from 'assets/icons/svg/checkmark.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/svg/pending.svg';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import ReceiptUpload from 'components/forms/ReceiptUpload';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import dateFormat from 'dateformat';

type SweepstakesDrawerProps = {
  drawerTitle: string;
  drawerData: SweepstakesModuleType;
};

export enum ApprovalStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  DENIED = 'denied',
  EXPIRED = 'expired',
}

const getTextClassName = (sweepstakesStatus: ApprovalStatus): string => {
  switch (sweepstakesStatus) {
    case ApprovalStatus.APPROVED:
      return 'text-brij';
    case ApprovalStatus.PENDING:
      return 'text-caution';
    case ApprovalStatus.DENIED:
      return 'text-warning';
    case ApprovalStatus.EXPIRED:
      return 'text-warning';
    default:
      return 'text-brij';
  }
};

const getClassName = (sweepstakesStatus: ApprovalStatus): string => {
  switch (sweepstakesStatus) {
    case ApprovalStatus.APPROVED:
      return 'bg-validWarranty';
    case ApprovalStatus.PENDING:
      return 'bg-pendingWarranty';
    case ApprovalStatus.DENIED:
      return 'bg-expiredWarranty';
    case ApprovalStatus.EXPIRED:
      return 'bg-expiredWarranty';
    default:
      return 'bg-validWarranty';
  }
};

const getIcon = (sweepstakesStatus: ApprovalStatus) => {
  switch (sweepstakesStatus) {
    case ApprovalStatus.APPROVED:
      return <Checkmark width='15px' height='15px' fill={theme.primary} />;
    case ApprovalStatus.PENDING:
      return <PendingIcon width='15px' height='15px' fill='#FE931E' />;
    case ApprovalStatus.DENIED:
      return <Warning width='15px' height='15px' fill='#FD6157' />;
    case ApprovalStatus.EXPIRED:
      return <Warning width='15px' height='15px' fill='#FD6157' />;
    default:
      return <Checkmark width='15px' height='15px' fill={theme.primary} />;
  }
};

const getCustomStatusMessage = (
  sweepstakesStatus: ApprovalStatus,
  drawerData: SweepstakesModuleType
) => {
  switch (sweepstakesStatus) {
    case ApprovalStatus.APPROVED:
      return drawerData.entryActiveMessage;
    case ApprovalStatus.PENDING:
      return drawerData.entryPendingReviewMessage;
    case ApprovalStatus.DENIED:
      return drawerData.entryDeniedMessage;
    case ApprovalStatus.EXPIRED:
      return drawerData.sweepstakesExpiredMessage;
    default:
      return drawerData.entryActiveMessage;
  }
};

const validateDate = (date: Date) =>
  new Date().setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0);

const isExpired = (expirationDate?: Date | string) => {
  if (!expirationDate) return false;
  if (expirationDate === 'LIFETIME') return false;
  return validateDate(new Date(dateFormat(expirationDate, 'mmm d, yyyy')));
};

const SweepstakesDrawer: React.FC<SweepstakesDrawerProps> = ({
  drawerTitle,
  drawerData,
}) => {
  const { personalDetails, slug, getCurrentRegistrationId } = useGlobal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.sweepstakesDrawer',
  });

  const registrationItem = personalDetails?.profile.brandRegistrations?.find(
    (item) => item.tagId === slug
  );
  const productCollection = personalDetails?.profile?.productCollection;
  const registrationId = getCurrentRegistrationId();

  const currentProductItem = productCollection
    ?.filter((item) => !item.incomplete)
    .find(
      // TODO: remove this when we have a proper way to get the preview mode
      (item) => {
        const matched = item?.tagId === slug;
        if (registrationId)
          return matched && item?.registrationId === registrationId;
        return matched;
      }
    );

  let sweepstakesStatus =
    (currentProductItem?.approvalStatus as ApprovalStatus) ||
    (drawerData.sweepstakesStatus as ApprovalStatus) ||
    ApprovalStatus.APPROVED;

  sweepstakesStatus = isExpired(drawerData?.sweepstakesEndDate)
    ? ApprovalStatus.EXPIRED
    : sweepstakesStatus;

  const getStatusTitle = (sweepstakesStatus: ApprovalStatus) => {
    let title = '';
    switch (sweepstakesStatus) {
      case ApprovalStatus.APPROVED:
        title = t('sweepstakesEntryStatus.entryApproved');
        break;
      case ApprovalStatus.PENDING:
        title = t('sweepstakesEntryStatus.entryPending');
        break;
      case ApprovalStatus.DENIED:
        title = t('sweepstakesEntryStatus.entryDenied');
        break;
      case ApprovalStatus.EXPIRED:
        title = t('sweepstakesEntryStatus.entryExpired');
        break;
      default:
        title = t('sweepstakesEntryStatus.entryApproved');
        break;
    }
    return title;
  };

  const getDefaultStatusMessage = (sweepstakesStatus: ApprovalStatus) => {
    switch (sweepstakesStatus) {
      case ApprovalStatus.APPROVED:
        return t('sweepstakesEntryStatusMessage.entryActiveMessage');
      case ApprovalStatus.PENDING:
        return t('sweepstakesEntryStatusMessage.entryPendingReviewMessage');
      case ApprovalStatus.DENIED:
        return t('sweepstakesEntryStatusMessage.entryDeniedMessage');
      case ApprovalStatus.EXPIRED:
        return t('sweepstakesEntryStatusMessage.sweepstakesExpiredMessage');
      default:
        return t('sweepstakesEntryStatusMessage.entryActiveMessage');
    }
  };

  return (
    <ModuleWrapper
      drawerTitle={
        drawerData.customizePostRegistrationCta &&
        drawerData.registrationId &&
        drawerData.postRegistrationCta
          ? drawerData.postRegistrationCta
          : drawerTitle
      }
    >
      {drawerData.content && (
        <HtmlWrapper html={drawerData.content} styles='!p-3 !mb-5' />
      )}
      {drawerData.showSweepstakesEntryStatus && (
        <div className='relative flex flex-col p-3 gap-3 rounded-lg border-2 border-solid border-gray-100'>
          <div
            className={`w-full flex items-center justify-center p-3 rounded ${getClassName(
              sweepstakesStatus as ApprovalStatus
            )}`}
          >
            {getIcon(sweepstakesStatus as ApprovalStatus)}
            <p
              className={`px-2 w-max text-sm text-center font-semibold ${getTextClassName(
                sweepstakesStatus as ApprovalStatus
              )}`}
            >
              {getStatusTitle(sweepstakesStatus as ApprovalStatus)}
            </p>
          </div>
          <div>
            <p
              className={`px-2.5 font-medium text-xs text-left ${getTextClassName(
                sweepstakesStatus as ApprovalStatus
              )}`}
            >
              {drawerData.customizeSweepstakesStateMessages
                ? getCustomStatusMessage(
                    sweepstakesStatus as ApprovalStatus,
                    drawerData
                  )
                : getDefaultStatusMessage(sweepstakesStatus as ApprovalStatus)}
            </p>
          </div>
          {sweepstakesStatus !== ApprovalStatus.DENIED ? (
            <div className='relative w-full flex flex-row gap-1.5 items-start'>
              <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
                <p className='text-sm text-center font-medium text-muted'>
                  {t('entryDateHeading')}
                </p>
                {registrationItem?.registrationDate && (
                  <p className='font-semibold text-sm text-center text-dark'>
                    {dateFormat(
                      registrationItem?.registrationDate,
                      'mmm d, yyyy'
                    )}
                  </p>
                )}
              </div>
              <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
                <p className='text-sm text-center font-medium text-muted'>
                  {t('entryIdHeading')}
                </p>
                <p className='font-semibold text-sm text-center text-dark'>
                  {drawerData.registrationId}
                </p>
              </div>
            </div>
          ) : (
            <ReceiptUpload />
          )}
        </div>
      )}
    </ModuleWrapper>
  );
};

export default SweepstakesDrawer;
