import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { FormDetailModel } from 'types/FormTypes';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import StarRating from 'components/atomic/StarRating';
import FormImage from './FormImage';

type FormUserReviewProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any) => void;
};

const FormUserReview: React.FC<FormUserReviewProps> = ({
  formData,
  formRef,
  name,
  updateFormField,
}) => {
  const [rating, setRating] = useState<number>(0);

  useEffect(() => {
    if (formRef?.values[name]) {
      setRating(formRef?.values[name] || 0);
    }

    formRef?.validateForm();
  }, []);

  const updateRating = (value: number) => {
    setRating(value);
    updateFormField(name, value);
  };

  return formRef ? (
    <div className='relative w-full flex flex-col '>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      {formData.text && (
        <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
          {formData.text}
        </h5>
      )}
      {formData.subText && (
        <p className='mt-3 mb-1.5 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <StarRating rating={rating} setRating={updateRating} styles='!my-3' />
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default FormUserReview;
