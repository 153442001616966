import React, { useEffect } from 'react';
import { useFormContext } from 'context/FormDrawerContext/FormDrawerContext';
import { useRouteMatch } from 'react-router-dom';
import { FormMatchParams } from '../FormDrawer';

const FromStepWrapper: React.FC = ({ children }) => {
  const { setCurrentStep } = useFormContext();
  const { params } = useRouteMatch<FormMatchParams>();

  useEffect(() => {
    if (params.stepId) {
      setCurrentStep(parseInt(params.stepId, 10));
    }
  }, []);

  return <div className='flex w-full h-full pt-5'>{children}</div>;
};

export default FromStepWrapper;
