import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ExpandIcon } from 'assets/icons/svg/expand.svg';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { CountryCodeType, countryPhoneCodes } from 'types/Misc';
import useElementSize from 'hooks/useElementSize';
import useWindowSize from 'hooks/useWindowSize';
import InputMask from 'react-input-mask';
import Dropdown from './Dropdown';
import Cookies from 'js-cookie';

type SearchBarProps = {
  phoneNumber: string | undefined;
  setPhoneNumber: (phoneNumber: string) => void;
  existingPhoneNumber?: string;
  bottomOffset: number;
  placeholder: string;
  disabled?: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({
  bottomOffset,
  placeholder,
  disabled,
  phoneNumber,
  setPhoneNumber,
  existingPhoneNumber,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [bottomSpace, setBottomSpace] = useState<number | null>(null);
  const [dropdownSafeArea, setDropdownSafeArea] = useState<number>(0);
  const [openedInverted, setOpenedInverted] = useState<string>('bottom');
  const [borderRadius, setBorderRadius] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<CountryCodeType>(
    countryPhoneCodes[0]
  );
  const { inputStyle } = useThemeContext();
  const [dropdownRef, { height }] = useElementSize();
  const contentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (existingPhoneNumber) {
      const number = existingPhoneNumber?.slice(-10);
      const code = existingPhoneNumber.slice(0, -10);
      const index = countryPhoneCodes.findIndex(
        (country) => country.mask === `(${code})`
      );
      if (index !== -1) {
        setCountryCode(countryPhoneCodes[index]);
        setPhoneNumber(
          `(${code}) ${number.slice(0, 3)} ${number.slice(3, 6)} ${number.slice(
            6,
            10
          )}`
        );
      }
    } else if (!existingPhoneNumber) {
      const countryCode: string = Cookies.get(`countryCode`) || '';
      if (countryCode) {
        const index = countryPhoneCodes.findIndex(
          (country) => country.countryCode === countryCode
        );
        if (index !== -1) setCountryCode(countryPhoneCodes[index]);
      }
    }
  }, [existingPhoneNumber, setPhoneNumber]);

  useEffect(() => {
    if (bottomSpace) {
      if (height >= 82) setDropdownSafeArea(height);
      else setDropdownSafeArea(186);
    }
  }, [height, bottomSpace]);

  useEffect(() => {
    if (phoneNumber) setIsFocused(true);
    else setIsFocused(false);
  }, [phoneNumber]);

  useEffect(() => {
    const scrollHandler = () => {
      if (contentRef.current && windowSize.height) {
        setBottomSpace(
          windowSize.height -
            contentRef.current.getBoundingClientRect().y -
            bottomOffset -
            58
        );
      }
    };
    if (bottomSpace) window.addEventListener('scroll', scrollHandler, true);
    else window.addEventListener('scroll', scrollHandler, true);

    return () => window.removeEventListener('scroll', scrollHandler, true);
  }, [bottomSpace, height]);

  useEffect(() => {
    setTimeout(() => {
      if (windowSize.height && contentRef.current) {
        setBottomSpace(
          windowSize.height -
            contentRef.current.getBoundingClientRect().y -
            bottomOffset -
            58
        );
      }
    }, 500);
  }, [windowSize, contentRef, height]);

  useEffect(() => {
    if (bottomSpace) {
      if (dropdownOpen)
        setOpenedInverted(bottomSpace > dropdownSafeArea ? 'bottom' : 'top');
      else
        setOpenedInverted(
          openedInverted === 'bottom'
            ? bottomSpace < dropdownSafeArea
              ? 'top'
              : 'bottom'
            : bottomSpace > dropdownSafeArea
            ? 'bottom'
            : 'top'
        );
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (inputStyle === CornerStyle.SQUARE_CORNERS) setBorderRadius('0rem');
    else if (inputStyle === CornerStyle.ROUNDED_CORNERS)
      setBorderRadius('0.625rem');
    else setBorderRadius('1.625rem');
  }, [inputStyle]);

  const focusInput = () => inputRef.current && inputRef.current.focus();
  const blurInput = () => inputRef.current && inputRef.current.blur();

  const getDropdownStyle = (openedInverted: string) => {
    let dropdownStyle =
      openedInverted === 'bottom'
        ? {
            borderTopLeftRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomLeftRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            borderTopRightRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomRightRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            top:
              bottomSpace && bottomSpace > dropdownSafeArea ? '57px' : `-180px`,
          }
        : {
            borderTopLeftRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomLeftRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            borderTopRightRadius:
              bottomSpace && bottomSpace < dropdownSafeArea
                ? borderRadius
                : '0',
            borderBottomRightRadius:
              bottomSpace && bottomSpace > dropdownSafeArea
                ? borderRadius
                : '0',
            bottom:
              bottomSpace && bottomSpace > dropdownSafeArea ? `-180px` : '58px',
          };
    return dropdownStyle;
  };

  return (
    <>
      {dropdownOpen && (
        <div
          className='z-40 fixed top-0 left-0 w-full h-full'
          onClick={() => setDropdownOpen(false)}
        />
      )}
      <div ref={contentRef} className='z-auto relative w-full'>
        <InputMask
          required
          value={phoneNumber}
          mask={
            countryCode.mask.includes('99')
              ? `${countryCode?.mask.replace('99', '\\9\\9')} 999 999 9999`
              : `${countryCode?.mask.replace('9', '\\9')} 999 999 9999`
          }
          onChange={(event) => setPhoneNumber(event.target.value)}
        >
          {() => (
            <div className='w-full'>
              <input
                type='tel'
                ref={inputRef}
                disabled={disabled}
                name='phone-number-input'
                onFocus={() => {
                  setDropdownOpen(false);
                  setIsFocused(true);
                }}
                style={{
                  ...{ border: '2px solid #e7eaeb' },
                  ...{
                    borderRadius: borderRadius,
                    borderTopLeftRadius: dropdownOpen
                      ? bottomSpace && bottomSpace < dropdownSafeArea
                        ? '0'
                        : borderRadius
                      : borderRadius,
                    borderTopRightRadius: dropdownOpen
                      ? bottomSpace && bottomSpace < dropdownSafeArea
                        ? '0'
                        : borderRadius
                      : borderRadius,
                    borderBottomLeftRadius: dropdownOpen
                      ? bottomSpace && bottomSpace < dropdownSafeArea
                        ? borderRadius
                        : '0'
                      : borderRadius,
                    borderBottomRightRadius: dropdownOpen
                      ? bottomSpace && bottomSpace < dropdownSafeArea
                        ? borderRadius
                        : '0'
                      : borderRadius,
                  },
                }}
                className={`w-full h-searchBar pl-[72px] pr-6 my-1.5 tracking-[0.1px] duration-300 placeholder:opacity-70 
                ${
                  dropdownOpen || phoneNumber
                    ? 'bg-transparent'
                    : 'bg-lightgray'
                }`}
              />
              <span
                className={`absolute z-auto left-[76px] top-[21px] w-max max-w-[calc(100%_-_100px)] text-muted transition-all duration-300 pointer-events-none whitespace-nowrap text-ellipsis overflow-hidden 
                ${dropdownOpen ? 'delay-100' : 'delay-0'}
                ${
                  isFocused
                    ? 'text-xxxs px-0.5 bg-white -translate-x-14 -translate-y-5 opacity-100'
                    : 'text-[0.9rem] bg-transparent translate-x-0 translate-y-0 opacity-80'
                }
              `}
              >
                {placeholder}
              </span>
            </div>
          )}
        </InputMask>
        <div
          ref={dropdownRef}
          style={{
            ...{
              height: dropdownOpen ? `186px` : '0px',
              background: '#FFFFFF',
            },
            ...getDropdownStyle(openedInverted),
          }}
          className={`z-50 absolute duration-300 shadow-dropdown 
          ${
            dropdownOpen
              ? 'w-[calc(100%_-_0.125rem)] ml-px left-0 overflow-auto'
              : 'w-[calc(100%_-_3.25rem)] left-[1.625rem] overflow-hidden'
          }`}
        >
          <Dropdown
            options={countryPhoneCodes}
            borderRadius={borderRadius}
            onSelect={(countryCode: CountryCodeType) => {
              setPhoneNumber('');
              setCountryCode(countryCode);
              setDropdownOpen(false);
              setIsFocused(true);
              setTimeout(() => focusInput(), 300);
            }}
            invertBorders={
              bottomSpace && bottomSpace < dropdownSafeArea ? true : false
            }
          />
        </div>
        {bottomSpace && (
          <div
            className={`absolute top-0 left-6 bottom-0 h-10 flex flex-row items-center m-auto cursor-pointer overflow-hidden
              ${
                disabled
                  ? 'pointer-events-none opacity-50'
                  : 'pointer-events-auto opacity-100'
              }
            `}
            onClick={() => {
              dropdownOpen && blurInput();
              setDropdownOpen(!dropdownOpen);
            }}
          >
            <label className='text-2xl cursor-pointer'>
              {countryCode.flag}
            </label>
            <ExpandIcon
              fill='#000000'
              className='duration-500 opacity-70'
              style={
                bottomSpace
                  ? {
                      transform: dropdownOpen
                        ? bottomSpace > dropdownSafeArea
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)'
                        : bottomSpace > dropdownSafeArea
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                    }
                  : {}
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
