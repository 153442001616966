import { useGlobal } from 'context/global/GlobalContext';
import { FC, useEffect } from 'react';

interface Props {
  setMainDrawerOpen: (open: boolean) => void;
  onClose: () => void;
}

const CloseAutoDeployDrawer: FC<Props> = ({ setMainDrawerOpen, onClose }) => {
  const { setOpenAutoDeployModal } = useGlobal();

  useEffect(() => {
    setOpenAutoDeployModal(false);
    setMainDrawerOpen(true);
    onClose();
  }, []);

  return null;
};

export default CloseAutoDeployDrawer;
