import React from 'react';
import Lottie from 'react-lottie';
import rotationAnimationData from 'assets/lottie-animations/device-rotate-animation.json';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const RotationScreen: React.FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'rotationScreen',
  });

  return (
    <div
      className={`flex flex-col items-center px-6 -mt-2 ${
        isMobile ? 'landscape:block portrait:hidden' : 'hidden'
      }`}
    >
      <p className='w-3/4 text-lg mx-auto mb-4 text-center font-medium'>
        {t('message')}
      </p>
      <Lottie
        speed={1}
        width={80}
        height={80}
        options={{
          loop: true,
          autoplay: true,
          animationData: rotationAnimationData,
        }}
      />
    </div>
  );
};

export default RotationScreen;
