import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  ModuleInfoType,
  ProductDetailsType,
  ReturnedCollectionType,
} from '../../types/ProductDetailsType';
import ScreenType from 'types/ScreenType';
import { TermsAndPrivacyType } from 'types/TermsAndPrivacyType';
import { EventPayload } from '../../hooks/useLogEvent';
import { GameRulesType } from 'types/GameRulesType';
import { MAGIC_ACTION } from './GlobalProvider';
import { ReceiptType } from 'types/ReceiptType';
import { UserStruct } from '../../types/User';
import { User } from 'firebase/auth';

export type UserLocationType = {
  latitude: number;
  longitude: number;
};

export type GlobalContextProps = {
  isMenuOpen: boolean;
  appZoom: number;
  setAppZoom: Dispatch<SetStateAction<number>>;
  previewEvent: MessageEvent | null;
  setPreviewEvent: Dispatch<SetStateAction<MessageEvent | null>>;
  previewAuthenticated: boolean;
  setPreviewAuthenticated: Dispatch<SetStateAction<boolean>>;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  signInRedirect: string;
  setSignInRedirect: Dispatch<SetStateAction<string>>;
  user: User | null;
  personalDetails: UserStruct | null;
  setpersonalDetails: Dispatch<SetStateAction<UserStruct | null>>;
  productDetails: ProductDetailsType | null;
  collectionDetails: ReturnedCollectionType[];
  reFetchProduct: (data?: any, latestToken?: string) => void;
  getUserAsync: (data?: any, latestToken?: string) => void;
  loading: boolean;
  slug: string | null;
  setSlug: Dispatch<SetStateAction<string | null>>;
  setUser: Dispatch<SetStateAction<User | null>>;
  getCollection: () => void;
  authFetched: boolean;
  getPersonalDetails: () => void;
  token: string | null;
  logEvent: (
    payload: Pick<
      EventPayload,
      | 'event'
      | 'eventType'
      | 'moduleType'
      | 'moduleId'
      | 'data'
      | 'date'
      | 'registrationId'
    >
  ) => Promise<any> | undefined;
  magicAction: MAGIC_ACTION;
  setMagicAction: Dispatch<SetStateAction<MAGIC_ACTION>>;
  magicPayload: any;
  setMagicPayload: Dispatch<SetStateAction<any>>;
  agegateDisplay: boolean;
  toggleAgegateDisplay: Dispatch<SetStateAction<boolean>>;
  contentGateDisplay: boolean;
  toggleContentGateDisplay: Dispatch<SetStateAction<boolean>>;
  autoDeployTriggered: boolean;
  setAutoDeployTriggered: Dispatch<SetStateAction<boolean>>;
  productModule: string;
  setProductModule: Dispatch<SetStateAction<string>>;
  setProductDetails: Dispatch<SetStateAction<ProductDetailsType | null>>;
  termsAndPrivacyDetails: TermsAndPrivacyType | null;
  setTermsAndPrivacyDetails: Dispatch<
    SetStateAction<TermsAndPrivacyType | null>
  >;
  termsLoading: boolean;
  setTermsLoading: Dispatch<SetStateAction<boolean>>;
  sweepstakesGameRules: GameRulesType | null;
  setSweepstakesGameRules: Dispatch<SetStateAction<GameRulesType | null>>;
  gameRulesLoading: boolean;
  setGameRulesLoading: Dispatch<SetStateAction<boolean>>;
  setRegisteringProduct: Dispatch<SetStateAction<boolean>>;
  registeringProduct: boolean;
  redirectResolved: boolean;
  setRedirectResolved: Dispatch<SetStateAction<boolean>>;
  setToken: (newToken: string | null) => void;
  formRegistration: ModuleInfoType | null;
  setFormRegistration: Dispatch<SetStateAction<ModuleInfoType | null>>;
  autoDeployIsNewUser: boolean;
  setAutoDeployIsNewUser: Dispatch<SetStateAction<boolean>>;
  isNewUser: boolean;
  setIsNewUser: Dispatch<SetStateAction<boolean>>;
  isVideoPlaying: boolean;
  setIsVideoPlaying: Dispatch<SetStateAction<boolean>>;
  receiptData: ReceiptType | null;
  setReceiptData: Dispatch<SetStateAction<ReceiptType | null>>;
  openAutoDeployModal: boolean;
  setOpenAutoDeployModal: (value: boolean) => void;
  userClosedAutoDeployDrawer: boolean;
  setUserClosedAutoDeployDrawer: Dispatch<SetStateAction<boolean>>;
  globalLoading: boolean;
  setGlobalLoading: React.Dispatch<React.SetStateAction<boolean>>;
  screen: ScreenType;
  setScreen: (
    screen: ScreenType,
    multipleRegistrationStarted?: boolean
  ) => void;
  registrationScreen: ScreenType;
  emailConsent: boolean;
  toggleEmailConsent: React.Dispatch<React.SetStateAction<boolean>>;
  privacyConsent: boolean;
  togglePrivacyConsent: React.Dispatch<React.SetStateAction<boolean>>;
  registerProduct: () => void;
  formTitle: string;
  setFormTitle: Dispatch<SetStateAction<string>>;
  setAllowClose: Dispatch<SetStateAction<boolean>>;
  allowClose: boolean;
  desktopView: boolean;
  setDesktopView: Dispatch<SetStateAction<boolean>>;
  deviceId: string | null;
  sessionId: string | null;
  registrationId: string | null;
  setRegistrationId: Dispatch<SetStateAction<string | null>>;
  newRegistrationId: string | null;
  setNewRegistrationId: Dispatch<SetStateAction<string | null>>;
  multipleRegistrationStarted: boolean;
  setMultipleRegistrationStarted: Dispatch<SetStateAction<boolean>>;
  getCurrentRegistrationId: () => string | null | undefined;
  landOnMoreScreen: boolean;
  setLandOnMoreScreen: Dispatch<SetStateAction<boolean>>;
};

export const GlobalContext = createContext<GlobalContextProps>({
  appZoom: 1,
  setAppZoom: () => {},
  previewEvent: null,
  setPreviewEvent: () => {},
  previewAuthenticated: false,
  setPreviewAuthenticated: () => {},
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  signInRedirect: '',
  setSignInRedirect: () => {},
  user: null,
  personalDetails: null,
  setpersonalDetails: () => {},
  productDetails: null,
  collectionDetails: [],
  loading: false,
  reFetchProduct: () => {},
  slug: null,
  setSlug: () => {},
  setUser: () => {},
  getCollection: () => {},
  authFetched: false,
  getPersonalDetails: () => {},
  token: null,
  logEvent: () => new Promise((res, rej) => {}),
  magicAction: MAGIC_ACTION.REDIRECT,
  setMagicAction: () => {},
  magicPayload: {},
  setMagicPayload: () => {},
  agegateDisplay: false,
  toggleAgegateDisplay: () => {},
  contentGateDisplay: false,
  toggleContentGateDisplay: () => {},
  autoDeployTriggered: false,
  setAutoDeployTriggered: () => {},
  productModule: '',
  setProductModule: () => {},
  setProductDetails: () => {},
  termsAndPrivacyDetails: null,
  setTermsAndPrivacyDetails: () => {},
  termsLoading: false,
  setTermsLoading: () => {},
  sweepstakesGameRules: null,
  setSweepstakesGameRules: () => {},
  gameRulesLoading: false,
  setGameRulesLoading: () => {},
  setRegisteringProduct: () => {},
  registeringProduct: false,
  redirectResolved: false,
  setRedirectResolved: () => {},
  setToken: () => {},
  formRegistration: null,
  setFormRegistration: () => {},
  autoDeployIsNewUser: false,
  setAutoDeployIsNewUser: () => {},
  isNewUser: false,
  setIsNewUser: () => {},
  isVideoPlaying: false,
  setIsVideoPlaying: () => {},
  receiptData: null,
  setReceiptData: () => {},
  openAutoDeployModal: false,
  setOpenAutoDeployModal: () => {},
  userClosedAutoDeployDrawer: false,
  setUserClosedAutoDeployDrawer: () => {},
  globalLoading: false,
  setGlobalLoading: () => {},
  screen: ScreenType.NONE,
  setScreen: () => {},
  registrationScreen: ScreenType.NONE,
  emailConsent: false,
  toggleEmailConsent: () => {},
  privacyConsent: false,
  togglePrivacyConsent: () => {},
  registerProduct: () => {},
  formTitle: '',
  setFormTitle: () => {},
  getUserAsync: () => {},
  setAllowClose: () => {},
  allowClose: true,
  desktopView: false,
  setDesktopView: () => {},
  deviceId: '',
  sessionId: '',
  registrationId: null,
  setRegistrationId: () => {},
  newRegistrationId: null,
  setNewRegistrationId: () => {},
  multipleRegistrationStarted: false,
  setMultipleRegistrationStarted: () => {},
  getCurrentRegistrationId: () => null,
  landOnMoreScreen: false,
  setLandOnMoreScreen: () => {},
});

export const useGlobal = () => useContext(GlobalContext);
