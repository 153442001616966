import i18n from 'i18next';
import { resources } from './resources';
import { initReactI18next } from 'react-i18next';

const availableTranslations = [
  'en',
  'es',
  'fr',
  'de',
  'tr',
  'pt',
  'ja',
  'ru',
  'he',
];

const getSystemLanguage = () => {
  const userLanguage = navigator.language;
  const systemLanguage = userLanguage.split('-')[0];
  return availableTranslations.includes(systemLanguage) ? systemLanguage : 'en';
};

i18n.use(initReactI18next).init({
  resources,
  lng: getSystemLanguage(),
  interpolation: { escapeValue: false },
});

export default i18n;
