import { useAPI } from 'utils/api';
import { theme } from 'styles/theme';
import { getLocalStorage } from 'utils/storage';
import { getFontWeightStyle } from 'utils/fonts';
import { TermsAndPrivacyType } from 'types/TermsAndPrivacyType';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { ProductDetailsType } from 'types/ProductDetailsType';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';

function useTermsAndPrivacyDetails(
  productDetails: ProductDetailsType | null,
  productLoading: boolean,
  brandId: string | null,
  token: string | null = null
): [
  TermsAndPrivacyType | null,
  Dispatch<SetStateAction<TermsAndPrivacyType | null>>,
  boolean,
  Dispatch<SetStateAction<boolean>>
] {
  const [termsLoading, setTermsLoading] = useState<boolean>(false);
  const [termsAndPrivacyDetails, setTermsAndPrivacyDetails] =
    useState<TermsAndPrivacyType | null>(null);
  const fetchProductRef = useRef<any>(null);

  const { setBrandTheme, setButtonStyle, setSecondaryBrandColor } =
    useThemeContext();

  useEffect(() => {
    if (
      brandId &&
      termsAndPrivacyDetails?.fonts &&
      termsAndPrivacyDetails?.fonts.length > 0 &&
      window.location.pathname.includes('/p/')
    ) {
      const fonts = termsAndPrivacyDetails?.fonts;

      let fontCSS = fonts.reduce(
        (css: string, fontObject: { name: string; src: string }) =>
          css.concat(`
            @font-face {
              font-family: brandFont;
              src: url(${fontObject.src});
              font-weight: ${getFontWeightStyle(fontObject.name).weight};
              font-style: ${getFontWeightStyle(fontObject.name).style};
            }
          `),
        ''
      );
      const style = document.createElement('style');
      style.innerHTML = fontCSS;
      document.head.appendChild(style);
    }
  }, [termsAndPrivacyDetails?.brandId, brandId, termsAndPrivacyDetails?.fonts]);

  const onSuccess = useCallback(
    (termsAndPrivacyDetails: TermsAndPrivacyType) => {
      setTermsAndPrivacyDetails({
        ...termsAndPrivacyDetails,
      });

      if (termsAndPrivacyDetails?.customAccentColor) {
        setBrandTheme(termsAndPrivacyDetails.customAccentColor);
        getLocalStorage().setItem(
          'accentColor',
          termsAndPrivacyDetails?.customAccentColor
        );
      } else {
        setBrandTheme(theme.primary);
        getLocalStorage().setItem('accentColor', '');
      }
      if (termsAndPrivacyDetails?.secondaryColor) {
        setSecondaryBrandColor(termsAndPrivacyDetails.secondaryColor);
        getLocalStorage().setItem(
          'secondaryColor',
          termsAndPrivacyDetails?.secondaryColor
        );
      } else {
        setSecondaryBrandColor(theme.secondary);
        getLocalStorage().setItem('secondaryColor', '');
      }
      if (termsAndPrivacyDetails?.buttonStyle) {
        setButtonStyle(termsAndPrivacyDetails.buttonStyle as CornerStyle);
      } else setButtonStyle(CornerStyle.FULL_ROUND);

      setTermsLoading(false);
      if (fetchProductRef.current) {
        fetchProductRef.current = null;
      }
    },
    [setButtonStyle, setTermsLoading, setBrandTheme, setSecondaryBrandColor]
  );

  const onError = useCallback((error) => {
    window.location.href = '/p/BRIJ';
  }, []);

  // @ts-ignore
  const [getTermsAndPrivacy] = useAPI(
    {
      method: 'GET',
      endpoint: `/terms/${brandId ? brandId : ''}`,
      onError,
    },
    token,
    true,
    true
  );

  const getTermsWithLoading = useCallback(
    async (...args) => {
      if (!brandId) {
        return;
      }

      if (fetchProductRef.current) {
        return fetchProductRef.current;
      }

      setTermsLoading(true);
      fetchProductRef.current = getTermsAndPrivacy(...args);

      // @ts-ignore
      const cache = await fetchProductRef.current;

      if (cache) {
        onSuccess(cache);
      }

      return;
    },
    [getTermsAndPrivacy, onSuccess, brandId]
  );

  useEffect(() => {
    if (brandId === 'BRIJ') return;
    if (productDetails && productDetails?.brand.id === brandId) {
      setTermsAndPrivacyDetails({
        brandId: productDetails?.brand.id,
        website: productDetails?.brand.website,
        brandName: productDetails?.brand?.name,
        brandLegalName: productDetails?.brand?.brandLegalName,
        privacyPolicyURL: productDetails?.brand?.privacyPolicyURL || '',
        termsAndConditionsURL:
          productDetails?.brand?.termsAndConditionsURL || '',
        customAccentColor: productDetails?.brand.customAccentColor || '',
        secondaryColor: productDetails?.brand.secondaryColor,
        buttonStyle: productDetails?.brand.buttonStyle,
        fonts: productDetails?.brand.fonts,
      });
      return;
    } else if (
      !productLoading &&
      productDetails?.brand?.id !== brandId &&
      window.location.pathname.includes('/p/')
    ) {
      getTermsWithLoading();
    }
  }, [productDetails, productLoading, brandId, getTermsWithLoading]);

  return [
    termsAndPrivacyDetails,
    setTermsAndPrivacyDetails,
    termsLoading,
    setTermsLoading,
  ];
}

export default useTermsAndPrivacyDetails;
