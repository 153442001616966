import { useState, useRef, Fragment, useMemo } from 'react';
import { Animated } from 'react-animated-css';
import { getImageMeta } from 'utils/productUtils';
import { useGlobal } from 'context/global/GlobalContext';
import ProgressiveImage from 'react-progressive-image';

const ContentGateBackground = () => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] = useState(true);
  const { productDetails, appZoom } = useGlobal();
  const videoPlayer = useRef<HTMLVideoElement>(null);

  const name: string = productDetails?.brand?.name || '';
  const contentGateModule: any = productDetails?.product?.contentGateModule;

  const mediaSource: string = contentGateModule?.image;

  let { mediaToUse, mediaType } = getImageMeta(
    mediaSource,
    renderOptimizedMedia
  );

  const background: string = useMemo(() => {
    if (contentGateModule?.customColor) {
      return contentGateModule?.customColor;
    }

    return productDetails?.product.customTheme &&
      productDetails?.product.customThemeConfig?.customBgColor
      ? productDetails?.product.customThemeConfig?.customBgColor
      : productDetails?.brand?.customBgColor
      ? productDetails?.brand?.customBgColor
      : 'white';
  }, [productDetails]);

  return (
    <Fragment>
      {!!productDetails && !mediaToUse ? (
        <div
          className='fixed top-0 left-0 w-flex h-flex flex flex-col items-center justify-center'
          style={{
            background,
            height: `${window.innerHeight / appZoom}px`,
          }}
        />
      ) : (
        !!productDetails &&
        (mediaType.includes('mp4') || mediaType.includes('webm') ? (
          <div
            className={`absolute top-0 left-0 w-full flex flex-col items-center justify-center h-full`}
          >
            <video
              loop
              muted
              autoPlay
              controls={false}
              ref={videoPlayer}
              playsInline={true}
              className='w-full h-full object-cover'
              onError={(e) => (e.currentTarget.src = mediaSource)}
            >
              <source src={mediaToUse} type={mediaType} />
            </video>
          </div>
        ) : (
          <ProgressiveImage
            onError={(error) => {
              setRenderOptimizedMedia(false);
              console.log(error);
            }}
            src={mediaToUse}
            placeholder={name}
          >
            {(src: string, loading: boolean) => {
              return !!loading ? (
                <div
                  className={`w-full p-0 absolute`}
                  style={{
                    background,
                    height: `${window.innerHeight / appZoom}px`,
                  }}
                />
              ) : (
                <div
                  className={`flex min-h-full max-h-full w-full p-0 absolute`}
                  style={{
                    background,
                    height: `${window.innerHeight / appZoom}px`,
                  }}
                >
                  <Animated
                    isVisible
                    animationIn='fadeIn'
                    animationOut='fadeIn'
                    animationInDelay={500}
                    className='relative w-full h-full'
                  >
                    <img
                      src={src}
                      alt={name}
                      className='relative w-full min-h-full max-h-full m-auto object-cover'
                    />
                  </Animated>
                </div>
              );
            }}
          </ProgressiveImage>
        ))
      )}
    </Fragment>
  );
};

export default ContentGateBackground;
