import { useEffect, useState } from 'react';

const getMainContainerHeight = () => {
  const mainContainer = document.getElementById('main-consumer-app');
  return mainContainer?.offsetHeight || 0;
};

const useHeights = (mainDrawerOpen: boolean, isChildOpen: boolean = false) => {
  const mainContainerHeight = getMainContainerHeight();

  // we set the bottom height to 100% of the screen initially
  const [bottomHeight, setBottomHeight] = useState<number>(mainContainerHeight);

  const setBottomHeightOnResize = () => {
    const mainContainerHeight = getMainContainerHeight();
    const bottomDrawerContainer = document.getElementById(
      'bottom-drawer-closed-container'
    );

    const bottomDrawerContainerChildren = document.querySelectorAll(
      '.calculate-this-children'
    );

    const children = Array.from(bottomDrawerContainerChildren || []) || [];

    // if the bottom drawer is open, we need to subtract the height of the bottom drawer
    // from the main container height

    const childrenHeight = children.reduce(
      // @ts-ignore
      (height, child) => height + child.offsetHeight,
      0
    );

    if (bottomDrawerContainer) {
      setBottomHeight(mainContainerHeight * 0.88 - (childrenHeight || 0));
    }
  };

  useEffect(() => {
    if (mainDrawerOpen || isChildOpen) {
      return;
    }
    setBottomHeightOnResize();
  }, [mainContainerHeight, mainDrawerOpen, isChildOpen]);

  useEffect(() => {
    window.addEventListener('resize', setBottomHeightOnResize);
    return () => window.removeEventListener('resize', setBottomHeightOnResize);
  }, []);

  return { topHeight: 0, bottomHeight };
};

export default useHeights;
