import {
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import Cookies from 'js-cookie';
import usePrevious from './usePrevious';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { getFontWeightStyle } from 'utils/fonts';
import { ProductDetailsType } from '../types/ProductDetailsType';
import { useAPI } from 'utils/api';

function useProductDetails(
  slug: string | null,
  token: string | null = null,
  previewEvent: any,
  authFetched: boolean,
  registrationId: string | null
): [
  ProductDetailsType | null,
  () => void,
  boolean,
  Dispatch<SetStateAction<ProductDetailsType | null>>
] {
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [productDetails, setProductDetails] =
    useState<ProductDetailsType | null>(null);
  const { setButtonStyle, setInputStyle, setMenuStyle } = useThemeContext();
  const country: string = Cookies.get(`countryCode`) || 'US';
  const previousEvent: any = usePrevious(previewEvent);
  const fetchProductRef = useRef<any>(null);

  useEffect(() => {
    if (slug === '1234' && productDetails?.brand?.id) {
      const fonts = productDetails?.brand?.fonts || [];

      let fontCSS = fonts.reduce(
        (css: string, fontObject: any) =>
          css.concat(`
            @font-face {
              font-family: brandFont;
              src: url(${fontObject.src});
              font-weight: ${getFontWeightStyle(fontObject.name).weight};
              font-style: ${getFontWeightStyle(fontObject.name).style};
            }
          `),
        ''
      );
      const style = document.createElement('style');
      style.innerHTML = fontCSS;
      document.head.appendChild(style);
    }
  }, [productDetails?.brand?.id, slug, productDetails?.brand.fonts]);

  const onSuccess = useCallback(
    (productDetails: any) => {
      const { modules } = productDetails;

      // if the product is already registered to some user don't show activate warranty
      const leadModule = modules[0] || [];
      let moduleCopy = [...modules];

      setProductDetails({
        ...productDetails,
        modules: moduleCopy,
        leadModule,
      });

      if (productDetails?.product?.customTheme) {
        setButtonStyle(productDetails?.product?.customThemeConfig?.buttonStyle);
        setInputStyle(productDetails?.product?.customThemeConfig?.inputStyle);
        setMenuStyle(productDetails?.product?.customThemeConfig?.menuStyle);
      } else {
        setButtonStyle(productDetails?.brand?.buttonStyle);
        setInputStyle(productDetails?.brand?.inputStyle);
        setMenuStyle(productDetails?.brand?.menuStyle);
      }
      setProductLoading(false);
      if (fetchProductRef.current) {
        fetchProductRef.current = null;
      }
    },
    [setButtonStyle, setInputStyle, setMenuStyle, setProductLoading]
  );

  const onError = useCallback(
    (error) => {
      if (error.name !== 'AbortError') {
        setProductLoading(false);
      }

      if (fetchProductRef.current) {
        fetchProductRef.current = null;
      }

      if (error?.status === 404) {
        window.location.href = '/404';
      }
    },
    [setProductLoading]
  );

  // @ts-ignore
  const [getProduct] = useAPI(
    {
      method: 'GET',
      endpoint: `products/${slug}${
        registrationId ? `/${registrationId}` : ''
      }?country=${country}`,
      onError,
    },
    token,
    true,
    true
  );

  const getProductWithLoading = useCallback(
    async (...args) => {
      if (!slug) {
        return;
      }

      // let productDetails = (window as any)?.productDetails;

      // if (productDetails) {
      //   productDetails = window.atob(productDetails);
      //   productDetails = JSON.parse(productDetails);
      //   onSuccess(productDetails);
      //   // @ts-ignore
      //   window.productDetails = null;
      //   return;
      // }

      if (fetchProductRef.current) {
        return fetchProductRef.current;
      }

      setProductLoading(true);

      fetchProductRef.current = getProduct(...args);

      // @ts-ignore
      const cache = await fetchProductRef.current;

      if (cache) {
        onSuccess(cache);
      }

      return;
    },
    [getProduct, onSuccess, slug]
  );

  useEffect(() => {
    if (!authFetched) return;
    if (
      slug &&
      !window.location.pathname.includes('/p/') &&
      !window.location.pathname.includes('/s/')
    )
      getProductWithLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, authFetched]);

  useEffect(() => {
    if (registrationId) getProductWithLoading(null, token);
  }, [getProductWithLoading, registrationId]);

  useEffect(() => {
    if (previewEvent && previewEvent.type === 'product') {
      if (
        JSON.stringify(previousEvent.productDetails) ===
        JSON.stringify(previewEvent.productDetails)
      ) {
        return;
      }

      setTimeout(() => {
        onSuccess(previewEvent.productDetails);
      });
    }
  }, [previewEvent, onSuccess]);

  return [
    productDetails,
    getProductWithLoading,
    productLoading,
    setProductDetails,
  ];
}

export default useProductDetails;
