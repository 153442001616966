import React, { useState, useEffect, useRef } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useAPI } from 'utils/api';
import { FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from 'utils/storage';
import {
  ConsentConfig,
  FiledValidationType,
  FormDetailModel,
  Property,
  PropertyDestination,
} from 'types/FormTypes';
import { useGlobal } from 'context/global/GlobalContext';
import { showToast } from 'components/atomic/Toast/Toast';
import { ModuleInfoType } from 'types/ProductDetailsType';
import { QuestionType } from 'types/FormDetailsEnum';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useFormContext } from 'context/FormDrawerContext/FormDrawerContext';
import { ReactComponent as BackIcon } from 'assets/icons/svg/chevron-left-light.svg';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';
import formSwipeAnimation from 'assets/lottie-animations/form-swipe-animation.json';
import * as Yup from 'yup';
import Lottie from 'react-lottie';
import Button from 'components/atomic/Button';
import useWindowSize from 'hooks/useWindowSize';
import FormDropDown from './components/FormDropDown';
import FormCheckBox from './components/FormCheckBox';
import FormTextField from './components/FormTextField';
import FormStartPage from './components/FormStartPage';
import ProgressBar from 'components/atomic/ProgressBar';
import FormDatePicker from './components/FormDatePicker';
import FormFileUpload from './components/FormFileUpload';
import FromStepWrapper from './components/FromStepWrapper';
import ModuleWrapper from 'components/wrappers/ModuleWrapper';
import FormCompletionPage from './components/FormCompletionPage';
import FormMultipleChoice from './components/FormMultipleChoice';
import FormProtectedRoute from './components/FormProtectedRoute';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import dayjs from 'dayjs';
import { isPreviewMode } from '../../../utils/preview';
import FormPhoneNumber from './components/FormPhoneNumber';
import FormAddress from './components/FormAddress';
import FormNumber from './components/FormNumber';
import FormRetailChannel from './components/FormRetailChannel';
import FormUserReview from './components/FormUserReview';
import FormName from '../FormDrawer/components/FormName';
import FormContentBlock from '../FormDrawer/components/FormContentBlock';
import FormSearchableDropDown from './components/FormSearchableDropdown';
import validator from 'validator';
import FormEmail from './components/FormEmail';
import { validateAgainstList } from 'utils/forms';

const validateCurrentStep = async (
  currentStep: number,
  data: FormDetailModel[],
  formik: React.MutableRefObject<FormikProps<any> | null>,
  getCurrentFormName: (currentStep: number) => string
): Promise<Boolean> => {
  const currentFormQuestion = data[currentStep - 1];
  const fieldValidation = currentFormQuestion?.config?.fieldValidation;
  const value = formik.current?.values[getCurrentFormName(currentStep)];

  const flattenValue = flattenAnswer(currentFormQuestion, value);

  if (!flattenValue) {
    return true;
  }

  switch (fieldValidation?.type) {
    case FiledValidationType.alphanumeric:
      if (
        !fieldValidation.allowSpecialCharacters &&
        /[^a-zA-Z0-9 ]/.test(flattenValue) // Allow spaces
      ) {
        formik.current?.setFieldError(
          getCurrentFormName(currentStep),
          'Special characters are not allowed'
        );
        return false;
      }
      if (fieldValidation.min && flattenValue.length < fieldValidation.min) {
        formik.current?.setFieldError(
          getCurrentFormName(currentStep),
          `Value must be at least ${fieldValidation.min} characters`
        );
        return false;
      }
      if (fieldValidation.max && flattenValue.length > fieldValidation.max) {
        formik.current?.setFieldError(
          getCurrentFormName(currentStep),
          `Value must be at most ${fieldValidation.max} characters`
        );
        return false;
      }
      break;
    case FiledValidationType.numeric:
      if (!/^\d+$/.test(flattenValue)) {
        formik.current?.setFieldError(
          getCurrentFormName(currentStep),
          'Value must be numeric'
        );
        return false;
      }
      if (fieldValidation.min && flattenValue < fieldValidation.min) {
        formik.current?.setFieldError(
          getCurrentFormName(currentStep),
          `Value must be at least ${fieldValidation.min}`
        );
        return false;
      }
      if (fieldValidation.max && flattenValue > fieldValidation.max) {
        formik.current?.setFieldError(
          getCurrentFormName(currentStep),
          `Value must be at most ${fieldValidation.max}`
        );
        return false;
      }
      break;
    default:
      return true;
  }

  return true;
};

const ValidateListItem = async (
  currentStep: number,
  data: FormDetailModel[],
  formik: React.MutableRefObject<FormikProps<any> | null>,
  getCurrentFormName: (currentStep: number) => string
): Promise<Boolean> => {
  const currentFormQuestion = data[currentStep - 1];
  const fieldValidation = currentFormQuestion?.config?.fieldValidation;
  const value = formik.current?.values[getCurrentFormName(currentStep)];

  if (!value) {
    return true;
  }

  switch (fieldValidation?.type) {
    case FiledValidationType.list:
      // Call an API to validate the value
      const isValid = await validateAgainstList(
        value,
        fieldValidation?.listId,
        fieldValidation?.uniqueEntry
      );

      if (!isValid) {
        formik.current?.setFieldError(
          getCurrentFormName(currentStep),
          'Invalid value'
        );
        return false;
      }

      break;
    default:
      return validateCurrentStep(currentStep, data, formik, getCurrentFormName);
  }

  return true;
};

export const getDataFromAnswers = (answers: any[]) => {
  const data: any = [];

  answers.forEach((answer) => {
    if (answer.question?.type === QuestionType.CONTENT_BLOCK) {
      return;
    }

    data.push({
      answer: answer.answer,
      property: answer.property?._id || '',
      question: answer.question?.text || '',
    });
  });

  return data;
};

const flattenAnswer = (question: any, answer: any) => {
  switch (question.type) {
    case QuestionType.TEXT:
    case QuestionType.NUMBER:
    case QuestionType.REVIEW_RATING:
    case QuestionType.DROP_DOWN:
    case QuestionType.DATE_SELECTOR:
    case QuestionType.MULTIPLE_CHOICE:
    case QuestionType.SEARCHABLE_DROP_DOWN:
      return answer;
    case QuestionType.FILE:
      return (
        (answer && answer?.map((file: any) => file.fileUrl).join(', ')) || ''
      );
    case QuestionType.PHONE_NUMBER:
      return answer?.phoneNumber;
    case QuestionType.EMAIL:
      return answer?.email;
    case QuestionType.RETAIL_CHANNEL:
      // if custom is true, then send the value because it's plain text
      // otherwise send the label because the value is an id
      return answer?.custom ? answer?.value : answer?.label;
    case QuestionType.ADDRESS:
      const { line1, line2, city, state, zip, country } = answer;
      // us address format
      const addressParts = [line1, line2, city, state, zip, country].filter(
        (value) => value
      );
      return addressParts.join(', ');
    case QuestionType.NAME:
      return `${answer?.firstName} ${answer?.lastName}`;
    default:
      return answer;
  }
};

const getPropertyValue = (
  property: Property,
  subProperty: string | null | undefined,
  userProfile: any,
  brandId?: string,
  registrationId?: string | null
) => {
  if (!property?._id) return '';

  const getSubPropertyValue = (source: any, propName: string) => {
    if (subProperty) {
      switch (propName) {
        // special case for name
        // because first name and last name are stored on the root level
        case 'name': {
          const subProp = property.subProperties?.find(
            (subProp: Property) => subProp._id === subProperty
          );
          if (subProp) {
            return source?.[subProp.name] || '';
          }
          break;
        }
        default: {
          const subProp = property.subProperties?.find(
            (subProp: Property) => subProp._id === subProperty
          );
          if (subProp) {
            return source?.[propName]?.[subProp.name] || '';
          }
        }
      }
    }
    return source?.[propName] || '';
  };

  if (property?.destination.includes(PropertyDestination.Profile)) {
    if (property.global) {
      return getSubPropertyValue(userProfile?.profile, property.name);
    } else if (brandId) {
      return (
        userProfile?.profile?.properties?.[brandId]?.[property._id]?.value || ''
      );
    }
  }

  if (
    property?.destination.includes(PropertyDestination.Registration) &&
    registrationId
  ) {
    const item = userProfile?.profile?.productCollection?.find(
      (item: any) => item.registrationId === registrationId
    );
    if (item) {
      if (property.global) {
        return getSubPropertyValue(item, property.name);
      } else if (brandId) {
        return item?.[brandId]?.[property._id]?.value || '';
      }
    }
  }

  return '';
};

const prepareFieldsAndAnswers = (
  moduleInfo: FormDetailModel[],
  answers: string[] | null
) => {
  if (!moduleInfo?.length) return [];
  if (!answers) return [];

  return moduleInfo.reduce((result, question, index) => {
    return result.concat({
      property: question.property || {},
      answer: answers[index] || '',
      question,
    });
  }, [] as any[]);
};

const prepareQuestionsAndAnswers = (
  moduleInfo: FormDetailModel[],
  answers: any[] | null
) => {
  if (!answers)
    return {
      questionsAndAnswers: {},
      flattenAnswers: [],
    };
  const questionsAndAnswers: any = {};
  const flattenAnswers: any[] = [];
  moduleInfo.forEach((question, index) => {
    if (question?.type === QuestionType.CONTENT_BLOCK) {
      return;
    }

    const answer = flattenAnswer(question, answers[index]);
    questionsAndAnswers[question.text] = answer;
    flattenAnswers.push(answer);
  });

  return { questionsAndAnswers, flattenAnswers };
};

type FormDrawerProps = {
  onSubmit?: () => void;
  data: FormDetailModel[];
  formModuleData: ModuleInfoType;
  endScreenNavModuleIndex?: number;
  changeDrawerPage?: (moduleIdx: number) => void;
  closeDrawer?: (
    closeDrawer: boolean,
    registrationFormCallback?: boolean
  ) => void;
  type?: 'FORM' | 'REVIEW';
};

type initNames = {
  [key: string]: string | [] | any;
};

export interface FormMatchParams {
  id: string;
  stepId: string;
}

const FormDrawer: React.FC<FormDrawerProps> = ({
  data = [],
  onSubmit,
  closeDrawer,
  formModuleData,
  changeDrawerPage,
  endScreenNavModuleIndex,
  type = 'FORM',
}) => {
  const [formOrder, setFormOrder] = useState<string[]>([]);
  const [validationYup, setValidationYup] = useState<any>({});
  const [initNameObjectYup, setNameObjectYup] = useState<any>({});
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [sessionStorageSet, setSessionStorageSet] = useState<boolean>(false);
  const [previewEventCoolDown, setPreviewEventCoolDown] =
    useState<boolean>(false);
  const [transistionAnimation, setTransistionAnimation] =
    useState<string>('right-to-left');
  const [loadSwipeLottieStartScreen, setLoadSwipeLottieStartScreen] =
    useState<boolean>(false);

  const route = useHistory();
  const location = useLocation();
  const formik = useRef<FormikProps<any>>(null);
  const answers = useRef([] as string[] | null);
  const clearTimerRefStartScreen = useRef<any>();
  const { setMeta, openDrawer, showSuccess, closeSuccessDrawer } =
    useSuccessDrawerContext();
  const { params } = useRouteMatch<FormMatchParams>();
  const { buttonStyle } = useThemeContext();
  const { height } = useWindowSize();
  let { id } = params;

  const {
    user,
    slug,
    logEvent,
    formTitle,
    desktopView,
    setFormTitle,
    openAutoDeployModal,
    productDetails: details,
    getCurrentRegistrationId,
    setReceiptData,
    personalDetails,
    setpersonalDetails,
  } = useGlobal();

  const {
    currentStep,
    startScreen,
    nextDisabled,
    setTotalSteps,
    setStartScreen,
    setCurrentStep,
    endScreen,
    setEndScreen,
  } = useFormContext();

  const brandId = details?.brand?.id;

  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer',
  });

  const registrationFormId = details?.registration?.formModuleId;
  const enablePurchaseDetails = details?.registration?.enablePurchaseDetails;

  const currentFormId =
    registrationFormId === formModuleData?.id
      ? registrationFormId
      : formModuleData?.id;

  const updateCurrentStep = (currentStep: number) => {
    getLocalStorage().setItem(
      `brij-form-${slug}-${currentFormId}-current-step`,
      currentStep
    );
    setCurrentStep(currentStep);
  };

  useEffect(() => {
    setTotalSteps(data?.length);
  }, [setTotalSteps, data]);

  // Lottie swipe animation start screen
  useEffect(() => {
    if (startScreen && !loadSwipeLottieStartScreen) {
      clearTimerRefStartScreen.current = setTimeout(() => {
        setLoadSwipeLottieStartScreen(true);
      }, 5000);
    } else {
      clearTimeout(clearTimerRefStartScreen.current);
      setLoadSwipeLottieStartScreen(false);
    }
  }, [startScreen, loadSwipeLottieStartScreen, setLoadSwipeLottieStartScreen]);

  useEffect(() => {
    if (setFormTitle) {
      if (
        currentStep &&
        !startScreen &&
        !endScreen &&
        !getLocalStorage().getItem(
          `brij-form-${slug}-${currentFormId}-complete`
        )
      )
        setFormTitle(`${currentStep}/${data?.length}`);
      else if (
        getLocalStorage().getItem(`brij-form-${slug}-${currentFormId}-complete`)
      )
        setFormTitle(t('submissionSuccessful'));
      else if (startScreen) setFormTitle(formModuleData.title);
      else if (endScreen) setFormTitle(t('submissionSuccessful'));
      else setFormTitle(`${currentStep}/${data?.length}`);
    }
  }, [
    t,
    currentStep,
    startScreen,
    setFormTitle,
    endScreen,
    data?.length,
    currentFormId,
    formModuleData,
  ]);

  useEffect(() => {
    const previewWork = (event: MessageEvent<any>) => {
      const type = event?.data?.type;
      const eventData = event?.data?.data;

      if (type !== 'changeFormStep') return;

      setPreviewEventCoolDown(true);
      setTimeout(() => {
        setPreviewEventCoolDown(false);
      }, 200);
      if (eventData.step === 'complete') {
        setStartScreen(false);
        setEndScreen(true);
        setFormTitle(t('submissionSuccessful'));
      } else if (eventData.step === 'start') {
        setStartScreen(true);
        setEndScreen(false);
        updateCurrentStep(1);
        setFormTitle(formModuleData.title);
      } else {
        setStartScreen(false);
        setEndScreen(false);
        setFormTitle(`${eventData.step}/${data?.length}`);
      }
      if (eventData.step === currentStep && !startScreen && !endScreen) {
        return;
      }

      if (parseInt(eventData.step) === eventData.step) {
        updateCurrentStep(eventData.step);
        route.push(`/c/${id}/form/${currentFormId}/step/${eventData.step}`);
      } else {
        if (
          window?.location?.href.includes(
            `/c/${id}/form/${currentFormId}/${eventData.step}`
          )
        )
          return;
        route.push(`/c/${id}/form/${currentFormId}/${eventData.step}`);
      }
    };
    window.addEventListener('message', previewWork, false);

    return () => window.removeEventListener('message', previewWork);
  }, [currentStep, startScreen, data, endScreen]);

  useEffect(() => {
    if (isPreviewMode()) {
      return;
    }
    if (!location.pathname.includes(`form/start`)) {
      setStartScreen(false);
    }
  }, [location.pathname]);

  const [submitFormRegistration] = useAPI<any>({
    method: 'POST',
    endpoint: 'form/submit_form',
    onSuccess: (data: { responseId: string; success: boolean }) => {
      // if there isn't a current user then call update after a registration
      if (!user?.uid) {
        getLocalStorage().setItem('brij-form-user-update-id', data.responseId);
      }

      removeRegistrationFormStorage();
    },
    onError: () => {
      if (endScreenNavModuleIndex === undefined) {
        showToast({
          message: t('formSubmissionError'),
          type: 'error',
        });
      }
      setIsFormSubmitting(false);
      closeSuccessDrawer();
      removeRegistrationFormStorage();
    },
  });

  const [submitForm] = useAPI<any>(
    {
      method: 'POST',
      endpoint: 'form/submit_form',
      onSuccess: (data: { responseId: string; success: boolean }) => {
        // this is registration form flow.
        if (
          endScreenNavModuleIndex !== undefined &&
          closeDrawer &&
          changeDrawerPage
        ) {
          // remove form registraion as user already submitted it.
          removeRegistrationFormStorage();

          // if there isn't a current user then call update after a registration
          if (!user?.uid) {
            getLocalStorage().setItem(
              'brij-form-user-update-id',
              data.responseId
            );
          }

          return;
        }

        // this is regular form module submission
        if (endScreenNavModuleIndex === undefined) {
          setIsFormSubmitting(false);
          setMeta({
            title:
              formModuleData?.confirmationHeader || 'Submission Successful!',
            description: formModuleData?.confirmationMessage || '',
          });

          showSuccess();
        }
        if (
          endScreenNavModuleIndex !== undefined &&
          closeDrawer &&
          changeDrawerPage
        ) {
          // This is normal registration flow.
          closeDrawer(true, true);
          changeDrawerPage(endScreenNavModuleIndex);
          getLocalStorage().removeItem(`brij-form-${slug}-${currentFormId}`);
          getLocalStorage().setItem(
            `brij-form-${slug}-${currentFormId}-start-screen-shown`,
            false
          );
          getLocalStorage().setItem(
            `brij-form-${slug}-${currentFormId}-current-step`,
            1
          );
          getLocalStorage().removeItem(
            `brij-form-${slug}-${currentFormId}-return-module-id`
          );
          getLocalStorage().setItem(
            `brij-form-${slug}-${currentFormId}-complete`,
            true
          );

          // if there isn't a current user than call update after a registration
          if (!user?.uid) {
            getLocalStorage().setItem(
              'brij-form-user-update-id',
              data.responseId
            );
          }
        }

        if (endScreenNavModuleIndex !== undefined) {
          return;
        }

        getLocalStorage().setItem(
          `brij-form-${slug}-${currentFormId}-complete`,
          true
        );

        // if no end screen content go back to main page
        if (!formModuleData.endScreenContent && closeDrawer && !desktopView) {
          closeDrawer(true);
          getLocalStorage().removeItem(`brij-form-${slug}-${currentFormId}`);
          return;
        }

        if (desktopView) {
          route.push(`/c/${id}/form/${currentFormId}/complete`);
          setEndScreen(true);
        }

        getLocalStorage().setItem(
          `brij-form-${slug}-${currentFormId}-complete`,
          true
        );

        // form complete only available on
        route.push(`/c/${id}/form/${currentFormId}/complete`);
        setEndScreen(true);
      },
      onError: () => {
        if (endScreenNavModuleIndex === undefined) {
          showToast({
            message: t('formSubmissionError'),
            type: 'error',
          });
        }
        setIsFormSubmitting(false);
        closeSuccessDrawer();
        removeRegistrationFormStorage();
      },
    },
    null,
    true
  );

  const removeRegistrationFormStorage = () => {
    getLocalStorage().removeItem(`brij-form-${slug}-${currentFormId}`);
    getLocalStorage().removeItem(
      `brij-form-${slug}-${currentFormId}-start-screen-shown`
    );
    getLocalStorage().removeItem(
      `brij-form-${slug}-${currentFormId}-current-step`
    );
    getLocalStorage().removeItem(
      `brij-form-${slug}-${currentFormId}-return-module-id`
    );
    getLocalStorage().setItem(
      `brij-form-${slug}-${currentFormId}-complete`,
      'true'
    );
    getLocalStorage().removeItem(`brij-form-${slug}-${currentFormId}-filled`);
  };

  useEffect(() => {
    if (!isPreviewMode() || previewEventCoolDown) {
      return;
    }

    if (
      formModuleData.startScreenContent === ' ' ||
      !formModuleData.startScreenContent
    ) {
      updateCurrentStep(1);
      setStartScreen(false);
      setEndScreen(false);
      route.push(`/c/${id}/form/${currentFormId}/step/1`);
      return;
    } else if (
      formModuleData.startScreenContent &&
      formModuleData.startScreenContent !== ' '
    ) {
      setStartScreen(true);
      setEndScreen(false);
      route.push(`/c/${id}/form/${currentFormId}/start`);
      return;
    }
  }, []);

  useEffect(() => {
    if (isPreviewMode()) {
      return;
    }
    const existingCurrentStep = getLocalStorage().getItem(
      `brij-form-${slug}-${currentFormId}-current-step`
    );

    let isFormComplete: boolean = false;
    if (
      getLocalStorage().getItem(
        `brij-form-${slug}-${currentFormId}-complete`
      ) === null
    )
      isFormComplete =
        formModuleData.singleSubmission &&
        getLocalStorage().getItem(`brij-form-${slug}-${currentFormId}-complete`)
          ? true
          : false;
    else
      isFormComplete =
        getLocalStorage().getItem(
          `brij-form-${slug}-${currentFormId}-complete`
        ) || formModuleData.isAlreadySubmitted;

    if (isFormComplete) {
      route.push(`/c/${id}/form/${currentFormId}/complete`);
      return;
    }

    if (existingCurrentStep && !startScreen) {
      updateCurrentStep(parseInt(existingCurrentStep));
      setEndScreen(false);
      route.push(`/c/${id}/form/${currentFormId}/step/${existingCurrentStep}`);
      return;
    }

    let startScreenShown: string | null;
    if (endScreenNavModuleIndex !== undefined) {
      startScreenShown = getLocalStorage().getItem(
        `brij-form-${slug}-${currentFormId}-start-screen-shown`
      );
    } else {
      startScreenShown = getLocalStorage().getItem(
        `brij-form-${slug}-${currentFormId}-start-screen-shown`
      );
    }

    if (
      formModuleData.startScreenContent === ' ' ||
      !formModuleData.startScreenContent
    ) {
      updateCurrentStep(1);
      setStartScreen(false);
      setEndScreen(false);
      getLocalStorage().setItem(
        `brij-form-${slug}-${currentFormId}-start-screen-shown`,
        true
      );
      route.push(`/c/${id}/form/${currentFormId}/step/1`);
      return;
    } else if (
      formModuleData.startScreenContent &&
      formModuleData.startScreenContent !== ' ' &&
      !startScreenShown
    ) {
      setStartScreen(true);
      setEndScreen(false);
      route.push(`/c/${id}/form/${currentFormId}/start`);
      getLocalStorage().setItem(
        `brij-form-${slug}-${currentFormId}-start-screen-shown`,
        false
      );
      getLocalStorage().removeItem(
        `brij-form-${slug}-${currentFormId}-current-step`
      );
      return;
    }
  }, [location.pathname]);

  useEffect(() => {
    // Create formik inital values
    // This is to play nicely with the api call as the order needs to be persisted.
    if (data && !sessionStorageSet) {
      const initNamesObject: initNames = {};
      const validationObject: any = {};
      let createFormOrder: string[] = [];
      data.forEach((value, idx) => {
        let valueToUse = '';

        if (value?.property) {
          valueToUse = getPropertyValue(
            value.property,
            value.subProperty,
            personalDetails,
            brandId,
            getCurrentRegistrationId()
          );
        }

        switch (value.type) {
          case QuestionType.CHECKBOX:
          case QuestionType.FILE:
            initNamesObject[`${value.type}${idx + 1}`] = [];
            break;
          case QuestionType.PHONE_NUMBER:
            initNamesObject[`${value.type}${idx + 1}`] = {
              phoneNumber: valueToUse || '',
              consent:
                (value?.config as ConsentConfig)?.checkedByDefault || false,
            };
            break;
          case QuestionType.EMAIL:
            initNamesObject[`${value.type}${idx + 1}`] = {
              email: valueToUse || '',
              consent:
                (value?.config as ConsentConfig)?.checkedByDefault || false,
            };
            break;
          case QuestionType.RETAIL_CHANNEL:
            initNamesObject[`${value.type}${idx + 1}`] = {
              custom: false,
              value: '',
              label: '',
            };
            break;
          case QuestionType.NAME:
            let values = {
              firstName: '',
              lastName: '',
            };

            // prefill the name value for global property
            // remove this in the future
            if (value?.property?.global) {
              values.firstName = personalDetails?.profile?.firstName || '';
              values.lastName = personalDetails?.profile?.lastName || '';
            }

            initNamesObject[`${value.type}${idx + 1}`] = values;
            break;
          default:
            initNamesObject[`${value.type}${idx + 1}`] = valueToUse;
            break;
        }

        createFormOrder.push(`${value.type}${idx + 1}`);
      });

      setFormOrder(createFormOrder);

      data.forEach((value, idx) => {
        switch (value.type) {
          case QuestionType.DROP_DOWN:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] =
                Yup.string().required();
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.string();
            }
            break;
          case QuestionType.SEARCHABLE_DROP_DOWN:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] =
                Yup.string().required();
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.string();
            }
            break;
          case QuestionType.RETAIL_CHANNEL:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] = Yup.object().shape({
                value: Yup.string().required(),
              });
            }
            break;
          case QuestionType.REVIEW_RATING:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] =
                Yup.number().required();
            }
            break;
          case QuestionType.MULTIPLE_CHOICE:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] =
                Yup.string().required();
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.string();
            }
            break;
          case QuestionType.TEXT:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] = Yup.string()
                .max(600, 'Max length is 600 characters')
                .required('required');
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.string().max(
                600,
                'Max length is 600 characters'
              );
            }
            break;
          case QuestionType.NUMBER:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] =
                Yup.number().required('required');
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.number();
            }
            break;
          case QuestionType.CHECKBOX:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] = Yup.array().min(1);
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.array();
            }
            break;
          case QuestionType.FILE:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] = Yup.array().min(1);
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.array();
            }
            break;
          case QuestionType.DATE_SELECTOR:
            if (value.isRequired) {
              validationObject[`${value.type}${idx + 1}`] = Yup.date()
                .typeError('Invalid Date')
                .min(new Date('1910/01/01'))
                .max(new Date('2100/01/01'))
                .required();
            } else {
              validationObject[`${value.type}${idx + 1}`] = Yup.date()
                .typeError('Invalid Date')
                .min(new Date('1910/01/01'))
                .max(new Date('2100/01/01'));
            }
            break;
          case QuestionType.NAME:
            let nameValidation = Yup.object().shape({}) as any;

            if (value.isRequired) {
              nameValidation = nameValidation.concat(
                Yup.object().shape({
                  firstName: Yup.string().required('required'),
                  lastName: Yup.string().required('required'),
                })
              );
            }

            validationObject[`${value.type}${idx + 1}`] = nameValidation;
            break;
          case QuestionType.PHONE_NUMBER:
            let phoneValidation = Yup.object().shape({}) as any;

            if (value.isRequired) {
              phoneValidation = phoneValidation.concat(
                Yup.object().shape({
                  phoneNumber: Yup.string().required('required'),
                })
              );
            }

            const { required, showConsent } =
              (value?.config as ConsentConfig) || {};

            if (showConsent && required) {
              phoneValidation = phoneValidation.concat(
                Yup.object().shape({
                  consent: Yup.boolean()
                    .required('required')
                    .oneOf([true], 'consent must be true'),
                })
              );
            }

            validationObject[`${value.type}${idx + 1}`] = phoneValidation;
            break;
          case QuestionType.EMAIL:
            let emailValidation = Yup.object().shape({}) as any;

            if (value.isRequired) {
              emailValidation = emailValidation.concat(
                Yup.object().shape({
                  email: Yup.string()
                    .test(
                      'is-valid-email',
                      'Invalid email',
                      (value = '') =>
                        validator.isEmail(value) &&
                        !value.toLowerCase().endsWith('.con')
                    )
                    .required('required'),
                })
              );
            } else {
              emailValidation = emailValidation.concat(
                Yup.object().shape({
                  email: Yup.string().test(
                    'is-valid-email',
                    'Invalid email',
                    (value = '') =>
                      validator.isEmail(value) &&
                      !value.toLowerCase().endsWith('.con')
                  ),
                })
              );
            }

            const { required: emailRequired, showConsent: emailShowConsent } =
              (value?.config as ConsentConfig) || {};

            if (emailShowConsent && emailRequired) {
              emailValidation = emailValidation.concat(
                Yup.object().shape({
                  consent: Yup.boolean()
                    .required('required')
                    .oneOf([true], 'consent must be true'),
                })
              );
            }

            validationObject[`${value.type}${idx + 1}`] = emailValidation;
            break;
          default:
            break;
        }
      });
      setNameObjectYup(initNamesObject);
      setValidationYup(Yup.object().shape(validationObject));
      if (formik.current) {
        try {
          formik.current.validateForm();
        } catch {}
      }
    }
  }, [data]);

  useEffect(() => {
    let form = getLocalStorage().getItem(`brij-form-${slug}-${currentFormId}`);
    if (formik?.current && form) {
      let values = JSON.parse(form);
      if (Object.keys(values)?.length) setNameObjectYup(values);
      formik.current.values = JSON.parse(form);
    }
  }, [location, currentStep, currentFormId]);

  const handleBtnSubmit = async () => {
    const isValid = await ValidateListItem(
      currentStep,
      data,
      formik,
      getCurrentFormName
    );

    if (!isValid) {
      return;
    }

    const { moduleInfo } = formModuleData;

    answers.current = prepareFormAnswers();

    const answersWithProperty = prepareFieldsAndAnswers(
      moduleInfo as FormDetailModel[],
      answers.current
    );

    const receiptData = {} as any;
    const globalProfileFieldsToUpdate = personalDetails?.profile || ({} as any);
    const customProfileFieldsToUpdate = {} as any;

    answersWithProperty.forEach((answer: any) => {
      const { property, question } = answer;

      if (!property?.name) {
        return;
      }

      // update customer profile locally until the next refresh
      if (property?.destination.includes(PropertyDestination.Profile)) {
        if (property?.global) {
          switch (property?.name) {
            case 'phoneNumber':
              globalProfileFieldsToUpdate['phoneNumber'] =
                answer.answer.phoneNumber;
              break;
            case 'name': {
              if (question.subProperty) {
                const subPropertyToUpdate = property.subProperties?.find(
                  (subProperty: Property) => {
                    return question.subProperty === subProperty._id;
                  }
                );
                if (subPropertyToUpdate) {
                  globalProfileFieldsToUpdate[`${subPropertyToUpdate.name}`] =
                    answer.answer;
                }
              } else {
                globalProfileFieldsToUpdate[`firstName`] =
                  answer.answer?.firstName;
                globalProfileFieldsToUpdate[`lastName`] =
                  answer.answer?.lastName;
              }

              break;
            }
            case 'address': {
              if (!globalProfileFieldsToUpdate['address']) {
                globalProfileFieldsToUpdate['address'] = {};
              }

              if (question.subProperty) {
                const subPropertyToUpdate = property.subProperties?.find(
                  (subProperty: Property) => {
                    return question.subProperty === subProperty._id;
                  }
                );
                if (subPropertyToUpdate) {
                  globalProfileFieldsToUpdate['address'][
                    `${subPropertyToUpdate.name}`
                  ] = answer.answer;
                }
              } else {
                globalProfileFieldsToUpdate[`address`] = answer.answer;
              }
              break;
            }
            default:
              globalProfileFieldsToUpdate[answer.property.name] = answer.answer;
          }
        } else {
          if (!customProfileFieldsToUpdate[brandId as string]) {
            customProfileFieldsToUpdate[brandId as string] = {};
          }

          customProfileFieldsToUpdate[brandId as string][answer.property._id] =
            {
              propertyId: answer.property._id,
              value: answer.answer,
            };
        }
      }

      if (!answer?.property?.global) {
        return;
      }

      switch (answer?.property?.name) {
        case 'retailChannel':
          if (answer?.answer?.custom) {
            receiptData['retailChannelOther'] = answer.answer.value;
          } else {
            receiptData['retailChannel'] = answer.answer.value;
          }
          break;
        case 'phoneNumber':
          receiptData['phoneNumber'] = answer.answer.phoneNumber;
          break;
        case 'receiptUpload':
          receiptData['receipts'] = answer.answer;
          break;
        default:
          receiptData[answer?.property?.name] = answer.answer;
          break;
      }
    });

    const mergeProperties = (obj: any, newObj: any) => {
      if (!obj) {
        return newObj;
      }

      for (const key in newObj) {
        if (typeof newObj[key] === 'object') {
          if (!obj[key]) {
            obj[key] = newObj[key];
          } else {
            mergeProperties(obj[key], newObj[key]);
          }
        } else {
          obj[key] = newObj[key];
        }
      }

      return obj;
    };

    setReceiptData(receiptData);

    try {
      // @ts-ignore
      setpersonalDetails({
        ...personalDetails,
        profile: {
          ...personalDetails?.profile,
          ...globalProfileFieldsToUpdate,
          properties: mergeProperties(
            personalDetails?.profile?.properties,
            customProfileFieldsToUpdate
          ),
        },
      });
    } catch (e) {
      console.log(e);
    }

    const { questionsAndAnswers, flattenAnswers } = prepareQuestionsAndAnswers(
      moduleInfo as FormDetailModel[],
      answers.current
    );

    const formEventData = {
      eventType: 'ENGAGEMENTS',
      event:
        type.toUpperCase() == 'FORM' ? 'FORM_SUBMISSION' : 'REVIEW_SUBMISSION',
      moduleType: formModuleData.type,
      moduleId: currentFormId,
      date: dayjs(),
      registrationId: getCurrentRegistrationId(),
      data: {
        registrationForm: endScreenNavModuleIndex === undefined,
        response: questionsAndAnswers,
        formName: formModuleData.name,
        registrationId: getCurrentRegistrationId(),
        responseWithProperty: getDataFromAnswers(answersWithProperty),
      },
    };

    const formDataToSend = {
      user: user?.uid,
      formId: currentFormId,
      slug: slug,
      answersWithProperty,
      answers: flattenAnswers,
      brandId: brandId,
      registrationId: getCurrentRegistrationId(),
      registrationModuleId: details?.registration?._id,
      formEventData,
    };

    if (endScreenNavModuleIndex === undefined) {
      setIsFormSubmitting(true);
      openDrawer();
      submitForm(formDataToSend);
    } else {
      setIsFormSubmitting(true);

      getLocalStorage().setItem(
        `brij-form-${slug}-${currentFormId}-filled`,
        'true'
      );

      if (enablePurchaseDetails) {
        submitFormRegistration(formDataToSend);
      } else {
        openDrawer();
        await submitFormRegistration(formDataToSend);
      }

      if (closeDrawer && changeDrawerPage)
        changeDrawerPage(endScreenNavModuleIndex);

      if (onSubmit) onSubmit();

      setCurrentStep(1);
    }

    // reset the form values
    const values = formik.current?.values;

    for (const key in values) {
      if (key.includes('file-upload')) {
        formik.current?.setFieldValue(key, []);
      } else if (key.includes('checkbox')) {
        formik.current?.setFieldValue(key, []);
      } else {
        formik.current?.setFieldValue(key, '');
      }
    }
  };

  const prepareFormAnswers = (): string[] => {
    // Submit the form
    // Prepare for submit.
    let buildAnswersArray: any[] = [];
    let formResults = formik.current?.values;

    formOrder.forEach((value) => {
      if (value.includes('file-upload')) {
        if (!formResults[value]?.length) {
          buildAnswersArray.push([]);
        } else {
          buildAnswersArray.push(formResults[value]);
        }
      } else if (value.includes('checkbox')) {
        const mutipleAns = formResults[value].join(',');
        buildAnswersArray.push(mutipleAns);
      } else if (
        formResults[value] === null ||
        formResults[value] === undefined
      ) {
        buildAnswersArray.push('');
      } else {
        buildAnswersArray.push(formResults[value]);
      }
    });

    return buildAnswersArray;
  };

  const handleNextBtnClicked = async () => {
    if (startScreen && !data?.length) {
      // No questions submit form.
      await handleBtnSubmit();
      setStartScreen(false);
      return;
    }

    const isValid = await ValidateListItem(
      currentStep,
      data,
      formik,
      getCurrentFormName
    );

    if (!isValid) {
      return;
    }

    if (startScreen) {
      setStartScreen(false);
      setEndScreen(false);
      setTransistionAnimation('right-to-left');
      if (endScreenNavModuleIndex !== undefined) {
        getLocalStorage().setItem(
          `brij-form-${slug}-${currentFormId}-start-screen-shown`,
          true
        );
        route.push(`/c/${id}/form/${currentFormId}/step/${currentStep}`);
        getLocalStorage().setItem(
          `brij-form-${slug}-${currentFormId}-current-step`,
          currentStep
        );
      } else {
        if (endScreenNavModuleIndex) {
          getLocalStorage().setItem(
            `brij-form-${slug}-${currentFormId}-start-screen-shown`,
            true
          );
        } else {
          getLocalStorage().setItem(
            `brij-form-${slug}-${currentFormId}-start-screen-shown`,
            true
          );
        }
        let stepToNavTo = currentStep;
        if (stepToNavTo > 1) {
          updateCurrentStep(1);
          stepToNavTo = 1;
        }

        updateCurrentStep(stepToNavTo);

        getLocalStorage().setItem(
          `brij-form-${slug}-${currentFormId}-current-step`,
          currentStep
        );
        route.push(`/c/${id}/form/${currentFormId}/step/${stepToNavTo}`);
        return;
      }
    }

    if (currentStep !== data?.length && !startScreen) {
      setTransistionAnimation('right-to-left');
      setTimeout(() => {
        route.push(`/c/${id}/form/${currentFormId}/step/${currentStep + 1}`);
        setEndScreen(false);
      }, 0);
      updateCurrentStep(currentStep + 1);
    }

    if ((window as any).mode === 'preview') {
      let brijFormRegistrationIndex = getLocalStorage().getItem(
        `brij-form-${slug}-${currentFormId}`
      );
      if (
        brijFormRegistrationIndex !== null &&
        endScreenNavModuleIndex !== undefined
      ) {
        getLocalStorage().setItem(
          `brij-form-${slug}-${currentFormId}`,
          JSON.stringify(formik.current?.values)
        );
      } else {
        getLocalStorage().setItem(
          `brij-form-${slug}-${currentFormId}`,
          JSON.stringify(formik.current?.values)
        );
      }
    }
  };

  const handleBackBtnClicked = () => {
    if (currentStep !== 1) {
      setTransistionAnimation('left-to-right');
      setTimeout(() => {
        route.push(`/c/${id}/form/${currentFormId}/step/${currentStep - 1}`);
        setEndScreen(false);
      }, 0);
      updateCurrentStep(currentStep - 1);
      return;
    }

    if (
      currentStep === 1 &&
      formModuleData.startScreenContent &&
      formModuleData.startScreenContent !== ' '
    ) {
      setTransistionAnimation('left-to-right');
      route.push(`/c/${id}/form/${currentFormId}/start`);
      setEndScreen(false);
      getLocalStorage().removeItem(
        `brij-form-${slug}-${currentFormId}-current-step`
      );
      getLocalStorage().setItem(
        `brij-form-${slug}-${currentFormId}-start-screen-shown`,
        false
      );
      setStartScreen(true);
      return;
    }
  };

  const getCurrentFormName = (currentStep: number) => {
    if (data?.length) {
      if (currentStep > data?.length)
        return `${data[data?.length - 1]?.type}${data?.length - 1}`;
      return `${data[currentStep - 1]?.type}${currentStep}`;
    } else return 'no-questions';
  };

  useEffect(() => {
    if (details?.product?.registeredToCurrentUser) return;

    if (
      getLocalStorage().getItem(`brij-form-${slug}-${currentFormId}-filled`)
    ) {
      if (onSubmit) {
        onSubmit();
      }
    }
  }, [currentFormId, id, details?.product?.registeredToCurrentUser]);

  const updateFormField = async (name: string, value: any) => {
    if (formik.current) {
      await formik.current.setFieldValue(name, value);

      validateCurrentStep(currentStep, data, formik, getCurrentFormName);

      getLocalStorage().setItem(
        `brij-form-${slug}-${currentFormId}`,
        JSON.stringify(formik.current?.values)
      );
      return;
    }
  };

  const getCornerStyle = () => {
    switch (buttonStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-full';
    }
  };

  const renderComponentRoutes = (stepId: string, formik: FormikProps<any>) => {
    const stepIdInt = parseInt(stepId, 10);
    const moduleStepInt = stepIdInt - 1;
    const currentFormModule = data[moduleStepInt];

    if (!currentFormModule) {
      return null;
    }

    switch (currentFormModule.type) {
      case QuestionType.DROP_DOWN:
        return (
          <FormDropDown
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            formData={currentFormModule}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.MULTIPLE_CHOICE:
        return (
          <FormMultipleChoice
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            formData={currentFormModule}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.SEARCHABLE_DROP_DOWN:
        return (
          <FormSearchableDropDown
            name={getCurrentFormName(stepIdInt)}
            index={moduleStepInt}
            formId={currentFormId}
            formRef={formik}
            formData={currentFormModule}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.TEXT:
        return (
          <FormTextField
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            formData={currentFormModule}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.CHECKBOX:
        return (
          <FormCheckBox
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.REVIEW_RATING:
        return (
          <FormUserReview
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            formData={currentFormModule}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.FILE:
        return (
          <FormFileUpload
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.DATE_SELECTOR:
        return (
          <FormDatePicker
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.PHONE_NUMBER:
        return (
          <FormPhoneNumber
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.EMAIL:
        return (
          <FormEmail
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.ADDRESS:
        return (
          <FormAddress
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.NUMBER:
        return (
          <FormNumber
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.RETAIL_CHANNEL:
        return (
          <FormRetailChannel
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.NAME:
        return (
          <FormName
            formData={currentFormModule}
            name={getCurrentFormName(stepIdInt)}
            formRef={formik}
            updateFormField={updateFormField}
          />
        );
      case QuestionType.CONTENT_BLOCK:
        return <FormContentBlock formData={currentFormModule} />;
    }
  };

  if (!data) {
    return null;
  }

  return (
    formModuleData &&
    details && (
      <Formik
        enableReinitialize={true}
        innerRef={formik}
        initialValues={initNameObjectYup}
        validationSchema={validationYup}
        validateOnChange={true}
        onSubmit={() => {}}
      >
        {(formikProps) => (
          <div
            className='relative flex w-full overflow-auto'
            style={{
              height: desktopView && height ? `${height - 64}px` : '100%',
              maxHeight: desktopView && height ? '720px' : '100%',
            }}
          >
            <ModuleWrapper
              transparent
              drawerTitle={
                desktopView &&
                openAutoDeployModal &&
                location.pathname.includes(`/c/${slug}/form/`)
                  ? null
                  : formTitle
              }
            >
              <div className='relative w-full h-full flex flex-col items-center justify-between'>
                {currentStep &&
                  !startScreen &&
                  !endScreen &&
                  !getLocalStorage().getItem(
                    `brij-form-${slug}-${currentFormId}-complete`
                  ) && (
                    <ProgressBar
                      steps={data?.length}
                      currentStep={currentStep}
                    />
                  )}
                <div
                  className={`relative w-full h-full overflow-auto overflow-x-hidden ${
                    loadSwipeLottieStartScreen ? 'pb-[3.75rem]' : 'pb-4'
                  }`}
                >
                  <TransitionGroup
                    className='relative w-full h-full overflow-auto overflow-x-hidden'
                    exit={false}
                  >
                    <CSSTransition
                      timeout={200}
                      key={location.key}
                      classNames={
                        transistionAnimation === 'right-to-left'
                          ? 'slide'
                          : 'inverseslide'
                      }
                    >
                      <Switch location={location}>
                        <FormProtectedRoute
                          exact={true}
                          totalSteps={data?.length}
                          formId={currentFormId}
                          path={`/c/${id}/form/${currentFormId}/step/:stepId`}
                          render={({
                            match: {
                              params: { stepId },
                            },
                          }) => (
                            <FromStepWrapper>
                              {Object.keys(formikProps.values)?.length ? (
                                renderComponentRoutes(stepId ?? '', formikProps)
                              ) : (
                                <div className='w-full h-full flex items-center justify-center'>
                                  <LoadingIndicator />
                                </div>
                              )}
                            </FromStepWrapper>
                          )}
                        />
                        <Route
                          path={`/c/${id}/form/${currentFormId}/complete`}
                          render={() => (
                            <FormCompletionPage
                              id={id}
                              formData={formModuleData}
                            />
                          )}
                        />
                        <Route
                          path={`/c/${id}/form/${currentFormId}/start`}
                          render={() => (
                            <FormStartPage formData={formModuleData} />
                          )}
                        />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </div>
                <div className='w-full flex flex-col items-end'>
                  <div className='absolute bottom-10 left-0 right-0 mx-auto'>
                    {startScreen && loadSwipeLottieStartScreen && (
                      <Lottie
                        speed={1}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: formSwipeAnimation,
                        }}
                        height={150}
                        width={150}
                      />
                    )}
                  </div>
                  {!getLocalStorage().getItem(
                    `brij-form-${slug}-${currentFormId}-complete`
                  ) &&
                    !endScreen && (
                      <div className='w-full flex items-center justify-between px-0.5 duration-300'>
                        <div
                          className={`flex items-center justify-center duration-300 ${
                            endScreen ||
                            startScreen ||
                            isFormSubmitting ||
                            (currentStep === 1 &&
                              (formModuleData.startScreenContent === ' ' ||
                                !formModuleData.startScreenContent))
                              ? 'w-0 m-0'
                              : 'w-14 mr-3'
                          }`}
                        >
                          {endScreen ||
                          startScreen ||
                          isFormSubmitting ||
                          (currentStep === 1 &&
                            (formModuleData.startScreenContent === ' ' ||
                              !formModuleData.startScreenContent)) ? null : (
                            <button
                              type='button'
                              onClick={() => handleBackBtnClicked()}
                              className={`w-iconButton h-iconButton flex items-center justify-center shadow-formBackButton disabled:opacity-30 bg-white ${getCornerStyle()}`}
                            >
                              <BackIcon fill='#262626' />
                            </button>
                          )}
                        </div>
                        {currentStep === data?.length &&
                        !startScreen &&
                        !endScreen ? (
                          <Button
                            title={
                              endScreenNavModuleIndex === undefined
                                ? t('submit')
                                : t('next')
                            }
                            transition
                            variant='dark'
                            styles='flex-1'
                            loading={isFormSubmitting}
                            onClick={() => handleBtnSubmit()}
                            disabled={!formikProps?.isValid || nextDisabled}
                          />
                        ) : (
                          <Button
                            transition
                            variant='dark'
                            styles='flex-1'
                            title={startScreen ? t('start') : t('next')}
                            onClick={() => handleNextBtnClicked()}
                            disabled={
                              !!formikProps?.errors[
                                getCurrentFormName(currentStep)
                              ] && !startScreen
                            }
                          />
                        )}
                      </div>
                    )}
                </div>
              </div>
            </ModuleWrapper>
          </div>
        )}
      </Formik>
    )
  );
};

export default FormDrawer;
