// @ts-ignore
import * as heicConvert from 'heic-convert/browser';
import { fetchWrapper } from './previewMockCalls';
import envVars from 'env-vars';

export const uploadToS3 = async (file: File) => {
  try {
    const presigned = await getPresignedPost(file);
    const url = await uploadFile(file, presigned);
    return url;
  } catch (error) {
    throw error;
  }
};

export async function getPresignedPost(file: File) {
  const API_URL =
    envVars.REACT_APP_STAND_ALONE_MODE === 'false'
      ? envVars.REACT_APP_API_URL
      : '';

  try {
    const response = await fetchWrapper(`${API_URL}/app_api/upload`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        filename: file.name.toLowerCase().replace(/\.heic/i, '.jpeg'),
      }),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
}

export async function uploadFile(file: File, presignedPost: any) {
  const formData = new FormData();
  const fileBuffer = await file.arrayBuffer();
  Object.entries(presignedPost.fields).forEach(
    ([key, value]: [string, any]) => {
      formData.append(key, value);
    }
  );
  if (file.type === 'image/heic') {
    const buffer = new Uint8Array(await file.arrayBuffer());
    const convertedBuffer = await heicConvert({
      buffer: buffer,
      format: 'JPEG',
      quality: 0.8,
    });
    formData.append('Content-Type', 'image/jpeg');
    formData.append(
      'file',
      new Blob([convertedBuffer], { type: 'image/jpeg' })
    );
  } else {
    formData.append('Content-Type', file.type);
    formData.append('file', new Blob([fileBuffer], { type: file.type }));
  }

  try {
    const res = await fetch(presignedPost.url, {
      method: 'POST',
      body: formData,
    });

    const location: string = res.headers.get('Location') || '';

    return decodeURIComponent(location);
  } catch (error) {
    return '';
  }
}
