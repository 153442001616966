import React, { useMemo } from 'react';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as TableCheckmark } from 'assets/icons/svg/table-checkmark.svg';

type TableProps = {
  headers: string[];
  tableData: string[];
};

const CoverageTable: React.FC<TableProps> = ({ headers, tableData }) => {
  const { brandTheme } = useThemeContext();

  const table = useMemo(() => {
    return (
      <table className='w-[calc(100%-1rem)] mx-auto border-collapse'>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <td
                key={header}
                style={
                  header !== 'mulberry'
                    ? {
                        boxShadow: `0px 1px 0px 0px ${brandTheme}`,
                      }
                    : {
                        boxShadow: `0px 0px 0px 1px ${brandTheme}`,
                      }
                }
                className={`
                  py-2 shadow-tableCell text-center
                  ${
                    header === 'mulberry'
                      ? `rounded-tl-[5px] rounded-tr-[5px]`
                      : ''
                  }
                `}
              >
                <p
                  className={`${
                    header === 'mulberry' ? 'text-primary' : 'text-black'
                  } ${
                    index === 0
                      ? 'p-0 text-base text-left font-semibold'
                      : 'px-2 text-xs text-center font-normal'
                  }`}
                >
                  {header}
                </p>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((title: string, index: number) => (
            <tr key={title}>
              <td
                key={title}
                className='w-[60%] py-2 pr-4'
                style={
                  index !== tableData.length - 1
                    ? {
                        boxShadow: '0px 1px 0px 0px #E7EAEB',
                      }
                    : {}
                }
              >
                <p className='text-xs'>{title}</p>
              </td>
              <td
                className={`${
                  index === tableData.length - 1
                    ? 'rounded-bl-[5px] rounded-br-[5px]'
                    : ''
                }`}
                style={
                  index !== tableData.length - 1
                    ? {
                        boxShadow: `0px 1px 0px #E7EAEB, -1px 0px 0px 0px ${brandTheme}, 1px 0px 0px 0px ${brandTheme}`,
                      }
                    : {
                        boxShadow: `0px 1px 0px ${brandTheme}, -1px 1px 1px ${brandTheme}, 1px 1px 0px ${brandTheme}`,
                      }
                }
              >
                <TableCheckmark fill={brandTheme} className='mx-auto' />
              </td>
              <td
                style={
                  index !== tableData.length - 1
                    ? { boxShadow: '0px 1px 0px 0px #e7eaeb' }
                    : {}
                }
              >
                {index === 0 ? (
                  <TableCheckmark fill={brandTheme} className='mx-auto' />
                ) : (
                  <div className='w-[9px] h-0.5 mx-auto rounded-2xl bg-dragbar' />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [headers, tableData, brandTheme]);

  return table;
};

export default CoverageTable;
