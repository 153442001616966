import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps {
  totalSteps: number;
  formId: string;
}
export interface FormMatchParams {
  id: string;
  stepId: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps & RouteProps> = (props) => {
  // Since this runs on route exit let's verify the url has /form in it.
  const url = window.location.pathname;
  if (!~url.indexOf('/form')) return <Route {...props} />;

  const stepId = url.split('/').pop();
  const stepIdInt = parseInt(stepId ?? '1', 10);
  const checkForFormModule = () => {
    if (stepId === 'start' || stepId === 'complete') {
      return true;
    }
    if (stepId) {
      if (!stepIdInt) return false;
      if (stepIdInt > props.totalSteps) return false;
      else return true;
    }
  };

  if (checkForFormModule()) return <Route {...props} />;

  if (stepIdInt > props.totalSteps) {
    // if you go to a step in normal form that's longer than autocomplete go to last step.
    const urlNoForm = window.location.pathname.split('/form').shift();
    return (
      <Redirect
        to={`${urlNoForm}/form/${props.formId}/step/${props.totalSteps}`}
      />
    );
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
