import React from 'react';
import { FormDetailModel } from 'types/FormTypes';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import { useIframeModifier } from 'hooks/useIframeModifier';
import FormImage from './FormImage';

type FormContentBlockProps = {
  formData: FormDetailModel;
};

const FormContentBlock: React.FC<FormContentBlockProps> = ({ formData }) => {
  return (
    <>
      <div className='w-full h-max flex flex-col justify-start gap-3'>
        {formData.image && (
          <FormImage image={formData.image} alt='form-question-image' />
        )}
        {formData.text && (
          <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
            {formData.text}
          </h5>
        )}
        {formData.subText && (
          <p className='my-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
            {formData.subText}
          </p>
        )}
        <HtmlWrapper html={useIframeModifier(formData.config?.content || '')} />
      </div>
    </>
  );
};

export default FormContentBlock;
