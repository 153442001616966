import React, { useEffect } from 'react';
import { ModuleInfoType } from 'types/ProductDetailsType';
import { useFormContext } from 'context/FormDrawerContext/FormDrawerContext';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';

type FormStartPageProps = {
  formData: ModuleInfoType;
};

export interface FormMatchParams {
  id: string;
  stepId: string;
}

const FormStartPage: React.FC<FormStartPageProps> = ({ formData }) => {
  const { setStartScreen, setCurrentStep } = useFormContext();

  useEffect(() => {
    setStartScreen(true);
    setCurrentStep(1);
  }, [setStartScreen]);

  return <HtmlWrapper html={formData.startScreenContent} hideAnimation />;
};

export default FormStartPage;
