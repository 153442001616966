import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';

type TextareaProps = {
  name: string;
  value: string;
  disabled?: boolean;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const TextArea = ({
  name,
  value,
  disabled,
  placeholder,
  onChange,
}: TextareaProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { inputStyle } = useThemeContext();

  useEffect(() => {
    if (value) setIsFocused(true);
    else setIsFocused(false);
  }, [value]);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = '48px';
      const scrollHeight: number = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-[26px] rounded-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px] rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-[26px] rounded-[26px]';
    }
  };

  return (
    <div
      className={`
        relative w-full min-h-textareaContainer max-h-textareaContainer flex items-start pt-[13px] px-6 border-2 border-solid border-input transition-all ease-linear duration-300 
        [&_textarea]:z-20 [&_textarea]:w-full [&_textarea]:border-0 [&_textarea]:bg-transparent 
        [&_textarea]:active:bg-transparent [&_textarea]:active:outline-none 
        [&_textarea]:hover:bg-transparent [&_textarea]:hover:outline-none  
        ${isFocused ? 'bg-transparent' : 'bg-lightgray'} 
        ${disabled && 'opacity-70'} ${getCornerStyle()}
      `}
    >
      <span
        className={`z-50 absolute text-muted transition-all duration-300 pointer-events-none ${
          isFocused
            ? 'text-xxxs px-0.5 bg-white -translate-x-0.5 -translate-y-5 opacity-100'
            : 'text-[0.9rem] bg-transparent translate-x-0 translate-y-0 opacity-80'
        }`}
      >
        {placeholder}
      </span>
      <textarea
        rows={1}
        name={name}
        value={value}
        ref={textAreaRef}
        onChange={onChange}
        disabled={disabled}
        onFocus={() => setIsFocused(true)}
        onBlur={() => !value && setIsFocused(false)}
        className='min-h-textarea max-h-textarea resize-none disabled:pointer-events-none disabled:text-disbaledInput'
      />
    </div>
  );
};

export default TextArea;
