import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

export const getId = (storage: Storage, key: string) => {
  let id = Cookies.get(key) || storage.getItem(key);

  if (id) {
    return id;
  }

  const newId = uuidv4();
  storage.setItem(key, newId);
  return newId;
};
