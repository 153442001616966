import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import { ReactComponent as FileIllustration } from 'assets/icons/svg/file-illustration.svg';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { isDesktop, isAndroid, isIOS } from 'react-device-detect';
import { useGlobal } from 'context/global/GlobalContext';
import { useTranslation } from 'react-i18next';
import Button from 'components/atomic/Button';
import envVars from 'env-vars';
import {
  LinkModuleType,
  VideoModuleType,
  ModuleThemeType,
  DocumentModuleType,
  PossibleModulesType,
} from 'types/ProductDetailsType';

const showCustomFullScreen = (moduleData: VideoModuleType) =>
  (window as any).mode === 'preview' ||
  isAndroid ||
  moduleData.youtubeEmbed ||
  isIOS;

export type ModuleButtonType = {
  className?: string;
  title: string | undefined;
  onClick: () => void;
  isHighlight: boolean;
  registrationRequired: boolean;
  moduleType: string;
  autoDeploy?: boolean;
  moduleData?: VideoModuleType | LinkModuleType | DocumentModuleType | null;
  moduleTheme?: ModuleThemeType | undefined;
};

type AdditionalButtonTypes = 'moreButton' | 'callToAction';

type ButtonFactoryProps = {
  buttonData?: Partial<ModuleButtonType>;
  type: AdditionalButtonTypes | PossibleModulesType | string;
  setVideoSource?: Dispatch<SetStateAction<VideoModuleType | null>>;
  currentPage?: number | null;
  autoDeployExists?: boolean;
  variant?: 'light' | 'dark';
  onClick: () => void;
  index?: number;
};

const ButtonFactory: React.FC<ButtonFactoryProps> = ({
  type,
  index,
  variant,
  onClick,
  buttonData,
  currentPage,
  setVideoSource,
  autoDeployExists,
}) => {
  const [renderOptimizedMedia, setRenderOptimizedMedia] =
    useState<boolean>(true);
  const {
    desktopView,
    authFetched,
    productDetails,
    setIsVideoPlaying,
    registeringProduct,
    autoDeployTriggered,
    setAutoDeployTriggered,
  } = useGlobal();

  const handle = useFullScreenHandle();
  const videoRef = useRef<HTMLVideoElement>(null);
  const moreButtonRef = useRef<HTMLButtonElement>(null);
  const autoDeployButtonRef = useRef<HTMLButtonElement>(null);
  const { brandTheme, secondaryBrandColor } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.bottomDrawer',
  });

  useEffect(() => {
    if (
      !authFetched ||
      registeringProduct ||
      autoDeployTriggered ||
      productDetails?.product.registeredToCurrentUser
    )
      return;
    if (autoDeployButtonRef?.current) {
      if (moreButtonRef?.current) moreButtonRef?.current?.click();
      setTimeout(() => autoDeployButtonRef?.current?.click(), 300);
      setAutoDeployTriggered(true);
    }
  }, [
    buttonData,
    authFetched,
    desktopView,
    autoDeployExists,
    registeringProduct,
    autoDeployButtonRef,
    autoDeployTriggered,
    setAutoDeployTriggered,
    productDetails?.product.registeredToCurrentUser,
  ]);

  const getIconFillColor = (isHighlight: boolean | undefined) => {
    if (desktopView)
      return currentPage === index ? brandTheme : secondaryBrandColor;
    else return isHighlight ? secondaryBrandColor : brandTheme;
  };

  const getOptimizedVideoLink = (source: string) => {
    let optimizedVideoLink: string = source;
    if (source) {
      const hostUrl: string = envVars.REACT_APP_HOST_URL_IMAGE || '';
      const fileId: string | undefined = source
        .split('?')
        .shift()
        ?.split('/')
        .pop()
        ?.split('.')
        .shift();
      optimizedVideoLink = `${hostUrl}/optimized-videos/${fileId}/video-vp9-720p.webm`;
    }
    return optimizedVideoLink;
  };

  const renderVideoElement = (moduleData: VideoModuleType) => {
    if (showCustomFullScreen(moduleData)) {
      return null;
    } else if (isDesktop) {
      return (
        <div
          className={`h-full flex items-center justify-center ${
            handle?.active ? 'w-full' : 'w-0'
          }`}
        >
          <FullScreen
            handle={handle}
            onChange={(active) => {
              active ? videoRef?.current?.play() : videoRef?.current?.pause();
            }}
          >
            <video
              src={
                renderOptimizedMedia
                  ? getOptimizedVideoLink(moduleData.path)
                  : moduleData.path
              }
              ref={videoRef}
              controls
              width='100%'
              height='100%'
              preload='metadata'
              className='h-screen'
              playsInline={false}
              onPlay={() => setIsVideoPlaying(true)}
              onError={() => setRenderOptimizedMedia(false)}
              onEnded={() => {
                handle.active && handle.exit();
              }}
            />
          </FullScreen>
        </div>
      );
    } else {
      return (
        <div className='w-0 h-0'>
          <video
            src={
              renderOptimizedMedia
                ? getOptimizedVideoLink(moduleData.path)
                : moduleData.path
            }
            ref={videoRef}
            controls
            height={0}
            width='100%'
            preload='metadata'
            playsInline={false}
            onError={() => setRenderOptimizedMedia(false)}
            onEnded={() => {
              handle.active && handle.exit();
            }}
          />
        </div>
      );
    }
  };

  const renderModuleButton = (
    type: AdditionalButtonTypes | PossibleModulesType | string,
    onClick: () => void,
    variant?: 'light' | 'dark',
    buttonData?: Partial<ModuleButtonType>
  ) => {
    switch (type) {
      case 'callToAction':
        return (
          <Button
            className={buttonData?.className}
            variant='dark'
            key='call-to-action'
            title={t('callToActionButton')}
            forwardedRef={buttonData?.autoDeploy ? autoDeployButtonRef : null}
          />
        );
      case 'moreButton':
        return (
          <Button
            className={buttonData?.className}
            forwardedRef={autoDeployExists ? moreButtonRef : null}
            color={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.textColor
                : ''
            }
            background={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.backgroundColor
                : ''
            }
            border={
              buttonData?.moduleTheme?.enabled
                ? `1px solid ${buttonData.moduleTheme.borderColor}`
                : ''
            }
            title={buttonData?.title || t('moreButton')}
            onClick={onClick}
            variant={
              buttonData?.moduleTheme?.enabled ? 'custom' : variant || 'light'
            }
          />
        );
      case 'DOCUMENT_MODULE':
        return (
          <Button
            className={buttonData?.className}
            forwardedRef={buttonData?.autoDeploy ? autoDeployButtonRef : null}
            color={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.textColor
                : ''
            }
            background={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.backgroundColor
                : ''
            }
            border={
              buttonData?.moduleTheme?.enabled
                ? `1px solid ${buttonData.moduleTheme.borderColor}`
                : ''
            }
            variant={
              desktopView
                ? `${
                    currentPage === index
                      ? 'desktopLight'
                      : buttonData?.moduleTheme?.enabled
                      ? 'desktopCustomDark'
                      : 'desktopDark'
                  }`
                : buttonData?.moduleTheme?.enabled
                ? 'custom'
                : variant || 'light'
            }
            onClick={onClick}
            title={buttonData?.title}
            styles={`${desktopView ? '!pr-9' : '!px-12'}`}
            icon={
              <FileIllustration
                width={24}
                className={`absolute ${desktopView ? 'right-3' : 'right-5'}`}
                fill={
                  buttonData?.moduleTheme?.enabled
                    ? buttonData.moduleTheme.textColor
                    : getIconFillColor(buttonData?.isHighlight)
                }
              />
            }
          />
        );
      case 'LINK_MODULE':
        return (
          <>
            <Button
              className={buttonData?.className}
              forwardedRef={buttonData?.autoDeploy ? autoDeployButtonRef : null}
              color={
                buttonData?.moduleTheme?.enabled
                  ? buttonData?.moduleTheme.textColor
                  : ''
              }
              background={
                buttonData?.moduleTheme?.enabled
                  ? buttonData?.moduleTheme.backgroundColor
                  : ''
              }
              border={
                buttonData?.moduleTheme?.enabled
                  ? `1px solid ${buttonData.moduleTheme.borderColor}`
                  : ''
              }
              variant={
                desktopView
                  ? `${
                      currentPage === index
                        ? 'desktopLight'
                        : buttonData?.moduleTheme?.enabled
                        ? 'desktopCustomDark'
                        : 'desktopDark'
                    }`
                  : buttonData?.moduleTheme?.enabled
                  ? 'custom'
                  : variant || 'light'
              }
              onClick={onClick}
              title={buttonData?.title}
              styles={`${desktopView ? '!pr-9' : '!px-12'}`}
              icon={
                <ExternalLink
                  width={24}
                  className={`absolute ${desktopView ? 'right-3' : 'right-5'}`}
                  fill={
                    buttonData?.moduleTheme?.enabled
                      ? buttonData.moduleTheme.textColor
                      : getIconFillColor(buttonData?.isHighlight)
                  }
                />
              }
            />
          </>
        );
      case 'VIDEO_MODULE': {
        return (
          <Button
            forwardedRef={buttonData?.autoDeploy ? autoDeployButtonRef : null}
            color={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.textColor
                : ''
            }
            background={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.backgroundColor
                : ''
            }
            border={
              buttonData?.moduleTheme?.enabled
                ? `1px solid ${buttonData.moduleTheme.borderColor}`
                : ''
            }
            className={buttonData?.className}
            variant={
              desktopView
                ? `${
                    currentPage === index
                      ? 'desktopLight'
                      : buttonData?.moduleTheme?.enabled
                      ? 'desktopCustomDark'
                      : 'desktopDark'
                  }`
                : buttonData?.moduleTheme?.enabled
                ? 'custom'
                : variant || 'light'
            }
            onClick={() => {
              if (desktopView) onClick();
              else if (
                !buttonData?.registrationRequired &&
                setVideoSource &&
                !handle.active &&
                !desktopView
              ) {
                isDesktop && handle.enter();
                showCustomFullScreen(
                  buttonData?.moduleData as VideoModuleType
                ) &&
                  setVideoSource(
                    (buttonData?.moduleData as VideoModuleType) || null
                  );
                !showCustomFullScreen(
                  buttonData?.moduleData as VideoModuleType
                ) && videoRef.current?.play();
                setIsVideoPlaying(true);
              } else if (buttonData?.registrationRequired) onClick();
            }}
            title={buttonData?.title}
            content={
              !buttonData?.registrationRequired &&
              renderVideoElement(buttonData?.moduleData as VideoModuleType)
            }
          />
        );
      }
      default:
        return (
          <Button
            className={buttonData?.className}
            forwardedRef={buttonData?.autoDeploy ? autoDeployButtonRef : null}
            title={buttonData?.title}
            color={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.textColor
                : ''
            }
            background={
              buttonData?.moduleTheme?.enabled
                ? buttonData?.moduleTheme.backgroundColor
                : ''
            }
            border={
              buttonData?.moduleTheme?.enabled
                ? `1px solid ${buttonData.moduleTheme.borderColor}`
                : ''
            }
            onClick={onClick}
            variant={
              desktopView
                ? `${
                    currentPage === index
                      ? 'desktopLight'
                      : buttonData?.moduleTheme?.enabled
                      ? 'desktopCustomDark'
                      : 'desktopDark'
                  }`
                : buttonData?.moduleTheme?.enabled
                ? 'custom'
                : variant || 'light'
            }
          />
        );
    }
  };

  return renderModuleButton(type, onClick, variant, buttonData);
};

export default ButtonFactory;
