export enum PossibleDigitalModulesType {
  EXPERIENCE = 'EXPERIENCE',
  WEBSITE_LINK = 'WEBSITE_LINK',
  EXPERIENCE_INDEX = 'EXPERIENCE_INDEX',
  CUSTOM_CONTENT = 'CUSTOM_CONTENT',
}

export type ProductSearchResultsType = {
  id: string;
  image: string;
  name: string;
  slug: string;
};

export type DigitalModuleInfoType = {
  type: string;
  moduleData:
    | ExperienceModuleData
    | WebsiteModuleData
    | ExperienceIndexModuleData
    | CustomModuleData;
};

export interface DigitalModuleData {
  text: string;
  backgroundColor: string;
  color: string;
}

export interface ExperienceModuleData extends DigitalModuleData {
  productId: string;
  slug: string;
  image: string;
  hideImage: boolean;
  name: string;
}

export interface WebsiteModuleData extends DigitalModuleData {
  linkURL: string;
  image: string | null;
}

export interface ExperienceIndexModuleData
  extends Omit<DigitalModuleData, 'text'> {
  productIds: string[];
  allSelected: boolean;
  productArray: ProductSearchResultsType[];
}

export interface CustomModuleData extends DigitalModuleData {
  customContent: string;
  showBackground: boolean;
}
