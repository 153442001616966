import dayjs from 'dayjs';

export const sendToBD = (type: string, data?: any) => {
  (window as any).parent.postMessage(
    {
      type,
      data,
    },
    '*'
  );
};

const fetchFromBD = (dataPrefix: string, data?: any) => {
  const responseId = Math.random().toString(36).substring(7);

  return new Promise((resolve) => {
    (window as any).parent.postMessage(
      {
        type: 'fetch',
        data: {
          payload: data,
          dataPrefix,
          responseId,
        },
      },
      '*'
    );

    const waitForData = (event: any) => {
      if (event?.data?.type === responseId) {
        window.removeEventListener('message', waitForData);
        resolve(event?.data?.data);
      }
    };

    window.addEventListener('message', waitForData);
  });
};

// @ts-ignore
export const fetchWrapper = (...data) => {
  // @ts-ignore
  return fetch(...data);
};

const previewMockCalls = (endpoint: string, data: any): Promise<Response> => {
  return new Promise((resolve, reject) => {
    switch (endpoint) {
      case `${window.location.protocol}//${window.location.host}/app_api/form/sync_files_to_S3`:
        resolve(
          new Response(
            JSON.stringify({
              url: `https://picsum.photos/200/300?date=${new Date().getTime()}`,
            })
          )
        );
        break;
      case `${window.location.protocol}//${window.location.host}/app_api/user/update_receipt`:
        fetchFromBD('user/update_receipt', data).then((data: any) => {
          resolve(data);
        });
        break;
      case 'auth/verify-captcha':
        resolve({
          // @ts-ignore
          challenge_ts: dayjs().format('YYYY-MM-DDTHH:MM:ss'),
          hostname: 'localhost',
          score: 0.9,
        });
        break;
      case 'auth/login-token':
        fetchFromBD('auth/login-token', data).then((data: any) => {
          resolve(data);
        });
        break;
      case 'products/register/1234': {
        fetchFromBD('products/register/1234', data).then((data: any) => {
          resolve(data);
        });
        break;
      }
      case '/auth/start_registration': {
        fetchFromBD('/auth/start_registration', data).then((data: any) => {
          resolve(data);
        });
        break;
      }
      case 'auth':
        fetchFromBD('auth').then((data: any) => {
          resolve(data);
        });
        break;
      default:
        if (endpoint.startsWith('products/1234')) {
          fetchFromBD('products/1234').then((data: any) => {
            resolve(data);
          });
          return;
        } else if (endpoint.startsWith('ab899')) {
          fetchFromBD(endpoint).then((data: any) => {
            if (data === false) {
              reject({ status: 404 });
            } else {
              resolve(data);
            }
          });
        } else {
          // @ts-ignore
          resolve(true);
        }
    }
  });
};

export default previewMockCalls;
