import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { FormDetailModel } from 'types/FormTypes';

export type FormContextProps = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  totalSteps: number;
  setTotalSteps: Dispatch<SetStateAction<number>>;
  nextDisabled: boolean;
  setNextDisabled: Dispatch<SetStateAction<boolean>>;
  backDisabled: boolean;
  setBackDisabled: Dispatch<SetStateAction<boolean>>;
  formData: FormDetailModel[];
  setFormData: Dispatch<SetStateAction<FormDetailModel[]>>;
  startScreen: boolean;
  setStartScreen: Dispatch<SetStateAction<boolean>>;
  endScreen: boolean;
  setEndScreen: Dispatch<SetStateAction<boolean>>;
  fileUploading: boolean;
  setFileUploading: Dispatch<SetStateAction<boolean>>;
};

export const FormContext = createContext<FormContextProps>({
  currentStep: 1,
  setCurrentStep: () => {},
  totalSteps: 0,
  setTotalSteps: () => {},
  nextDisabled: false,
  setNextDisabled: () => {},
  backDisabled: false,
  setBackDisabled: () => {},
  formData: [],
  setFormData: () => {},
  startScreen: false,
  setStartScreen: () => {},
  endScreen: false,
  setEndScreen: () => {},
  fileUploading: false,
  setFileUploading: () => {},
});

export const useFormContext = () => useContext(FormContext);
