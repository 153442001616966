enum ScreenType {
  NONE = 'none',
  AUTH = 'auth',
  REGISTER = 'register',
  FORM = 'form',
  COMPLETE_PROFILE = 'complete_profile',
  CURRENT_MODULE = 'current_module',
  PURCHASE_DETAILS = 'purchase_details',
  START_REGISTRATION = 'start_registration',
  REGISTER_AGAIN = 'register_again',
  MULTIPLE_REGISTRATION = 'multiple_registration',
  MAGIC_ACTION_AUTH = 'magic_action_auth',
}

export default ScreenType;
