import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { IFileFrom } from 'types/FileType';
import { uploadToS3 } from 'utils/uploadToS3';
import { useTranslation } from 'react-i18next';
import { ReceiptType } from 'types/ReceiptType';
import { useGlobal } from 'context/global/GlobalContext';
import { showToast } from 'components/atomic/Toast/Toast';
import Input from 'components/atomic/Input';
import Button from 'components/atomic/Button';
import useElementSize from 'hooks/useElementSize';
import PhoneInput from 'components/atomic/PhoneInput';
import DatePicker from 'components/atomic/DatePicker';
import FileUpload from 'components/atomic/FileUpload';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import ConsentCheck from 'components/atomic/ConsentCheck';
import CustomSelectInput from 'components/atomic/CustomSelectInput';
import moment from 'moment';

type OptionType = {
  value: string;
  label: string;
};

type PurchaseDetailsType = {
  description?: string;
  name?: boolean;
  phoneNo?: boolean;
  callToAction?: string;
  purchaseDate?: boolean;
  receipt?: boolean;
  serialNo?: boolean;
  quantity?: boolean;
  retailChannel?: boolean;
  includeOther?: boolean;
  phoneDetail?: {
    consentText?: string;
    defaultChecked?: boolean;
    explicitConsentSms?: boolean;
    requireConsent?: boolean;
  };
  afterReceiptUpload: () => void;
  retailChannelsList?: {
    value: string;
    label: string;
  }[];
};

const PurchaseDetails: React.FC<PurchaseDetailsType> = ({
  name,
  phoneNo,
  receipt,
  quantity,
  serialNo,
  phoneDetail,
  description,
  purchaseDate,
  callToAction,
  includeOther,
  retailChannel,
  retailChannelsList,
  afterReceiptUpload,
}) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<IFileFrom[] | undefined>();
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [consentError, setConsentError] = useState<boolean>(false);
  const [smsConsent, toggleSmsConsent] = useState<boolean>(false);
  const [itemQuantity, setItemQuantity] = useState<OptionType>({
    value: '1',
    label: '1',
  });
  const [purchasePlace, setPurchasePlace] = useState<OptionType>({
    value: '',
    label: '',
  });
  const [autoApprovalRequired, setAutoApprovalRequired] =
    useState<boolean>(false);
  const [receiptVerificationFilesMap, setReceiptVerificationFilesMap] =
    useState<{
      [key: string]: string;
    }>({});
  const [receiptAutoApprovalFilesMap, setReceiptAutoApprovalFilesMap] =
    useState<{
      [key: string]: string;
    }>({});
  const atLeastOneReceiptFailedVerification = selectedFiles?.some(
    (file) => receiptVerificationFilesMap[file.fileUrl as string] === 'invalid'
  );
  const atLeastOneReceiptFailedAutoApproval = selectedFiles?.some(
    (file) => receiptAutoApprovalFilesMap[file.fileUrl as string] === 'invalid'
  );
  const { t } = useTranslation('translation', { keyPrefix: 'purchaseDetails' });
  const { setReceiptData, personalDetails, receiptData, productDetails } =
    useGlobal();
  const [formContainerRef, { height }] = useElementSize();
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (
      phoneNo &&
      phoneDetail &&
      phoneDetail.explicitConsentSms &&
      phoneDetail.defaultChecked
    )
      toggleSmsConsent(true);
  }, [phoneNo, phoneDetail]);

  useEffect(() => {
    let retailChannel: any = {
      value: '',
      label: '',
    };

    if (receiptData?.retailChannel) {
      const existingRetailChannel = retailChannelsList?.find(
        (channel) => channel.value === receiptData.retailChannel
      );

      if (existingRetailChannel) {
        retailChannel = existingRetailChannel;
      }
    } else if (receiptData?.retailChannelOther) {
      retailChannel = {
        value: 'Other',
        label: receiptData.retailChannelOther,
      };
    }

    if (personalDetails) {
      setFirstName(personalDetails?.profile?.firstName || '');
      setLastName(personalDetails?.profile?.lastName || '');
      setPhoneNumber(personalDetails?.profile?.phoneNumber || '');
    }

    const firstName =
      receiptData?.firstName || personalDetails?.profile?.firstName || '';
    const lastName =
      receiptData?.lastName || personalDetails?.profile?.lastName || '';
    let phoneNumber = personalDetails?.profile?.phoneNumber || '';

    if (receiptData) {
      if (phoneNo && receiptData.phoneNumber) {
        phoneNumber =
          receiptData.phoneNumber.phoneNumber || receiptData.phoneNumber || '';
      }

      setSerialNumber(receiptData.serialNumber || '');

      if (receiptData.purchaseDate) {
        setSelectedDate(new Date(receiptData.purchaseDate || ''));
      }

      setItemQuantity({
        value: receiptData.quantity?.toString() || '1',
        label: receiptData.quantity?.toString() || '1',
      });
      setPurchasePlace(retailChannel);
    }

    setFirstName(firstName);
    setLastName(lastName);
    setPhoneNumber(phoneNumber);
  }, [receiptData, personalDetails]);

  const saveReceiptData = () => {
    const dataToSend: Partial<ReceiptType> = {
      firstName: firstName,
      lastName: lastName,
      serialNumber: serialNumber,
      phoneNumber: phoneNumber.replace(/[\s()-]/g, ''),
      purchaseDate: moment(selectedDate).format('MM/DD/yyyy'),
      receipts: selectedFiles || [],
      quantity:
        itemQuantity.value === 'Other'
          ? parseInt(itemQuantity.label)
          : parseInt(itemQuantity.value),
      retailChannelOther:
        purchasePlace.value === 'Other' ? purchasePlace.label : '',
      retailChannel:
        purchasePlace?.value === 'Other' ? '' : purchasePlace?.value,
    };

    if (phoneNo && phoneDetail?.explicitConsentSms) {
      dataToSend.smsConsent = {
        consentType: 'explicit',
        consentFor: 'sms',
        allowed: smsConsent,
        language: phoneDetail?.consentText || '',
        defaultLanguage: phoneDetail?.consentText || '',
      };
    }

    setReceiptData(dataToSend as ReceiptType);
    afterReceiptUpload();
  };

  const changeSelectedFile = async (files: FileList) => {
    if (!files?.length) {
      setSelectedFiles(undefined);
      setFileUploading(false);
      return;
    }

    const uploaded_files = [...files];
    setFileUploading(true);
    let updatedSelectedFiles: IFileFrom[] = selectedFiles || [];
    const uploadFilePromiseArr = [];

    for (let i = 0; i < uploaded_files.length; i++) {
      // only allow images and pdf
      if (
        ![
          'application/pdf',
          'image/png',
          'image/jpg',
          'image/jpeg',
          'image/heif',
          'image/heic',
        ].includes(uploaded_files[i].type)
      ) {
        toast.error(t('invalidType'));
        setFileUploading(false);
        return;
      }

      let fileObject: IFileFrom = {
        fileName: uploaded_files[i]?.name,
        fileUrl: '',
      };

      uploadFilePromiseArr.push(async () => {
        try {
          const url = await uploadToS3(uploaded_files[i]);
          let verificationResult;
          if (!url) {
            showToast({
              message: `File "${
                uploaded_files[i].name.length > 30
                  ? `${uploaded_files[i].name.substring(0, 18)}...`
                  : uploaded_files[i].name
              }"${t('failedToUpload')}`,
              type: 'error',
            });
            return {
              ...fileObject,
              fileUrl: '',
            };
          }
          if (!!productDetails?.automaticReceiptApprovalEnabled) {
            const receiptVerificationResponse = await fetch(
              `https://${window.location.hostname}/shared-api/receipts/process`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  receiptUrl: url,
                  brandId: productDetails?.brand.id,
                  approvalMode: 'Lenient',
                  slug: (
                    window.location.pathname.match(/\/c\/[a-z0-9]+/i) as any
                  )[0]
                    .split('/')
                    .pop(),
                }),
              }
            );
            const receiptVerificationData =
              await receiptVerificationResponse.json();
            setAutoApprovalRequired(
              receiptVerificationData.autoApprovalRequired
            );
            receiptVerificationFilesMap[url] =
              receiptVerificationData.preApproved ? 'valid' : 'invalid';
            setReceiptVerificationFilesMap({
              ...receiptVerificationFilesMap,
            });
            receiptAutoApprovalFilesMap[url] =
              receiptVerificationData.autoApproved ? 'valid' : 'invalid';
            setReceiptAutoApprovalFilesMap({
              ...receiptAutoApprovalFilesMap,
            });
            verificationResult = receiptVerificationData.verificationResult;
          }
          return {
            ...fileObject,
            fileUrl: url,
            verificationResult,
          };
        } catch (e) {
          throw e;
        }
      });
    }

    if (uploadFilePromiseArr.length) {
      try {
        let results = await Promise.all(uploadFilePromiseArr.map((p) => p()));
        updatedSelectedFiles = updatedSelectedFiles.concat(
          results.filter((result) => result.fileUrl !== '')
        );
      } catch (e) {
        toast.error(t('receiptUploadError'));
        return;
      }
    }

    setSelectedFiles(updatedSelectedFiles);
    setFileUploading(false);
  };

  const handleSubmit = () => {
    if (!selectedFiles?.length && receipt) toast.error(t('noReceiptError'));
    else if (
      phoneNo &&
      phoneDetail &&
      phoneDetail.explicitConsentSms &&
      phoneDetail.requireConsent &&
      !smsConsent
    ) {
      setConsentError(true);
      showToast({
        message: t('smsConsentRequired'),
        type: 'error',
      });
    } else saveReceiptData();
  };

  const getMinimumDate = () => {
    const current = new Date();
    const lowerDateLimit = new Date(
      current.getFullYear() - 100,
      current.getMonth(),
      current.getDate()
    );
    return lowerDateLimit;
  };

  const validateForm = () => {
    if (name && !firstName) return false;
    if (name && !lastName) return false;
    if (phoneNo && !phoneNumber) return false;
    if (serialNo && !serialNumber) return false;
    if (retailChannel && !purchasePlace.label) return false;
    if (quantity && !itemQuantity.label) return false;
    if (receipt && !selectedFiles) return false;
    return true;
  };

  return (
    <div
      ref={formContainerRef}
      id='purchase-form-container'
      className='relative w-full h-full max-h-full flex flex-col pt-2 pb-4 gap-4 items-center justify-between overflow-auto'
    >
      <form
        ref={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
        className='relative w-full flex flex-1 flex-col items-center justify-start pb-20 gap-3'
      >
        {description && (
          <HtmlWrapper
            html={description}
            styles='text-sm text-dark font-medium whitespace-pre-wrap'
          />
        )}
        {name && (
          <>
            <Input
              required
              value={firstName}
              name='firstName'
              key='first-name-input'
              autoComplete='given-name'
              placeholder={
                firstName === ''
                  ? t('firstnameInputPlaceholder')
                  : t('firstnameFilledInputPlaceholder')
              }
              onChange={(event) => setFirstName(event.target.value)}
            />
            <Input
              required
              value={lastName}
              name='lastName'
              key='last-name-input'
              autoComplete='family-name'
              placeholder={
                lastName === ''
                  ? t('lastnameInputPlaceholder')
                  : t('lastnameFilledInputPlaceholder')
              }
              onChange={(event) => setLastName(event.target.value)}
            />
          </>
        )}
        {phoneNo && (
          <PhoneInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            existingPhoneNumber={personalDetails?.profile?.phoneNumber}
            placeholder={t('phoneNumberInputPlaceholder')}
            bottomOffset={98}
          />
        )}
        {phoneNo && phoneDetail && phoneDetail?.explicitConsentSms && (
          <ConsentCheck
            styles='!mb-0'
            enabled={[true]}
            errors={[consentError]}
            consentChecks={[smsConsent]}
            toggleConsentChecks={[toggleSmsConsent]}
            texts={[
              phoneDetail?.consentText?.replaceAll(
                '<a href=',
                '<a target="_blank" rel="noreferrer" href='
              ) || '',
            ]}
          />
        )}
        {purchaseDate && (
          <DatePicker
            key='purchase-date-input'
            label={
              selectedDate ? t('datepickerFilledLabel') : t('datepickerLabel')
            }
            selectedDate={selectedDate}
            errorMessage={t('invalidDate')}
            changeDate={(props) => setSelectedDate(props)}
            minDate={getMinimumDate()}
            maxDate={new Date()}
          />
        )}
        {serialNo && (
          <Input
            required
            value={serialNumber}
            name='serial-number-input'
            key='serial-number-input'
            placeholder={
              serialNumber === ''
                ? t('serialNumberInputPlaceholder')
                : t('serialNumberFilledInputPlaceholder')
            }
            onChange={(event) => setSerialNumber(event.target.value)}
          />
        )}
        {retailChannel && (
          <CustomSelectInput
            required
            type='text'
            key='retail-channel'
            name='retail-channel'
            selected={purchasePlace}
            options={retailChannelsList}
            customValueAllowed={includeOther}
            customValueLabel={includeOther ? 'Other' : ''}
            placeholder={t('retailChannelInputPlaceholder')}
            onChange={(option: OptionType) => setPurchasePlace(option)}
            scrollIntoView={{
              enabled: true,
              parentHeight: height,
              parentContainerId: 'purchase-form-container',
            }}
          />
        )}
        {quantity && (
          <CustomSelectInput
            required
            type='number'
            key='quantity-input'
            name='quantity-input'
            placeholder={t('quantitySelectorInputPlaceholder')}
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              { value: '3', label: '3' },
              { value: '4', label: '4' },
              { value: '5', label: '5' },
            ]}
            selected={itemQuantity}
            customValueAllowed
            customValueLabel={t('quantitySelectorInputOther')}
            onChange={(option: OptionType) => setItemQuantity(option)}
            scrollIntoView={{
              enabled: true,
              parentHeight: height,
              parentContainerId: 'purchase-form-container',
            }}
          />
        )}
        {receipt && (
          <>
            <FileUpload
              setSelectedFiles={setSelectedFiles}
              selectedFiles={selectedFiles?.map(
                (f) =>
                  ({
                    fileName: f.fileName,
                    fileUrl: f.fileUrl,
                    receiptVerificationStatus:
                      receiptVerificationFilesMap[f.fileUrl as string] &&
                      receiptAutoApprovalFilesMap[f.fileUrl as string],
                  } as IFileFrom)
              )}
              uploadButtonText={
                callToAction ? callToAction : t('uploadReceiptButtonText')
              }
              changeFile={changeSelectedFile}
              loading={fileUploading}
              uploadLimit={5}
              validatePdf
              multiple
            />
            {(atLeastOneReceiptFailedVerification ||
              (autoApprovalRequired &&
                atLeastOneReceiptFailedAutoApproval)) && (
              <div className='bg-[#fff4e8] px-[1rem] py-[1rem] flex flex-row gap-[1rem] rounded-xl'>
                <div className='flex-none rounded-full bg-[#ff931e] w-[2rem] h-[2rem]'>
                  <div className='w-full h-full flex justify-center items-center'>
                    <span className='font-bold text-white'>!</span>
                  </div>
                </div>
                <div className='text-xxs text-medium text-caution'>
                  <p>{t('receiptFailedError')}</p>
                </div>
              </div>
            )}
          </>
        )}
        <Button
          type='submit'
          title={t('submitButtonText')}
          variant='dark'
          className='submit-personal-details-button'
          disabled={
            fileUploading ||
            !validateForm() ||
            moment().diff(selectedDate, 'minutes') < 0 ||
            moment().diff(selectedDate, 'minutes') > 52596000 ||
            moment().diff(selectedDate, 'minutes').toString() === 'NaN'
          }
          styles='max-w-full flex-shrink-0'
        />
      </form>
    </div>
  );
};

export default PurchaseDetails;
