import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProps } from 'formik';
import { FormDetailModel } from 'types/FormTypes';
import FormImage from './FormImage';
import SearchableDropDown from 'components/atomic/SearchableDropDown';
import envVars from 'env-vars';

const API_URL =
  envVars.REACT_APP_STAND_ALONE_MODE === 'false'
    ? envVars.REACT_APP_API_URL
    : '';

type FormSearchableDropDownProps = {
  formRef: FormikProps<any> | null;
  formData: FormDetailModel;
  name: string;
  index: number;
  formId: string;
  updateFormField: (name: string, value: string) => void;
};

const FormSearchableDropDown: React.FC<FormSearchableDropDownProps> = ({
  formRef,
  name,
  formData,
  index,
  formId,
  updateFormField,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.searchableDropDown',
  });

  useEffect(() => {
    formRef?.validateForm();
  }, []);

  const fetchServerRecords = async (query: string) => {
    try {
      const response = await fetch(`${API_URL}/app_api/searchable/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          searchQuery: query,
          formId: formId,
          formIndex: index,
          limit: 30,
          sortingSchema: formData?.config?.sortingSchema || 'date',
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching server results:', error);
      return [];
    }
  };

  return (
    <div className='w-full h-full flex flex-col justify-start overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5 className='text-left text-lg leading-formTitle font-semibold text-black'>
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='mt-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <div className='w-full flex pb-40 flex-col'>
        {formRef && (
          <SearchableDropDown
            type='text'
            name='drop-down'
            styles='!mt-3'
            allowedCustomValue={formData?.config?.allowedCustomValue}
            placeholder={formRef.values[name] ? '' : t('selectText')}
            selected={{
              value: formRef.values[name],
              label: formRef.values[name],
            }}
            fetchRecords={fetchServerRecords}
            options={formData?.options.map((option) => ({
              value: option.text,
              label: option.text,
            }))}
            onChange={async (option) => {
              await updateFormField(name, option.value);
            }}
          />
        )}
        <div className='mt-2'>
          {formRef?.errors[name] && (
            <span className='text-warning text-[0.8rem]'>
              {formRef.errors[name]}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormSearchableDropDown;
