import { useEffect } from 'react';
import { RoutesHashMap } from 'routes';
import { showToast } from 'components/atomic/Toast/Toast';
import { useGlobal } from 'context/global/GlobalContext';
import { useHistory, useLocation } from 'react-router-dom';
import { MAGIC_ACTION } from 'context/global/GlobalProvider';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import useFirebaseError from 'hooks/useFirebaseError';
import qs from 'query-string';
import useFirebase from 'hooks/useFirebase';

const MagicLink = () => {
  const {
    user,
    setUser,
    signInRedirect,
    setSignInRedirect,
    setMagicAction,
    setProductModule,
    toggleEmailConsent,
    togglePrivacyConsent,
  } = useGlobal();

  const { getAuth, signInWithEmailLink, isSignInWithEmailLink } = useFirebase();

  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();
  const getFirebaseError = useFirebaseError();
  const { openDrawer } = useSuccessDrawerContext();
  const { email, isNewUser, action, productSlug, payload } = qs.parse(
    location.search
  );

  useEffect(() => {
    if (payload) {
      const payloadObject = JSON.parse(decodeURIComponent(payload as string));
      setProductModule(payloadObject?.moduleId);
      toggleEmailConsent(payloadObject?.emailConsent);
      togglePrivacyConsent(payloadObject?.privacyConsent);
    }
  }, [payload, setProductModule, toggleEmailConsent, togglePrivacyConsent]);

  useEffect(() => {
    if (user) {
      setSignInRedirect('');
      switch (action) {
        case MAGIC_ACTION.OPEN_MODULE: {
          if (!productSlug) {
            const link = RoutesHashMap.Collection.path;
            history.push(link);
          }

          // only set magic action if user is registering product
          setMagicAction(action as MAGIC_ACTION);
          const productLink = RoutesHashMap.ProductDetails.path(productSlug);
          history.push(productLink);
          break;
        }
        default: {
          const link = RoutesHashMap.Collection.path;
          history.push(link);
        }
      }
    }
  }, [
    user,
    history,
    isNewUser,
    signInRedirect,
    setMagicAction,
    setSignInRedirect,
    action,
    productSlug,
    openDrawer,
  ]);

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      signInWithEmailLink(auth, email as string, window.location.href)
        .then((userCredentials) => {
          setUser(userCredentials.user);
        })
        .catch((error) => {
          showToast({
            message: getFirebaseError(error.code),
            type: 'error',
          });
          history.push(RoutesHashMap.Login.path);
        });
    }
  }, [auth, email, setUser, getFirebaseError, history]);

  return <LoadingIndicator />;
};

export default MagicLink;
