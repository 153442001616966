import { useEffect, useState } from 'react';
import { getId } from 'utils/getId';
import { v4 as uuidv4 } from 'uuid';

const useDeviceId = () => {
  const [deviceId, setDeviceId] = useState<string | null>(null);

  useEffect(() => {
    try {
      setDeviceId(getId(localStorage, 'deviceId'));
    } catch (e) {
      setDeviceId(uuidv4());
    }
  }, []);

  return deviceId;
};

export default useDeviceId;
