import React, { useCallback, ReactElement, useRef, useEffect } from 'react';
import { removeRegistrationFormLocalStorageValues } from 'utils/forms';
import { ReactComponent as MulberryLogo } from 'assets/logos/svg/mulberry-logo.svg';
import { RegistrationContext } from 'context/RegistrationContext/RegistrationContext';
import { useSuccessDrawerContext } from 'context/SuccessDrawerContext/SuccessDrawerContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { PossibleGtmEventNames } from 'utils/logGtmEvent';
import { useGlobal } from 'context/global/GlobalContext';
import { FormDetailModel } from 'types/FormTypes';
import { getLocalStorage } from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import { CollectionItem } from 'types/User';
import { FormProvider } from 'context';
import { User } from 'firebase/auth';
import { useAPI } from 'utils/api';
import {
  Product,
  ModuleInfoType,
  WarrantyModuleType,
} from 'types/ProductDetailsType';
import AuthDrawer from '../AuthDrawer';
import FormDrawer from '../FormDrawer';
import ScreenType from 'types/ScreenType';
import logGtmEvent from 'utils/logGtmEvent';
import usePrevious from 'hooks/usePrevious';
import PersonalDetails from 'components/forms/PersonalDetails';
import PurchaseDetails from 'components/forms/PurchaseDetails';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import PreRegistration from 'components/registration/PreRegistration/PreRegistration';
import RegisterProductDrawer from '../RegisterProductDrawer';
import MagicActionAuthDrawer from '../MagicActionAuthDrawer';

type RegistrationDrawerProps = {
  product?: Product;
  closePage(): void;
  warrantyId: string;
  isNewUser: boolean;
  brandName?: string;
  drawerTitle?: string;
  registrationData: any;
  onUserUpdate: () => void;
  html: string | undefined | null;
  children: ReactElement<any, any> | null;
  warrantyData?: WarrantyModuleType | any;
  mulberry?: WarrantyModuleType['mulberry'] | null;
  renderNull?: boolean;
  isAutodeploy?: boolean;
};

export const RegistrationDrawer: React.FC<RegistrationDrawerProps> = ({
  html,
  product,
  children,
  mulberry,
  brandName,
  drawerTitle,
  onUserUpdate,
  registrationData,
  isAutodeploy,
  renderNull,
  closePage,
}) => {
  const history = useHistory();

  const { closeSuccessDrawer } = useSuccessDrawerContext();
  const { brandTheme } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.registrationDrawer',
  });

  const { t: authDrawerTranslation } = useTranslation('translation', {
    keyPrefix: 'drawers.authDrawer',
  });

  const {
    slug,
    token,
    screen,
    setScreen,
    desktopView,
    setIsNewUser,
    getUserAsync,
    emailConsent,
    privacyConsent,
    productDetails,
    reFetchProduct,
    registerProduct,
    personalDetails,
    landOnMoreScreen,
    registeringProduct,
    setLandOnMoreScreen,
    openAutoDeployModal,
    setNewRegistrationId,
    multipleRegistrationStarted,
    setMultipleRegistrationStarted,
  } = useGlobal();

  const isMultipleRegistration = useRef<boolean>(false);
  const prevRegisteringProduct = usePrevious(registeringProduct);

  const { formModule, purchaseTemplate, enablePurchaseDetails } =
    productDetails?.registration || ({} as any);
  const { brand } = productDetails || ({} as any);

  const customRegistrationLegalText = productDetails?.brand
    .customRegistrationLegalText
    ? productDetails?.brand.registrationLegalText
    : '';

  const isUnitTagFlow = productDetails?.unitTagDetails?.registered;

  const emailConsentCheck = productDetails?.registration.consentCheck;
  const emailConsentDetails = productDetails?.registration.consentDetail;
  const privacyConsentCheck = productDetails?.registration.privacyConsent;
  const privacyConsentDetails =
    productDetails?.registration.privacyConsentDetail;

  const getDefaultRegistrationLegalText = () => {
    let legalText = '';
    if (customRegistrationLegalText) return customRegistrationLegalText;

    if (isUnitTagFlow)
      legalText = authDrawerTranslation('termsAndConditions.unitTagDisclaimer');
    else {
      legalText = `${authDrawerTranslation(
        'termsAndConditions.part1'
      )}${brandName}${authDrawerTranslation('termsAndConditions.part2')}${
        mulberry
          ? authDrawerTranslation('termsAndConditions.mulberryAndBrijBrand')
          : authDrawerTranslation('termsAndConditions.brijBrand')
      }<a target='_blank' rel='noreferrer' href=${authDrawerTranslation(
        'termsAndConditions.link'
      )}>${authDrawerTranslation(
        'termsAndConditions.linkText'
      )}</a>.${authDrawerTranslation('termsAndConditions.part3')}`;
    }

    legalText.concat(
      `<a
        target='_blank'
        rel='noreferrer'
        className='underline cursor-pointer'
        href="mailto:support@brij.it?subject=Help with ${brandName} ${
        product?.name
      } (${slug})"
      >
        ${authDrawerTranslation('termsAndConditions.helpEmail')}
      </a>`
    );

    return legalText;
  };

  const handleReceiptUpload = () => {
    registerProduct();
    if (isMultipleRegistration.current) setMultipleRegistrationStarted(true);
  };

  const removeFormRegistration = useCallback(() => {
    removeRegistrationFormLocalStorageValues(slug, formModule?.id);
  }, [slug, formModule?.id]);

  const [generateRegistrationId] = useAPI({
    endpoint: '/auth/generate_registration_id',
    method: 'POST',
  });

  const [startRegistration] = useAPI({
    endpoint: '/auth/start_registration',
    method: 'POST',
  });

  const nextRegistrationAction = useCallback(
    async (currentToken?: string) => {
      let generatedRegistrationId = null;

      const productCollectionItem =
        personalDetails?.profile.productCollection?.find(
          (item: CollectionItem) => item.tagId === slug
        );

      if (productCollectionItem?.incomplete)
        generatedRegistrationId = productCollectionItem?.registrationId || null;
      else {
        const tokenToUse = (token as string) || currentToken;
        const { registrationId } = await generateRegistrationId(
          { slug },
          tokenToUse
        );
        generatedRegistrationId = registrationId;
      }

      setNewRegistrationId(generatedRegistrationId);

      let userBirthDate;

      if (
        getLocalStorage().getItem('ageGateChecked') === 'true' &&
        getLocalStorage().getItem('ageGateBirthDate')
      ) {
        if (
          productDetails?.product?.ageGateEnabled ||
          productDetails?.product.enableContentGate
        )
          userBirthDate = new Date(
            getLocalStorage().getItem('ageGateBirthDate')
          );
      }

      try {
        await startRegistration(
          {
            brandId: brand?.id,
            tag: slug,
            registrationId: generatedRegistrationId,
            registrationModuleId: registrationData?._id,
            consents: [
              {
                allowed: emailConsentCheck ? emailConsent : true,
                consentType: emailConsentCheck ? 'explicit' : 'implicit',
                // Todo this should be dynamic in future
                consentFor: 'email',
                language: emailConsentDetails?.text || '',
                defaultLanguage: getDefaultRegistrationLegalText(),
              },
              {
                allowed: privacyConsentCheck ? privacyConsent : true,
                consentType: privacyConsentCheck ? 'explicit' : 'implicit',
                // Todo this should be dynamic in future
                consentFor: 'privacy',
                defaultLanguage: getDefaultRegistrationLegalText(),
                language: privacyConsentDetails?.text || '',
              },
            ],
            userBirthDate: userBirthDate ? userBirthDate : '',
          },
          (token as string) || currentToken
        );
      } catch (error) {
        console.log(error);
      }

      // landOnMoreScreen is set to true so the user is redirected to the more screen after registration
      setLandOnMoreScreen(registrationData?.landOnMoreScreen || false);

      if (formModule) {
        const isFormComplete = getLocalStorage().getItem(
          `brij-form-${slug}-${formModule.id}-complete`
        );

        if (!isFormComplete) {
          setScreen(ScreenType.FORM);
          closeSuccessDrawer();
          return;
        }
      }

      if (enablePurchaseDetails) {
        setScreen(ScreenType.PURCHASE_DETAILS);
        closeSuccessDrawer();
        return;
      }

      registerProduct();

      if (isMultipleRegistration.current) {
        setMultipleRegistrationStarted(true);
      }
    },
    [
      enablePurchaseDetails,
      registerProduct,
      formModule,
      setScreen,
      personalDetails,
      token,
      emailConsent,
      customRegistrationLegalText,
    ]
  );

  const onAuthComplete = useCallback(
    async (provider: string, user?: User) => {
      // if user is a social user check if the product is already registered with the user
      // if yes, then show the current module
      // else show the registration screen
      logGtmEvent(PossibleGtmEventNames.REGISTRATION_INITIATION);
      if (provider === 'social') {
        const token = await user?.getIdToken();
        const personalDetails: any = await getUserAsync(null, token);

        if (
          personalDetails?.profile.productCollection?.find(
            (item: CollectionItem) => item.tagId === slug && !item.incomplete
          )
        ) {
          setScreen(ScreenType.CURRENT_MODULE);
          reFetchProduct(null, token);
          closeSuccessDrawer();
          return;
        }
      }
      nextRegistrationAction(await user?.getIdToken());
    },
    [
      closeSuccessDrawer,
      getUserAsync,
      reFetchProduct,
      setScreen,
      nextRegistrationAction,
    ]
  );

  // if product is registered and user is registerting again
  // go back to the warranty module
  useEffect(() => {
    if (
      prevRegisteringProduct &&
      !registeringProduct &&
      multipleRegistrationStarted
    ) {
      setMultipleRegistrationStarted(false);
      closePage();
      isMultipleRegistration.current = false;
      history.push(`/c/${slug}`);
      setScreen(ScreenType.NONE);
    }
  }, [registeringProduct, prevRegisteringProduct, multipleRegistrationStarted]);

  // remove values
  useEffect(() => {
    setMultipleRegistrationStarted(false);
    isMultipleRegistration.current = false;
  }, []);

  useEffect(() => {}, [registeringProduct]);

  if (renderNull) return null;

  switch (screen) {
    case ScreenType.MAGIC_ACTION_AUTH:
      return <MagicActionAuthDrawer onAuth={nextRegistrationAction} />;
    case ScreenType.AUTH:
      return (
        <div
          className={`relative w-full h-full flex pb-4 overflow-y-auto overflow-x-hidden ${
            !desktopView ? 'pt-16' : 'pt-4'
          }`}
        >
          {!desktopView && (
            <div className='z-10 fixed flex items-center top-0 left-0 w-full h-16 rounded-[26px] bg-white'>
              {mulberry ? (
                <div className='z-10 fixed top-0 left-0 flex min-h-16 max-h-16 w-full rounded-[26px] bg-white'>
                  <MulberryLogo className='w-[7.2rem] my-[1.125rem] ml-6 mr-16' />
                </div>
              ) : (
                <h1 className='w-[calc(100%-6rem)] mt-1 mr-2 ml-6 text-base font-semibold text-left leading-5'>
                  {drawerTitle}
                </h1>
              )}
            </div>
          )}
          <AuthDrawer
            animated={false}
            mulberry={mulberry}
            onAuthComplete={onAuthComplete}
            brandName={productDetails?.brand?.name}
            customLegalText={
              productDetails?.brand.customRegistrationLegalText
                ? productDetails?.brand.registrationLegalText
                : ''
            }
            isAutodeploy={isAutodeploy}
            html={productDetails?.registration?.registrationText}
            productName={
              productDetails?.product?.displayName ||
              productDetails?.product?.name
            }
          />
        </div>
      );
    case ScreenType.MULTIPLE_REGISTRATION:
      return (
        <RegistrationContext.Provider
          value={{
            onAuth: nextRegistrationAction,
            isMultipleRegistration,
            removeFormRegistration,
            closePage: closePage,
          }}
        >
          <RegisterProductDrawer />
        </RegistrationContext.Provider>
      );
    case ScreenType.CURRENT_MODULE:
      if (landOnMoreScreen) {
        closePage();
        setLandOnMoreScreen(false);
        return null;
      }

      return children;
    case ScreenType.COMPLETE_PROFILE:
      return (
        <PersonalDetails
          saveToShopify={true}
          onDetailsSaved={() => {}}
          onPersonalDetailsUpdate={() => {
            getLocalStorage().removeItem('brij-form-user-update-id');
            setScreen(ScreenType.CURRENT_MODULE, multipleRegistrationStarted);
            onUserUpdate();
            setIsNewUser(false);
          }}
        />
      );
    case ScreenType.REGISTER:
      return (
        <div
          className={`relative w-full h-full flex pb-4 overflow-y-auto overflow-x-hidden ${
            !desktopView ? 'pt-16' : 'pt-4'
          }`}
        >
          {!desktopView && (
            <div className='z-10 fixed flex items-center top-0 left-0 w-full h-16 rounded-[26px] bg-white'>
              <h1 className='w-[calc(100%-6rem)] mt-1 mr-2 ml-6 text-base font-semibold text-left leading-5'>
                {drawerTitle}
              </h1>
            </div>
          )}
          <PreRegistration
            html={html}
            slug={slug}
            brandName={brandName}
            brandTheme={brandTheme}
            register={nextRegistrationAction}
            productName={product?.name}
            showMulberryTerms={!!mulberry}
            registrationType={registrationData?.registrationType}
            purchaseTemplate={registrationData?.purchaseTemplate}
            customCallToAction={registrationData?.customCallToAction}
            enablePurchaseDetails={registrationData?.enablePurchaseDetails}
            customLegalText={
              productDetails?.brand.customRegistrationLegalText
                ? productDetails?.brand.registrationLegalText
                : ''
            }
          />
        </div>
      );
    case ScreenType.FORM:
      return formModule?.moduleInfo?.length ? (
        <FormProvider>
          <FormDrawer
            formModuleData={formModule as ModuleInfoType}
            data={formModule?.moduleInfo as FormDetailModel[]}
            endScreenNavModuleIndex={0}
            onSubmit={() => {
              if (enablePurchaseDetails) {
                setScreen(ScreenType.PURCHASE_DETAILS);
                return;
              }
              if (isMultipleRegistration.current)
                setMultipleRegistrationStarted(true);
              registerProduct();
            }}
            type={registrationData?.attachedModuleType || 'FORM'}
          />
        </FormProvider>
      ) : (
        <LoadingIndicator />
      );
    case ScreenType.PURCHASE_DETAILS:
      return (
        <div
          className={`relative w-full h-full flex pb-4 duration-150 overflow-y-auto overflow-x-hidden ${
            desktopView && openAutoDeployModal ? 'pt-2' : 'pt-16'
          }`}
        >
          <div
            className={`z-10 fixed flex items-center top-0 left-0 w-full rounded-[26px] bg-white overflow-hidden duration-150 ${
              desktopView && openAutoDeployModal ? 'h-0' : 'h-16'
            }`}
          >
            <h1 className='w-[calc(100%-6rem)] mt-1 mr-2 ml-6 text-base font-semibold text-left leading-5'>
              {t('purchaseDetailsHeading')}
            </h1>
          </div>
          <Animated
            isVisible
            animateOnMount
            className='w-full h-full'
            animationIn='slideInRight'
            animationOut='slideOutLeft'
            animationInDuration={200}
            animationOutDuration={200}
          >
            <PurchaseDetails
              name={purchaseTemplate?.name}
              phoneNo={purchaseTemplate?.phoneNo}
              receipt={purchaseTemplate?.receipt}
              quantity={purchaseTemplate?.quantity}
              serialNo={purchaseTemplate?.serialNo}
              afterReceiptUpload={handleReceiptUpload}
              callToAction={purchaseTemplate?.CTAText}
              phoneDetail={purchaseTemplate?.phoneDetail}
              description={purchaseTemplate?.description}
              purchaseDate={purchaseTemplate?.purchaseDate}
              includeOther={purchaseTemplate?.includeOther}
              retailChannel={purchaseTemplate?.retailChannel}
              retailChannelsList={purchaseTemplate?.retailChannelsList}
            />
          </Animated>
        </div>
      );
    default:
      return null;
  }
};

export default RegistrationDrawer;
