export interface Position {
  x: number;
  y: number;
}

export type UrlParam = { id: string };

export type CountryCodeType = {
  mask: string;
  country: string;
  countryCode: string;
  flag: string;
};

export const countryPhoneCodes: CountryCodeType[] = [
  {
    mask: '(+1)',
    country: 'United States',
    countryCode: 'US',
    flag: '🇺🇸',
  },
  {
    mask: '(+93)',
    country: 'Afghanistan',
    countryCode: 'AF',
    flag: '🇦🇫',
  },
  {
    mask: '(+355)',
    country: 'Albania',
    countryCode: 'AL',
    flag: '🇦🇱',
  },
  {
    mask: '(+213)',
    country: 'Algeria',
    countryCode: 'DZ',
    flag: '🇩🇿',
  },
  {
    mask: '(+376)',
    country: 'Andorra',
    countryCode: 'AD',
    flag: '🇦🇩',
  },
  {
    mask: '(+244)',
    country: 'Angola',
    countryCode: 'AO',
    flag: '🇦🇴',
  },
  {
    mask: '(+54)',
    country: 'Argentina',
    countryCode: 'AR',
    flag: '🇦🇷',
  },
  {
    mask: '(+374)',
    country: 'Armenia',
    countryCode: 'AM',
    flag: '🇦🇲',
  },
  {
    mask: '(+61)',
    country: 'Australia',
    countryCode: 'AU',
    flag: '🇦🇺',
  },
  {
    mask: '(+43)',
    country: 'Austria',
    countryCode: 'AT',
    flag: '🇦🇹',
  },
  {
    mask: '(+994)',
    country: 'Azerbaijan',
    countryCode: 'AZ',
    flag: '🇦🇿',
  },
  {
    mask: '(+973)',
    country: 'Bahrain',
    countryCode: 'BH',
    flag: '🇧🇭',
  },
  {
    mask: '(+880)',
    country: 'Bangladesh',
    countryCode: 'BD',
    flag: '🇧🇩',
  },
  {
    mask: '(+375)',
    country: 'Belarus',
    countryCode: 'BY',
    flag: '🇧🇾',
  },
  {
    mask: '(+32)',
    country: 'Belgium',
    countryCode: 'BE',
    flag: '🇧🇪',
  },
  {
    mask: '(+501)',
    country: 'Belize',
    countryCode: 'BZ',
    flag: '🇧🇿',
  },
  {
    mask: '(+229)',
    country: 'Benin',
    countryCode: 'BJ',
    flag: '🇧🇯',
  },
  {
    mask: '(+975)',
    country: 'Bhutan',
    countryCode: 'BT',
    flag: '🇧🇹',
  },
  {
    mask: '(+591)',
    country: 'Bolivia',
    countryCode: 'BO',
    flag: '🇧🇴',
  },
  {
    mask: '(+387)',
    country: 'Bosnia and Herzegovina',
    countryCode: 'BA',
    flag: '🇧🇦',
  },
  {
    mask: '(+267)',
    country: 'Botswana',
    countryCode: 'BW',
    flag: '🇧🇼',
  },
  {
    mask: '(+55)',
    country: 'Brazil',
    countryCode: 'BR',
    flag: '🇧🇷',
  },
  {
    mask: '(+673)',
    country: 'Brunei',
    countryCode: 'BN',
    flag: '🇧🇳',
  },
  {
    mask: '(+359)',
    country: 'Bulgaria',
    countryCode: 'BG',
    flag: '🇧🇬',
  },
  {
    mask: '(+226)',
    country: 'Burkina Faso',
    countryCode: 'BF',
    flag: '🇧🇫',
  },
  {
    mask: '(+257)',
    country: 'Burundi',
    countryCode: 'BI',
    flag: '🇧🇮',
  },
  {
    mask: '(+855)',
    country: 'Cambodia',
    countryCode: 'KH',
    flag: '🇰🇭',
  },
  {
    mask: '(+237)',
    country: 'Cameroon',
    countryCode: 'CM',
    flag: '🇨🇲',
  },
  {
    mask: '(+1)',
    country: 'Canada',
    countryCode: 'CA',
    flag: '🇨🇦',
  },
  {
    mask: '(+238)',
    country: 'Cape Verde',
    countryCode: 'CV',
    flag: '🇨🇻',
  },
  {
    mask: '(+236)',
    country: 'Central African Republic',
    countryCode: 'CF',
    flag: '🇨🇫',
  },
  {
    mask: '(+235)',
    country: 'Chad',
    countryCode: 'TD',
    flag: '🇹🇩',
  },
  {
    mask: '(+56)',
    country: 'Chile',
    countryCode: 'CL',
    flag: '🇨🇱',
  },
  {
    mask: '(+86)',
    country: 'China',
    countryCode: 'CN',
    flag: '🇨🇳',
  },
  {
    mask: '(+57)',
    country: 'Colombia',
    countryCode: 'CO',
    flag: '🇨🇴',
  },
  {
    mask: '(+269)',
    country: 'Comoros',
    countryCode: 'KM',
    flag: '🇰🇲',
  },
  {
    mask: '(+506)',
    country: 'Costa Rica',
    countryCode: 'CR',
    flag: '🇨🇷',
  },
  {
    mask: '(+385)',
    country: 'Croatia',
    countryCode: 'HR',
    flag: '🇭🇷',
  },
  {
    mask: '(+53)',
    country: 'Cuba',
    countryCode: 'CU',
    flag: '🇨🇺',
  },
  {
    mask: '(+357)',
    country: 'Cyprus',
    countryCode: 'CY',
    flag: '🇨🇾',
  },
  {
    mask: '(+420)',
    country: 'Czech Republic',
    countryCode: 'CZ',
    flag: '🇨🇿',
  },
  {
    mask: '(+243)',
    country: 'Democratic Republic of the Congo',
    countryCode: 'CD',
    flag: '🇨🇩',
  },
  {
    mask: '(+45)',
    country: 'Denmark',
    countryCode: 'DK',
    flag: '🇩🇰',
  },
  {
    mask: '(+253)',
    country: 'Djibouti',
    countryCode: 'DJ',
    flag: '🇩🇯',
  },
  {
    mask: '(+1)',
    country: 'Dominica',
    countryCode: 'DM',
    flag: '🇩🇲',
  },
  {
    mask: '(+1)',
    country: 'Dominican Republic',
    countryCode: 'DO',
    flag: '🇩🇴',
  },
  {
    mask: '(+593)',
    country: 'Ecuador',
    countryCode: 'EC',
    flag: '🇪🇨',
  },
  {
    mask: '(+20)',
    country: 'Egypt',
    countryCode: 'EG',
    flag: '🇪🇬',
  },
  {
    mask: '(+503)',
    country: 'El Salvador',
    countryCode: 'SV',
    flag: '🇸🇻',
  },
  {
    mask: '(+240)',
    country: 'Equatorial Guinea',
    countryCode: 'GQ',
    flag: '🇬🇶',
  },
  {
    mask: '(+291)',
    country: 'Eritrea',
    countryCode: 'ER',
    flag: '🇪🇷',
  },
  {
    mask: '(+372)',
    country: 'Estonia',
    countryCode: 'EE',
    flag: '🇪🇪',
  },
  {
    mask: '(+251)',
    country: 'Ethiopia',
    countryCode: 'ET',
    flag: '🇪🇹',
  },
  {
    mask: '(+298)',
    country: 'Faroe Islands',
    countryCode: 'FO',
    flag: '🇫🇴',
  },
  {
    mask: '(+679)',
    country: 'Fiji',
    countryCode: 'FJ',
    flag: '🇫🇯',
  },
  {
    mask: '(+358)',
    country: 'Finland',
    countryCode: 'FI',
    flag: '🇫🇮',
  },
  {
    mask: '(+33)',
    country: 'France',
    countryCode: 'FR',
    flag: '🇫🇷',
  },
  {
    mask: '(+241)',
    country: 'Gabon',
    countryCode: 'GA',
    flag: '🇬🇦',
  },
  {
    mask: '(+220)',
    country: 'Gambia',
    countryCode: 'GM',
    flag: '🇬🇲',
  },
  {
    mask: '(+995)',
    country: 'Georgia',
    countryCode: 'GE',
    flag: '🇬🇪',
  },
  {
    mask: '(+49)',
    country: 'Germany',
    countryCode: 'DE',
    flag: '🇩🇪',
  },
  {
    mask: '(+233)',
    country: 'Ghana',
    countryCode: 'GH',
    flag: '🇬🇭',
  },
  {
    mask: '(+30)',
    country: 'Greece',
    countryCode: 'GR',
    flag: '🇬🇷',
  },
  {
    mask: '(+299)',
    country: 'Greenland',
    countryCode: 'GL',
    flag: '🇬🇱',
  },
  {
    mask: '(+1)',
    country: 'Grenada',
    countryCode: 'GD',
    flag: '🇬🇩',
  },
  {
    mask: '(+502)',
    country: 'Guatemala',
    countryCode: 'GT',
    flag: '🇬🇹',
  },
  {
    mask: '(+224)',
    country: 'Guinea',
    countryCode: 'GN',
    flag: '🇬🇳',
  },
  {
    mask: '(+245)',
    country: 'Guinea-Bissau',
    countryCode: 'GW',
    flag: '🇬🇼',
  },
  {
    mask: '(+592)',
    country: 'Guyana',
    countryCode: 'GY',
    flag: '🇬🇾',
  },
  {
    mask: '(+509)',
    country: 'Haiti',
    countryCode: 'HT',
    flag: '🇭🇹',
  },
  {
    mask: '(+504)',
    country: 'Honduras',
    countryCode: 'HN',
    flag: '🇭🇳',
  },
  {
    mask: '(+852)',
    country: 'Hong Kong',
    countryCode: 'HK',
    flag: '🇭🇰',
  },
  {
    mask: '(+36)',
    country: 'Hungary',
    countryCode: 'HU',
    flag: '🇭🇺',
  },
  {
    mask: '(+354)',
    country: 'Iceland',
    countryCode: 'IS',
    flag: '🇮🇸',
  },
  {
    mask: '(+91)',
    country: 'India',
    countryCode: 'IN',
    flag: '🇮🇳',
  },
  {
    mask: '(+62)',
    country: 'Indonesia',
    countryCode: 'ID',
    flag: '🇮🇩',
  },
  {
    mask: '(+98)',
    country: 'Iran',
    countryCode: 'IR',
    flag: '🇮🇷',
  },
  {
    mask: '(+964)',
    country: 'Iraq',
    countryCode: 'IQ',
    flag: '🇮🇶',
  },
  {
    mask: '(+353)',
    country: 'Ireland',
    countryCode: 'IE',
    flag: '🇮🇪',
  },
  {
    mask: '(+972)',
    country: 'Israel',
    countryCode: 'IL',
    flag: '🇮🇱',
  },
  {
    mask: '(+39)',
    country: 'Italy',
    countryCode: 'IT',
    flag: '🇮🇹',
  },
  {
    mask: '(+225)',
    country: 'Ivory Coast',
    countryCode: 'CI',
    flag: '🇨🇮',
  },
  {
    mask: '(+1)',
    country: 'Jamaica',
    countryCode: 'JM',
    flag: '🇯🇲',
  },
  {
    mask: '(+81)',
    country: 'Japan',
    countryCode: 'JP',
    flag: '🇯🇵',
  },
  {
    mask: '(+962)',
    country: 'Jordan',
    countryCode: 'JO',
    flag: '🇯🇴',
  },
  {
    mask: '(+7)',
    country: 'Kazakhstan',
    countryCode: 'KZ',
    flag: '🇰🇿',
  },
  {
    mask: '(+254)',
    country: 'Kenya',
    countryCode: 'KE',
    flag: '🇰🇪',
  },
  {
    mask: '(+686)',
    country: 'Kiribati',
    countryCode: 'KI',
    flag: '🇰🇮',
  },
  {
    mask: '(+965)',
    country: 'Kuwait',
    countryCode: 'KW',
    flag: '🇰🇼',
  },
  {
    mask: '(+996)',
    country: 'Kyrgyzstan',
    countryCode: 'KG',
    flag: '🇰🇬',
  },
  {
    mask: '(+856)',
    country: 'Laos',
    countryCode: 'LA',
    flag: '🇱🇦',
  },
  {
    mask: '(+371)',
    country: 'Latvia',
    countryCode: 'LV',
    flag: '🇱🇻',
  },
  {
    mask: '(+961)',
    country: 'Lebanon',
    countryCode: 'LB',
    flag: '🇱🇧',
  },
  {
    mask: '(+266)',
    country: 'Lesotho',
    countryCode: 'LS',
    flag: '🇱🇸',
  },
  {
    mask: '(+231)',
    country: 'Liberia',
    countryCode: 'LR',
    flag: '🇱🇷',
  },
  {
    mask: '(+218)',
    country: 'Libya',
    countryCode: 'LY',
    flag: '🇱🇾',
  },
  {
    mask: '(+423)',
    country: 'Liechtenstein',
    countryCode: 'LI',
    flag: '🇱🇮',
  },
  {
    mask: '(+370)',
    country: 'Lithuania',
    countryCode: 'LT',
    flag: '🇱🇹',
  },
  {
    mask: '(+352)',
    country: 'Luxembourg',
    countryCode: 'LU',
    flag: '🇱🇺',
  },
  {
    mask: '(+853)',
    country: 'Macau',
    countryCode: 'MO',
    flag: '🇲🇴',
  },
  {
    mask: '(+389)',
    country: 'Macedonia',
    countryCode: 'MK',
    flag: '🇲🇰',
  },
  {
    mask: '(+261)',
    country: 'Madagascar',
    countryCode: 'MG',
    flag: '🇲🇬',
  },
  {
    mask: '(+265)',
    country: 'Malawi',
    countryCode: 'MW',
    flag: '🇲🇼',
  },
  {
    mask: '(+60)',
    country: 'Malaysia',
    countryCode: 'MY',
    flag: '🇲🇾',
  },
  {
    mask: '(+960)',
    country: 'Maldives',
    countryCode: 'MV',
    flag: '🇲🇻',
  },
  {
    mask: '(+223)',
    country: 'Mali',
    countryCode: 'ML',
    flag: '🇲🇱',
  },
  {
    mask: '(+356)',
    country: 'Malta',
    countryCode: 'MT',
    flag: '🇲🇹',
  },
  {
    mask: '(+692)',
    country: 'Marshall Islands',
    countryCode: 'MH',
    flag: '🇲🇭',
  },
  {
    mask: '(+222)',
    country: 'Mauritania',
    countryCode: 'MR',
    flag: '🇲🇷',
  },
  {
    mask: '(+230)',
    country: 'Mauritius',
    countryCode: 'MU',
    flag: '🇲🇺',
  },
  {
    mask: '(+52)',
    country: 'Mexico',
    countryCode: 'MX',
    flag: '🇲🇽',
  },
  {
    mask: '(+691)',
    country: 'Micronesia',
    countryCode: 'FM',
    flag: '🇫🇲',
  },
  {
    mask: '(+373)',
    country: 'Moldova',
    countryCode: 'MD',
    flag: '🇲🇩',
  },
  {
    mask: '(+377)',
    country: 'Monaco',
    countryCode: 'MC',
    flag: '🇲🇨',
  },
  {
    mask: '(+976)',
    country: 'Mongolia',
    countryCode: 'MN',
    flag: '🇲🇳',
  },
  {
    mask: '(+382)',
    country: 'Montenegro',
    countryCode: 'ME',
    flag: '🇲🇪',
  },
  {
    mask: '(+212)',
    country: 'Morocco',
    countryCode: 'MA',
    flag: '🇲🇦',
  },
  {
    mask: '(+258)',
    country: 'Mozambique',
    countryCode: 'MZ',
    flag: '🇲🇿',
  },
  {
    mask: '(+95)',
    country: 'Myanmar',
    countryCode: 'MM',
    flag: '🇲🇲',
  },
  {
    mask: '(+264)',
    country: 'Namibia',
    countryCode: 'NA',
    flag: '🇳🇦',
  },
  {
    mask: '(+674)',
    country: 'Nauru',
    countryCode: 'NR',
    flag: '🇳🇷',
  },
  {
    mask: '(+977)',
    country: 'Nepal',
    countryCode: 'NP',
    flag: '🇳🇵',
  },
  {
    mask: '(+31)',
    country: 'Netherlands',
    countryCode: 'NL',
    flag: '🇳🇱',
  },
  {
    mask: '(+64)',
    country: 'New Zealand',
    countryCode: 'NZ',
    flag: '🇳🇿',
  },
  {
    mask: '(+505)',
    country: 'Nicaragua',
    countryCode: 'NI',
    flag: '🇳🇮',
  },
  {
    mask: '(+227)',
    country: 'Niger',
    countryCode: 'NE',
    flag: '🇳🇪',
  },
  {
    mask: '(+234)',
    country: 'Nigeria',
    countryCode: 'NG',
    flag: '🇳🇬',
  },
  {
    mask: '(+850)',
    country: 'North Korea',
    countryCode: 'KP',
    flag: '🇰🇵',
  },
  {
    mask: '(+47)',
    country: 'Norway',
    countryCode: 'NO',
    flag: '🇳🇴',
  },
  {
    mask: '(+968)',
    country: 'Oman',
    countryCode: 'OM',
    flag: '🇴🇲',
  },
  {
    mask: '(+92)',
    country: 'Pakistan',
    countryCode: 'PK',
    flag: '🇵🇰',
  },
  {
    mask: '(+680)',
    country: 'Palau',
    countryCode: 'PW',
    flag: '🇵🇼',
  },
  {
    mask: '(+970)',
    country: 'Palestine',
    countryCode: 'PS',
    flag: '🇵🇸',
  },
  {
    mask: '(+507)',
    country: 'Panama',
    countryCode: 'PA',
    flag: '🇵🇦',
  },
  {
    mask: '(+675)',
    country: 'Papua New Guinea',
    countryCode: 'PG',
    flag: '🇵🇬',
  },
  {
    mask: '(+595)',
    country: 'Paraguay',
    countryCode: 'PY',
    flag: '🇵🇾',
  },
  {
    mask: '(+51)',
    country: 'Peru',
    countryCode: 'PE',
    flag: '🇵🇪',
  },
  {
    mask: '(+63)',
    country: 'Philippines',
    countryCode: 'PH',
    flag: '🇵🇭',
  },
  {
    mask: '(+48)',
    country: 'Poland',
    countryCode: 'PL',
    flag: '🇵🇱',
  },
  {
    mask: '(+351)',
    country: 'Portugal',
    countryCode: 'PT',
    flag: '🇵🇹',
  },
  {
    mask: '(+974)',
    country: 'Qatar',
    countryCode: 'QA',
    flag: '🇶🇦',
  },
  {
    mask: '(+242)',
    country: 'Republic of the Congo',
    countryCode: 'CG',
    flag: '🇨🇬',
  },
  {
    mask: '(+40)',
    country: 'Romania',
    countryCode: 'RO',
    flag: '🇷🇴',
  },
  {
    mask: '(+7)',
    country: 'Russia',
    countryCode: 'RU',
    flag: '🇷🇺',
  },
  {
    mask: '(+250)',
    country: 'Rwanda',
    countryCode: 'RW',
    flag: '🇷🇼',
  },
  {
    mask: '(+590)',
    country: 'Saint Barthelemy',
    countryCode: 'BL',
    flag: '🇧🇱',
  },
  {
    mask: '(+290)',
    country: 'Saint Helena',
    countryCode: 'SH',
    flag: '🇸🇭',
  },
  {
    mask: '(+1)',
    country: 'Saint Kitts and Nevis',
    countryCode: 'KN',
    flag: '🇰🇳',
  },
  {
    mask: '(+1)',
    country: 'Saint Lucia',
    countryCode: 'LC',
    flag: '🇱🇨',
  },
  {
    mask: '(+590)',
    country: 'Saint Martin',
    countryCode: 'MF',
    flag: '🇲🇫',
  },
  {
    mask: '(+508)',
    country: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
    flag: '🇵🇲',
  },
  {
    mask: '(+1)',
    country: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    flag: '🇻🇨',
  },
  {
    mask: '(+685)',
    country: 'Samoa',
    countryCode: 'WS',
    flag: '🇼🇸',
  },
  {
    mask: '(+378)',
    country: 'San Marino',
    countryCode: 'SM',
    flag: '🇸🇲',
  },
  {
    mask: '(+239)',
    country: 'Sao Tome and Principe',
    countryCode: 'ST',
    flag: '🇸🇹',
  },
  {
    mask: '(+966)',
    country: 'Saudi Arabia',
    countryCode: 'SA',
    flag: '🇸🇦',
  },
  {
    mask: '(+221)',
    country: 'Senegal',
    countryCode: 'SN',
    flag: '🇸🇳',
  },
  {
    mask: '(+381)',
    country: 'Serbia',
    countryCode: 'RS',
    flag: '🇷🇸',
  },
  {
    mask: '(+248)',
    country: 'Seychelles',
    countryCode: 'SC',
    flag: '🇸🇨',
  },
  {
    mask: '(+232)',
    country: 'Sierra Leone',
    countryCode: 'SL',
    flag: '🇸🇱',
  },
  {
    mask: '(+65)',
    country: 'Singapore',
    countryCode: 'SG',
    flag: '🇸🇬',
  },
  {
    mask: '(+421)',
    country: 'Slovakia',
    countryCode: 'SK',
    flag: '🇸🇰',
  },
  {
    mask: '(+386)',
    country: 'Slovenia',
    countryCode: 'SI',
    flag: '🇸🇮',
  },
  {
    mask: '(+677)',
    country: 'Solomon Islands',
    countryCode: 'SB',
    flag: '🇸🇧',
  },
  {
    mask: '(+252)',
    country: 'Somalia',
    countryCode: 'SO',
    flag: '🇸🇴',
  },
  {
    mask: '(+27)',
    country: 'South Africa',
    countryCode: 'ZA',
    flag: '🇿🇦',
  },
  {
    mask: '(+82)',
    country: 'South Korea',
    countryCode: 'KR',
    flag: '🇰🇷',
  },
  {
    mask: '(+211)',
    country: 'South Sudan',
    countryCode: 'SS',
    flag: '🇸🇸',
  },
  {
    mask: '(+34)',
    country: 'Spain',
    countryCode: 'ES',
    flag: '🇪🇸',
  },
  {
    mask: '(+94)',
    country: 'Sri Lanka',
    countryCode: 'LK',
    flag: '🇱🇰',
  },
  {
    mask: '(+249)',
    country: 'Sudan',
    countryCode: 'SD',
    flag: '🇸🇩',
  },
  {
    mask: '(+597)',
    country: 'Suriname',
    countryCode: 'SR',
    flag: '🇸🇷',
  },
  {
    mask: '(+268)',
    country: 'Swaziland',
    countryCode: 'SZ',
    flag: '🇸🇿',
  },
  {
    mask: '(+46)',
    country: 'Sweden',
    countryCode: 'SE',
    flag: '🇸🇪',
  },
  {
    mask: '(+41)',
    country: 'Switzerland',
    countryCode: 'CH',
    flag: '🇨🇭',
  },
  {
    mask: '(+963)',
    country: 'Syria',
    countryCode: 'SY',
    flag: '🇸🇾',
  },
  {
    mask: '(+886)',
    country: 'Taiwan',
    countryCode: 'TW',
    flag: '🇹🇼',
  },
  {
    mask: '(+992)',
    country: 'Tajikistan',
    countryCode: 'TJ',
    flag: '🇹🇯',
  },
  {
    mask: '(+255)',
    country: 'Tanzania',
    countryCode: 'TZ',
    flag: '🇹🇿',
  },
  {
    mask: '(+66)',
    country: 'Thailand',
    countryCode: 'TH',
    flag: '🇹🇭',
  },
  {
    mask: '(+670)',
    country: 'Timor-Leste',
    countryCode: 'TL',
    flag: '🇹🇱',
  },
  {
    mask: '(+228)',
    country: 'Togo',
    countryCode: 'TG',
    flag: '🇹🇬',
  },
  {
    mask: '(+676)',
    country: 'Tonga',
    countryCode: 'TO',
    flag: '🇹🇴',
  },
  {
    mask: '(+1)',
    country: 'Trinidad and Tobago',
    countryCode: 'TT',
    flag: '🇹🇹',
  },
  {
    mask: '(+216)',
    country: 'Tunisia',
    countryCode: 'TN',
    flag: '🇹🇳',
  },
  {
    mask: '(+90)',
    country: 'Turkey',
    countryCode: 'TR',
    flag: '🇹🇷',
  },
  {
    mask: '(+993)',
    country: 'Turkmenistan',
    countryCode: 'TM',
    flag: '🇹🇲',
  },
  {
    mask: '(+688)',
    country: 'Tuvalu',
    countryCode: 'TV',
    flag: '🇹🇻',
  },
  {
    mask: '(+256)',
    country: 'Uganda',
    countryCode: 'UG',
    flag: '🇺🇬',
  },
  {
    mask: '(+380)',
    country: 'Ukraine',
    countryCode: 'UA',
    flag: '🇺🇦',
  },
  {
    mask: '(+971)',
    country: 'United Arab Emirates',
    countryCode: 'AE',
    flag: '🇦🇪',
  },
  {
    mask: '(+44)',
    country: 'United Kingdom',
    countryCode: 'GB',
    flag: '🇬🇧',
  },
  {
    mask: '(+598)',
    country: 'Uruguay',
    countryCode: 'UY',
    flag: '🇺🇾',
  },
  {
    mask: '(+998)',
    country: 'Uzbekistan',
    countryCode: 'UZ',
    flag: '🇺🇿',
  },
  {
    mask: '(+678)',
    country: 'Vanuatu',
    countryCode: 'VU',
    flag: '🇻🇺',
  },
  {
    mask: '(+39)',
    country: 'Vatican City',
    countryCode: 'VA',
    flag: '🇻🇦',
  },
  {
    mask: '(+58)',
    country: 'Venezuela',
    countryCode: 'VE',
    flag: '🇻🇪',
  },
  {
    mask: '(+84)',
    country: 'Vietnam',
    countryCode: 'VN',
    flag: '🇻🇳',
  },
  {
    mask: '(+967)',
    country: 'Yemen',
    countryCode: 'YE',
    flag: '🇾🇪',
  },
  {
    mask: '(+260)',
    country: 'Zambia',
    countryCode: 'ZM',
    flag: '🇿🇲',
  },
  {
    mask: '(+263)',
    country: 'Zimbabwe',
    countryCode: 'ZW',
    flag: '🇿🇼',
  },
];
