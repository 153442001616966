import { createContext, useContext } from 'react';

interface MetaProps {
  title: string;
  description?: string;
}

export type SuccessDrawerContextProps = {
  openDrawer: () => void;
  closeSuccessDrawer: () => void;
  showSuccess: () => void;
  closeSuccess: () => void;
  setMeta: (meta: MetaProps) => void;
  setCloseOnComplete: (closeOnComplete: boolean) => void;
  meta: MetaProps;
  open: boolean;
  success: boolean;
  closeOnComplete: boolean;
  clearMeta: () => void;
};

export const SuccessDrawerContext = createContext<SuccessDrawerContextProps>({
  openDrawer: () => {},
  closeSuccessDrawer: () => {},
  showSuccess: () => {},
  closeSuccess: () => {},
  setMeta: (meta) => {},
  setCloseOnComplete: (closeOnComplete: boolean) => {},
  meta: { title: '', description: '' },
  open: false,
  success: false,
  closeOnComplete: true,
  clearMeta: () => {},
});

export const useSuccessDrawerContext = () => useContext(SuccessDrawerContext);
