import { hex2rgba } from './hexToRgba';

export const whiteOrBlackTextWithBG = (colorHex: string): string => {
  let color = hex2rgba(colorHex, 1, true);
  let red = parseInt(color.substring(0, 2), 16); // hexToR
  let green = parseInt(color.substring(2, 4), 16); // hexToG
  let blue = parseInt(color.substring(4, 6), 16); // hexToB
  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) return '#000000';
  else return '#ffffff';
};
