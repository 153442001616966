import { useGlobal } from 'context/global/GlobalContext';
import { useEffect } from 'react';
import ScreenType from 'types/ScreenType';

const MagicActionAuthDrawer = ({ onAuth }: { onAuth: () => void }) => {
  const { setScreen } = useGlobal();

  useEffect(() => {
    if (onAuth) {
      setScreen(ScreenType.NONE);
      onAuth();
    }
  }, [onAuth]);

  return null;
};

export default MagicActionAuthDrawer;
