import React, { useState, useEffect, ChangeEvent } from 'react';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';

type InputProps = {
  type?: 'text' | 'number' | 'email' | 'tel';
  value: string | number | undefined;
  styles?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  name?: string;
};

const Input: React.FC<InputProps> = ({
  value = '',
  onChange,
  required,
  disabled = false,
  placeholder = '',
  type = 'text',
  styles,
  className,
  name,
  autoComplete,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { inputStyle } = useThemeContext();

  useEffect(() => {
    if (value) setIsFocused(true);
    else setIsFocused(false);
  }, [value]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number' && (event.key === 'e' || event.key === 'E')) {
      event.preventDefault();
    }
  };

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-full';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-full';
    }
  };

  return (
    <div
      className={`
        relative w-full h-input flex items-center px-6 border-2 border-solid border-input transition-all ease-linear duration-300 
        [&_input]:z-20 [&_input]:w-full [&_input]:border-0 [&_input]:bg-transparent 
        [&_input]:active:bg-transparent [&_input]:active:outline-none 
        [&_input]:hover:bg-transparent [&_input]:hover:outline-none 
        ${isFocused ? 'bg-transparent' : 'bg-lightgray'} 
        ${getCornerStyle()} ${styles}
      `}
    >
      <span
        className={`z-40 absolute text-muted transition-all duration-300 pointer-events-none ${
          isFocused
            ? 'text-xxxs px-0.5 bg-white -translate-x-0.5 -translate-y-[25px] opacity-100'
            : 'text-[0.9rem] bg-transparent translate-x-0 translate-y-0 opacity-80'
        }`}
      >
        {placeholder}
      </span>
      <input
        name={name}
        type={type}
        width='100%'
        value={value}
        required={required}
        autoComplete={autoComplete}
        onFocus={() => setIsFocused(true)}
        onBlur={() => !value && setIsFocused(false)}
        onChange={(event) => onChange && onChange(event)}
        onKeyDown={handleKeyDown}
        className={`
            ${className || ''}
            ${
              disabled
                ? ` opacity-70 pointer-events-none text-disbaledInput`
                : ''
            }
          `}
      />
    </div>
  );
};

export default Input;
