import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobal } from 'context/global/GlobalContext';
import { showToast } from 'components/atomic/Toast/Toast';
import { MAGIC_ACTION } from 'context/global/GlobalProvider';
import useFirebaseError from './useFirebaseError';

interface MagicHandlerMap {
  handleMagicLink: (magicLinkRedirect?: MAGIC_ACTION) => void;
  loading: boolean;
  error: string;
  success: string;
}

const useMagicLinkHandler = (
  email: string,
  isNewUser: boolean = false
): MagicHandlerMap => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { slug, magicPayload, emailConsent, privacyConsent } = useGlobal();
  const { t } = useTranslation('translation', { keyPrefix: 'magicLink' });
  const getErrorMessage = useFirebaseError();

  const handleMagicLink = useCallback(
    (magicLinkRedirect: MAGIC_ACTION = MAGIC_ACTION.OPEN_MODULE) => {
      const payload = JSON.stringify({
        ...magicPayload,
        emailConsent,
        privacyConsent,
      });
      const payloadEncodedString = encodeURIComponent(payload);
      setLoading(true);
      setError('');
      setSuccess('');
      fetch(`${window.location.origin}/shared-api/auth/send-magic-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          isNewUser: isNewUser,
          magicAction: magicLinkRedirect,
          slug: slug,
          host: window.location.host,
          payloadEncodedString: payloadEncodedString,
        }),
      })
        .then(() => {
          setLoading(false);
          showToast({
            message: t('linkSentToastMessage'),
            type: 'success',
          });
        })
        .catch((err) => {
          setLoading(false);
          showToast({ message: getErrorMessage(err.code), type: 'error' });
        });
    },
    [t, slug, email, isNewUser, getErrorMessage, magicPayload]
  );

  return {
    handleMagicLink,
    loading,
    error,
    success,
  };
};

export default useMagicLinkHandler;
